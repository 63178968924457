import { User } from '../GlobalTypes';

export interface DashboardUser extends User {
  pin: string | null;
}

export interface DashboardUserProfile {
  address1: string;
  address2?: string;
  city: string;
  country?: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone2?: string;
  state: string;
  zipcode: string;
}

export interface DashboardUserStore {
  dashboardUserInfo: DashboardUser | null;
  error: any | null;
  isLoading: boolean;
}

export enum DashboardUserStoreActionTypes {
  POPULATE_DASHBOARD_USER = 'POPULATE_DASHBOARD_USER',
  DASHBOARD_USER_API_ERR = 'DASHBOARD_USER_API_ERR',
  DASHBOARD_USER_SET_LOAD_STATE = 'DASHBOARD_USER_SET_LOAD_STATE',
}

export type ApiErrorType = {
  type: DashboardUserStoreActionTypes.DASHBOARD_USER_API_ERR;
  payload: { error: any };
};

export type SetLoadStateType = {
  type: DashboardUserStoreActionTypes.DASHBOARD_USER_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type PopulateDashboardUserType = {
  type: DashboardUserStoreActionTypes.POPULATE_DASHBOARD_USER;
  payload: { dashboardUserInfo: DashboardUser };
};

export type DashboardUserStoreAction =
  | ApiErrorType
  | SetLoadStateType
  | PopulateDashboardUserType;
