import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ImageCarouselProps {
  images: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  return (
    <Carousel>
      {images.map((img, index) => (
        <div key={index}>
          <img src={img} alt={`${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
