import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import MuiInput from '@mui/material/Input';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { updateFacility } from '../../../store/facility/actionCreators';
import { Facility } from '../../../store/facility/facilityTypes';
import DataRowItem from '../../Shared/DataRowItem';
import {
  convertMinutesToSeconds,
  convertSecondsToMinutes,
  formatDurationFromMinutes,
} from '../../../shared/utils';

type MyFacilityDetailProps = {
  facility: Facility;
  facilityId: number;
  engOrCustomerCare?: boolean;
};

const FacilityData = (props: MyFacilityDetailProps) => {
  const { facility, facilityId, engOrCustomerCare } = props;

  const dispatch = useDispatch();

  let [editMedWindowTime, setEditMedWindowTime] = useState(false);
  let [editMedWindowTimeValue, setEditMedWindowTimeValue] = useState(0);
  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={2}
      style={{ marginBottom: 20 }}
    >
      <DataRowItem title="Name" data={facility.name} titleSize={2} />
      <Grid item container style={{ minHeight: '47px' }}>
        <Grid item xs={2}>
          <Typography variant={'h2'}>
            Medication window time {editMedWindowTime ? '(minutes)' : ''}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {!editMedWindowTime ? (
            <>
              {facility.med_window_time_sec === null
                ? '[No Facility Medication Window Set]'
                : formatDurationFromMinutes(
                    convertSecondsToMinutes(facility.med_window_time_sec)
                  )}
              <EditIcon
                fontSize="small"
                viewBox="0 0 24 18"
                onClick={() => {
                  setEditMedWindowTime(true);
                  setEditMedWindowTimeValue(
                    facility && facility.med_window_time_sec
                      ? convertSecondsToMinutes(facility.med_window_time_sec)
                      : editMedWindowTimeValue
                  );
                }}
              />
            </>
          ) : (
            <>
              <MuiInput
                value={editMedWindowTimeValue}
                inputProps={{
                  step: 15,
                  min: 15,
                  max: 1440,
                  type: 'number',
                }}
                onChange={(e: any) => {
                  setEditMedWindowTimeValue(e.target.value);
                }}
              />
              <CheckIcon
                onClick={() => {
                  setEditMedWindowTime(false);
                  dispatch(
                    updateFacility(
                      facilityId,
                      {
                        med_window_time_sec: convertMinutesToSeconds(
                          editMedWindowTimeValue
                        ),
                      },
                      engOrCustomerCare
                    )
                  );
                }}
              />
              <CloseIcon
                onClick={() => {
                  setEditMedWindowTime(false);
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <DataRowItem
        title="Created at"
        data={facility.created_at}
        titleSize={2}
      />
    </Grid>
  );
};
export default FacilityData;
