import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import MaterialTable, {
  Column,
  Options,
  MTableToolbar,
} from '@material-table/core';
import { Typography, Button } from '@mui/material';
import { TableOptions } from '../../../shared/tableUtils';
import { getPrivilegeById } from '../../../shared/utils';
import { RootState } from '../../../store';
import {
  getFacilityDevices,
  updateDeviceAssociationWithFacility,
} from '../../../store/facility/actionCreators';
import {
  FacilityDevice,
  FacilityDeviceAssociationAction,
} from '../../../store/facility/facilityTypes';
import {
  getDeviceList,
  clearDeviceList,
} from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import { HeaderWrapper } from '../../Shared/HeaderWrapper';
import { Wrapper } from '../../Shared/Wrapper';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import LinkDevicesModal from './LinkDevicesModal';
import ConfirmUnlinkDevicesModal from './ConfirmUnlinkDevicesModal';

interface FacilityDevicesProps {
  facilityId: number;
}

const stateSelector = (state: RootState) => {
  const { facility, dashboardUser, devices } = state;
  const { facilityDevices } = facility;
  const { dashboardUserInfo } = dashboardUser;
  const { deviceList } = devices;
  return {
    facilityDevices,
    dashboardUserInfo,
    deviceList,
  };
};

function FacilityDevices(props: FacilityDevicesProps) {
  const { facilityId } = props;
  const [selectedRows, setSelectedRows] = useState<FacilityDevice[]>([]); // State to track selected devices rows

  // Modal states
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);

  const { facilityDevices, dashboardUserInfo, deviceList } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDeviceList()); // this needs to be called so that the deviceList from the /devices page is not shown on the linking modal
    if (
      !facilityDevices ||
      (facilityDevices && facilityDevices.facility_id !== facilityId)
    ) {
      dispatch(getFacilityDevices(facilityId));
    }
  }, [facilityId, facilityDevices, dispatch]);

  const columns: Column<FacilityDevice>[] = [
    {
      title: 'Serial number',
      field: 'serial_number',
    },
    {
      title: 'Owner',
      field: 'owner_name',
      defaultSort: 'asc',
    },
    {
      title: 'Synced By',
      field: 'synced_by_name',
    },
    { title: 'SW version', field: 'software_version' },
  ];
  const isCustomerCareOrEngineering = dashboardUserInfo
    ? ['engineering', 'customer_care'].includes(
        getPrivilegeById(dashboardUserInfo.privilege_id)
      )
    : false;

  const isRowSelectable = (rowData: FacilityDevice) => {
    return rowData.owner ? false : true;
  };

  const handleDevicesRowSelectionChange = (rows: FacilityDevice[]) => {
    setSelectedRows(rows);
  };

  const openLinkModal = () => {
    dispatch(getDeviceList(false, false));
    setLinkModalOpen(true);
  };

  const closeLinkModal = () => {
    dispatch(clearDeviceList()); // this needs to be called so the /devices page is not affected by the deviceList filtering on the linking modal
    setLinkModalOpen(false);
  };

  const openUnlinkModal = () => {
    setUnlinkModalOpen(true);
  };

  const closeUnlinkModal = () => {
    setUnlinkModalOpen(false);
  };

  const handleLinkDevices = (stagedDevices: Device[]) => {
    const deviceIds = stagedDevices.map((d) => d.id);
    dispatch(
      updateDeviceAssociationWithFacility(
        facilityId,
        FacilityDeviceAssociationAction.LINK,
        deviceIds
      )
    );
    setSelectedRows([]);
    closeLinkModal();
  };

  const handleUnlinkDevices = () => {
    const deviceIds = selectedRows.map((d) => d.id);
    dispatch(
      updateDeviceAssociationWithFacility(
        facilityId,
        FacilityDeviceAssociationAction.UNLINK,
        deviceIds
      )
    );
    setSelectedRows([]);
    closeUnlinkModal();
  };

  const CustomToolbar = (props: any) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {isCustomerCareOrEngineering && (
        <div style={{ marginLeft: '20px' }}>
          <Button variant="contained" color="primary" onClick={openLinkModal}>
            + Add
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={openUnlinkModal}
            disabled={selectedRows.length === 0} // Disable when no rows are selected
            style={{ marginLeft: '8px' }}
          >
            Remove
          </Button>
        </div>
      )}
      <div style={{ flexGrow: 1 }}></div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MTableToolbar {...props} />
      </div>
    </div>
  );

  if (isNil(facilityDevices) || isNil(dashboardUserInfo)) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Wrapper>
          <HeaderWrapper>
            <Typography variant={'h1'}>Facility Devices</Typography>
          </HeaderWrapper>
          <MaterialTable
            columns={columns}
            data={facilityDevices.devices}
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50],
              selection: isCustomerCareOrEngineering,
              showSelectAllCheckbox: false,
              selectionProps: (rowData: FacilityDevice) => ({
                disabled: !isRowSelectable(rowData), // Disable checkbox for rows based on the logic
              }),
              ...(TableOptions as Partial<Options<FacilityDevice>>),
            }}
            onSelectionChange={handleDevicesRowSelectionChange} // Track row selection changes
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Wrapper>
        {linkModalOpen && (
          <LinkDevicesModal
            devices={deviceList ?? []}
            onClose={closeLinkModal}
            onConfirm={handleLinkDevices}
          />
        )}
        {unlinkModalOpen && (
          <ConfirmUnlinkDevicesModal
            devices={selectedRows}
            confirmUnlink={handleUnlinkDevices}
            onClose={closeUnlinkModal}
          />
        )}
      </>
    );
  }
}

export default FacilityDevices;
