import { Reducer } from 'redux';
import { MY_ACCOUT_TABS } from '../../shared/constants';
import {
  MyAccountAction,
  MyAccountActionTypes,
  MyAccountStore,
} from './myAccountTypes';

const initialState: MyAccountStore = {
  selectedTab: MY_ACCOUT_TABS.PERSONAL_INFO,
};

const myAccountReducer: Reducer<MyAccountStore, MyAccountAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MyAccountActionTypes.MY_ACCOUNT_SIDE_BAR_TAB_SELECTION: {
      const { selectedTab } = action.payload;
      let newState = {
        ...state,
        selectedTab: selectedTab,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default myAccountReducer;
