import moment from 'moment';
import { Reducer } from 'redux';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../shared/constants';
import {
  Pharmacy,
  PharmacyAction,
  PharmacyActionType,
  PharmacyStore,
} from './pharmacyTypes';

const initialState: PharmacyStore = {
  pharmacies: null,
  error: null,
  isLoading: false,
};

const pharmacyReducer: Reducer<PharmacyStore, PharmacyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PharmacyActionType.SET_PHARMACIES: {
      const { pharmacies } = action.payload;
      pharmacies.forEach((pharmacy) => {
        let createdAt = moment(pharmacy.created_at);
        pharmacy.created_at = createdAt.isValid()
          ? createdAt.format(DATE_FORMAT)
          : '-';
      });
      let newState = {
        ...state,
        pharmacies: pharmacies,
      };
      return newState;
    }
    case PharmacyActionType.PHARMACY_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', action.payload);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case PharmacyActionType.SET_PHARMACY_INVITES: {
      const { pharmacyId, invites } = action.payload;
      let selectedPharmacy = (state.pharmacies || ([] as Pharmacy[])).find(
        (pharmacy) => pharmacy.id === Number(pharmacyId)
      );
      if (!selectedPharmacy) {
        return state;
      }
      invites.forEach((invite) => {
        let expiresAt = moment(invite.expires_at);
        invite.expires_at = expiresAt.isValid()
          ? expiresAt.format(DATE_TIME_FORMAT)
          : '-';
      });
      selectedPharmacy.invites = invites;
      let newState = {
        ...state,
        pharmacies: [...(state.pharmacies || [])],
      };
      return newState;
    }
    case PharmacyActionType.PHARMACY_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case PharmacyActionType.SET_PHARMACY_TECHS: {
      const { pharmacyId, techs } = action.payload;
      let selectedPharmacy = (state.pharmacies || ([] as Pharmacy[])).find(
        (pharmacy) => pharmacy.id === Number(pharmacyId)
      );
      if (!selectedPharmacy) {
        return state;
      }
      selectedPharmacy.techs = techs;
      let newState = {
        ...state,
        pharmacies: [...(state.pharmacies || [])],
      };
      return newState;
    }
    default:
      return state;
  }
};

export default pharmacyReducer;
