import { faRedo, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import MaterialTable, { Column, Options } from '@material-table/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { careteamRoleOptions } from '../../../shared/constants';
import { TableOptions } from '../../../shared/tableUtils';
import { RootState } from '../../../store';
import {
  createCareteamInvite,
  deleteCareteamInvite,
  getPendingCareteamInvites,
} from '../../../store/customers/actionCreators';
import { CareteamInvite } from '../../../store/customers/customerTypes';
import Modal from '../../Shared/Modal';

interface CareTeamInvite {
  first_name: string;
  last_name: string;
  email: string;
  careteam_role_id: string;
  expires_at: string;
  name: string;
  expires: string;
  careteam_role: string;
}

const careteamInviteColumns: Column<CareTeamInvite>[] = [
  { title: 'Name', field: 'name' },
  { title: 'Email address', field: 'email' },
  {
    title: 'Role',
    field: 'careteam_role_id',
    lookup: careteamRoleOptions.reduce(
      (acl, curr) => ({ ...acl, [curr.value]: curr.label }),
      {}
    ),
  },
  { title: 'Expires', field: 'expires' },
];

const stateSelector = (state: RootState) => {
  const invites = state.customers.careteamInvites;
  if (!invites) {
    return null;
  }
  return (invites || []).map((invite) => ({
    name: `${invite.first_name} ${invite.last_name}`,
    expires: moment(invite.expires_at).format('MM/DD/YYYY'),
    careteam_role: invite.careteam_role_id,
    ...invite,
  }));
};

interface PendingCareteamInvitesTableProps {
  ownerId: number;
}

export const PendingCareteamInvitesTable = (
  props: PendingCareteamInvitesTableProps
) => {
  const { ownerId } = props;
  const dispatch = useDispatch();
  const careteamInvites = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);
  const [confirmModalProps, setConfirmModalProps] = useState<{
    primaryActionText: string;
    description: string;
    onSuccess(): void;
  } | null>(null);

  useEffect(() => {
    if (!careteamInvites) {
      dispatch(getPendingCareteamInvites(ownerId));
    }
  }, [ownerId, dispatch, careteamInvites]);

  return (
    <>
      <Typography variant={'h1'}>Pending invites</Typography>
      <MaterialTable
        columns={careteamInviteColumns.map((c) => ({
          ...c,
          tableData: undefined,
        }))}
        data={careteamInvites || []}
        options={{
          ...(TableOptions as Partial<Options<CareTeamInvite>>),
          actionsColumnIndex: -1,
          idSynonym: 'name',
        }}
        actions={[
          {
            icon: function SaveIcon() {
              return <FontAwesomeIcon size="sm" icon={faRedo} />;
            },
            tooltip: 'Resend invite',
            onClick: (_, rowData) =>
              setConfirmModalProps({
                primaryActionText: 'Resend',
                description: `Are you sure you want to resend invite to ${
                  (rowData as CareteamInvite).first_name
                } ${(rowData as CareteamInvite).last_name}?`,
                onSuccess: () => {
                  dispatch(
                    createCareteamInvite(
                      ownerId,
                      rowData as CareteamInvite,
                      'resend'
                    )
                  );
                  setConfirmModalProps(null);
                },
              }),
          },
          {
            icon: function DeleteIcon() {
              return <FontAwesomeIcon size="sm" icon={faTrashAlt} />;
            },
            tooltip: 'Delete invite',
            onClick: (_, rowData) =>
              setConfirmModalProps({
                primaryActionText: 'Delete',
                description: `Are you sure you want to delete ${
                  (rowData as CareteamInvite).first_name
                } ${(rowData as CareteamInvite).last_name}'s invite?`,
                onSuccess: () => {
                  dispatch(
                    deleteCareteamInvite(
                      ownerId,
                      (rowData as CareteamInvite).email
                    )
                  );
                  setConfirmModalProps(null);
                },
              }),
          },
        ]}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
      {confirmModalProps ? (
        <Modal
          onClose={() => setConfirmModalProps(null)}
          actions={{
            primaryAction: {
              onClick: confirmModalProps.onSuccess,
              text: confirmModalProps.primaryActionText,
            },
          }}
        >
          <div style={{ marginBottom: 10 }}>
            {confirmModalProps.description}
          </div>
        </Modal>
      ) : null}
    </>
  );
};
