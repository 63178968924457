/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetMedEvent = {
  /**
   * treat this as an arbitray string and do not store locally for later reuse, the response value is subject to change in the future
   */
  med_event_id?: string;
  /**
   * When the pharmacy prescribed the medication to be taken - immutable
   */
  prescribed_datetime?: string;
  /**
   * The time the owner has configured omcare to take their medication, within an acceptable range of the prescribed_datetime
   */
  desired_datetime?: string;
  owner?: {
    /**
     * customer id of owner
     */
    owner_id?: number;
    /**
     * full name of owner
     */
    name?: string;
    /**
     * age of owner
     */
    age?: number;
    /**
     * Type of gender
     */
    gender?: GetMedEvent.gender;
    /**
     * timezone of owner's hub
     */
    device_time_zone?: string;
  };
  /**
   * The 24 hour clock time of day the owner is allowed to take their medication, in the owners local timezone
   */
  prescribed_time_of_day_24?: string;
  /**
   * The 24 hour clock time of day the owner has configured omcare to take their medication, in the owners local timezone
   */
  desired_time_of_day_24?: string;
  /**
   * The catergorization of time based on morning, afternoon and evening
   */
  part_of_day?: GetMedEvent.part_of_day;
  /**
   * Name of the assigned caregiver for given owner.
   */
  caregiver_name?: string;
  /**
   * Customer id of assigned caregiver for given owner.
   */
  caregiver_customer_id?: number;
  /**
   * Type of med assist
   */
  event_type?: GetMedEvent.event_type;
  event_type_id?: number;
  /**
   * Type of med event status
   */
  status?: GetMedEvent.status;
};

export namespace GetMedEvent {
  /**
   * Type of gender
   */
  export enum gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NON_BINARY = 'NON_BINARY',
    DECLINE_TO_SAY = 'DECLINE_TO_SAY',
  }

  /**
   * The catergorization of time based on morning, afternoon and evening
   */
  export enum part_of_day {
    MORNING = 'Morning',
    AFTERNOON = 'Afternoon',
    EVENING = 'Evening',
  }

  /**
   * Type of med assist
   */
  export enum event_type {
    SMART_ASSIST = 'SMART_ASSIST',
    CAREGIVER_ASSIST = 'CAREGIVER_ASSIST',
    ON_THE_GO = 'ON_THE_GO',
  }

  /**
   * Type of med event status
   */
  export enum status {
    MISSED = 'MISSED',
    COMPLETE = 'COMPLETE',
    UPCOMING = 'UPCOMING',
    ACTIVE = 'ACTIVE',
  }
}
