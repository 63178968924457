/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PharmacyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the complete list of pharmacies
   * @returns any Success
   * @throws ApiError
   */
  public getAllPharmacies(): CancelablePromise<
    Array<{
      name?: string;
      created_at?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/pharmacy/',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
