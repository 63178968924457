import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField } from '@mui/material';
import { FieldAttributes, getIn } from 'formik';
import React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface TextInputFormFieldProps {
  field: { name: string; onBlur(): void; onChange(): void; value: string };
  form: FieldAttributes<any>;
  placeholder: string;
  extra: { children: any; label: string; margin: string; placeHolder: string };
}

export const TextInputFormField = (props: TextInputFormFieldProps) => {
  const { field, form, placeholder, ...extra } = props;
  const errorText = getIn(form.errors, field.name);

  return (
    <TextField
      fullWidth
      variant="outlined"
      helperText={
        errorText ? (
          <>
            {[].concat(errorText).map((text) => (
              <li style={{ listStyleType: 'none' }} key={text}>
                {text}
              </li>
            ))}
          </>
        ) : null
      }
      error={!!errorText}
      {...extra}
      placeholder={placeholder}
      {...field}
    />
  );
};

interface IconAdornmentProps {
  onClick(): void;
  faIconProps: FontAwesomeIconProps;
}

export const IconAdornment = (props: IconAdornmentProps) => {
  const { onClick, faIconProps } = props;

  return (
    <IconButton onClick={onClick}>
      <FontAwesomeIcon {...faIconProps} />
    </IconButton>
  );
};
