/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FactoryService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Submit the CSR report
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addDeviceToDb(requestBody?: Blob): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/factory/submit_csr',
      body: requestBody,
      mediaType: 'application/text',
      errors: {
        400: `Error Inserting Device`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
