import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { deleteDatabase } from '../../../store/devices/actionCreators';
import { setLoadState } from '../../../store/software/actionCreators';
import Modal from '../../Shared/Modal';
import { TextInputFormField } from '../../Shared/TextInputFormField';

interface DeleteDatabaseProps {
  serialNumber: string;
  ownerName: string;
  onSuccess(): void;
  onCancel(): void;
}

const DeleteDatabase = (props: DeleteDatabaseProps) => {
  const { serialNumber, ownerName, onSuccess, onCancel } = props;

  const dispatch = useDispatch();

  const formikRef = useRef<FormikProps<{ note: string }>>(null);

  const schema = yup.object({
    note: yup
      .string()
      .required('This field is required.')
      .matches(
        /^confirm$/i,
        'This field must only contain the word "confirm".'
      ),
  });

  const onSubmit = () => {
    dispatch(setLoadState(true));
    dispatch(deleteDatabase(serialNumber));
    dispatch(setLoadState(false));
    onSuccess();
  };

  interface FormValues {
    note: string;
  }

  const initialValues: FormValues = { note: '' };

  return (
    <Modal
      onClose={onCancel}
      actions={{
        primaryAction: {
          onClick: () => {
            formikRef.current && formikRef.current.submitForm();
          },
          text: 'Confirm',
        },
      }}
    >
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontWeight: 'bold' }}>Warning: </span>
        This action will delete all medication details from the hub owned by{' '}
        {ownerName}. Are you sure you want to proceed? Please type
        &quot;confirm&quot; below to continue.
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="note"
              label='Please type "confirm"'
              placeholder="confirm"
              component={TextInputFormField}
              autoComplete="off"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteDatabase;
