import moment from 'moment';
import { Reducer } from 'redux';
import {
  DATE_TIME_FORMAT,
  DATE_TIME_SECONDS_FORMAT,
} from '../../shared/constants';
import {
  DeviceActions,
  DeviceActionTypes,
  DevicesStore,
  DeviceStatus,
} from './deviceTypes';

const initialState: DevicesStore = {
  deviceList: null,
  syncDeviceError: null,
  error: null,
  isLoading: false,
  currentLog: null,
  deviceSynced: false,
  seamImages: null,
  deviceLogs: null,
  shadowConfigs: null,
  bbImages: null,
};

const devicesReducer: Reducer<DevicesStore, DeviceActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DeviceActionTypes.POPULATE_DEVICES: {
      const { deviceList } = action.payload;
      deviceList.forEach((device) => {
        device.owner_name =
          device.first_name && device.last_name
            ? `${device.first_name} ${device.last_name}`
            : '-';
        device.synced_by_customer_name = device.sync_code_done_by
          ? `${device.sync_code_done_by_first_name} ${device.sync_code_done_by_last_name}`
          : '-';
      });
      let newState = {
        ...state,
        isLoading: false,
        deviceList: deviceList,
      };
      return newState;
    }
    case DeviceActionTypes.CLEAR_DEVICE_LIST: {
      let newState = {
        ...state,
        deviceList: initialState.deviceList,
      };
      return newState;
    }
    case DeviceActionTypes.UPDATE_DEVICE_STATUS: {
      const { deviceSerialNumber, deviceStatus } = action.payload;
      let newState = {
        ...state,
        deviceList: (state.deviceList || []).map((device) => {
          if (device.serial_number === deviceSerialNumber) {
            return { ...device, status: deviceStatus as DeviceStatus };
          } else {
            return device;
          }
        }),
      };
      return newState;
    }
    case DeviceActionTypes.DEVICES_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', error);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case DeviceActionTypes.DEVICES_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case DeviceActionTypes.STORE_LOG: {
      const { log } = action.payload;
      log.forEach((event, index) => {
        let realTimeTimeStamp = moment(
          parseInt(event.__REALTIME_TIMESTAMP) / 1000
        );
        event.formatted_timestamp = realTimeTimeStamp.isValid()
          ? realTimeTimeStamp.format(DATE_TIME_SECONDS_FORMAT)
          : '-';
        event.id = index;
      });
      let newState = {
        ...state,
        currentLog: log,
      };
      return newState;
    }
    case DeviceActionTypes.CLEAR_LOG: {
      let newState = {
        ...state,
        currentLog: initialState.currentLog,
      };
      return newState;
    }
    case DeviceActionTypes.FAILURE_SYNC_DEVICE: {
      const { syncDeviceError } = action.payload;
      let newState = {
        ...state,
        syncDeviceError: syncDeviceError,
      };
      return newState;
    }
    case DeviceActionTypes.CLEAR_SYNC_DEVICE_STATE: {
      let newState = {
        ...state,
        syncDeviceError: initialState.syncDeviceError,
        deviceSynced: initialState.deviceSynced,
      };
      return newState;
    }
    case DeviceActionTypes.DID_SYNC_DEVICE: {
      let newState = {
        ...state,
        deviceSynced: true,
      };
      return newState;
    }
    case DeviceActionTypes.STORE_SEAM_IMAGES: {
      const { deviceId, seamImages } = action.payload;
      let newState = {
        ...state,
        seamImages: seamImages[deviceId] ? seamImages[deviceId] : [],
      };
      return newState;
    }
    case DeviceActionTypes.CLEAR_SEAM_IMAGES: {
      let newState = {
        ...state,
        seamImages: initialState.seamImages,
      };
      return newState;
    }
    case DeviceActionTypes.STORE_DEVICE_LOGS: {
      const { deviceLogs } = action.payload;
      let newState = {
        ...state,
        deviceLogs:
          deviceLogs?.map((log) => {
            let uploadedAt = moment(log.uploaded_at);
            log.uploaded_at = uploadedAt.isValid()
              ? uploadedAt.format(DATE_TIME_FORMAT)
              : '-';
            return log;
          }) || [],
      };
      return newState;
    }
    case DeviceActionTypes.STORE_DEVICE_SHADOW: {
      const { shadowConfigs } = action.payload;
      let newState = {
        ...state,
        shadowConfigs: shadowConfigs,
      };
      return newState;
    }
    case DeviceActionTypes.CLEAR_DEVICE_SHADOW: {
      let newState = {
        ...state,
        shadowConfigs: initialState.shadowConfigs,
      };
      return newState;
    }
    case DeviceActionTypes.STORE_BB_IMAGES: {
      const { bbImages } = action.payload;
      const newState = {
        ...state,
        bbImages,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default devicesReducer;
