import { Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';

interface OnlineStatusProps {
  isOnline: boolean | undefined;
}

function OnlineStatus(props: OnlineStatusProps) {
  const { isOnline } = props;
  const color = isOnline ? '#82c8ba' : '#cc5d4b';
  return (
    <Grid container sx={{ marginLeft: '-4px' }}>
      <FiberManualRecordIcon color="error" style={{ color }} />{' '}
      {isOnline ? 'Online' : 'Offline'}
    </Grid>
  );
}

export default OnlineStatus;
