import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SOFTWARE_CHANNEL_ID } from '../../../shared/constants';
import { updateDeviceProperties } from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import { SoftwareChannel } from '../../../store/GlobalTypes';
import StyledSelect from '../../Shared/StyledSelect';

interface UpdateDeviceChannelProps {
  device: Device;
}

const UpdateDeviceChannel = (props: UpdateDeviceChannelProps) => {
  const { device } = props;
  const dispatch = useDispatch();

  const [deviceChannel, setDeviceChannel] = useState(
    device.software_channel_id
  );

  if (!device) {
    return null;
  }
  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-end"
      style={{ marginBottom: 5 }}
    >
      <Grid item xs="auto">
        <FormControl required variant="outlined" sx={{ minWidth: '200px' }}>
          <StyledSelect
            value={String(deviceChannel)}
            onChange={(e: SelectChangeEvent<string>) => {
              setDeviceChannel(Number(e.target.value));
            }}
            sx={{ width: '200px', textAlign: 'left' }}
          >
            {Object.keys(SOFTWARE_CHANNEL_ID).map((channel) => (
              <MenuItem
                key={channel}
                value={String(
                  (SOFTWARE_CHANNEL_ID as SoftwareChannel)[channel]
                )}
              >
                {channel}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="secondary"
          disabled={deviceChannel === device.software_channel_id}
          onClick={() => {
            dispatch(
              updateDeviceProperties(device.id, {
                software_channel_id: deviceChannel,
              })
            );
          }}
        >
          Update channel
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpdateDeviceChannel;
