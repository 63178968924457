/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Event handler endpoint for omcare events
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public handleEvents(requestBody?: {
    source?: 'omcare.local.event' | 'omcare.event';
    'detail-type'?:
      | 'updateMedEventTimeWindow'
      | 'newMedSchedule'
      | 'hubUptimeStatus'
      | 'closingMedEvent'
      | 'careteamUpdate'
      | 'caregiverAssistReady';
    detail?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/events/handle',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Endpoint for handle extracting tar files uploaded with dispense seam detection bounding box images
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public extractBoundingBoxImages(requestBody?: {
    'detail-type'?: string;
    detail?: {
      bucket?: {
        name?: string;
      };
      object?: {
        key?: string;
      };
    };
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/events/extract/dispensing-images',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Endpoint for handling cron jobs
   * @param job Name of cron job
   * @throws ApiError
   */
  public handleCronJobs(
    job:
      | 'CheckOfflineHubsForNotification'
      | 'CheckPreviousDayMedAdherence'
      | 'CheckTimeForMedAssist'
      | 'AboutToMissMedEmail'
      | 'AboutToMissMedMobile'
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/events/cron',
      query: {
        job: job,
      },
    });
  }

  /**
   * Endpoint for handling device online|offline
   * @param requestBody
   * @throws ApiError
   */
  public handleDeviceConnectionStatus(requestBody?: {
    /**
     * Serial No
     */
    clientId?: string;
    /**
     * Event Type
     */
    eventType?: 'connected' | 'disconnected';
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/events/device_status',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
