import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFacilityRoleById } from '../../../shared/utils';
import { RootState } from '../../../store';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';
import DataRowItem from '../../Shared/DataRowItem';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import EditPersonalProfile from './EditPersonalProfile';

interface PersonalInfoProps {
  title: string;
}

function PersonalInfo(props: PersonalInfoProps) {
  const { dashboardUserInfo } = useSelector<
    RootState,
    {
      dashboardUserInfo: DashboardUser | null;
    }
  >((state) => {
    const { dashboardUser } = state;
    const { dashboardUserInfo } = dashboardUser;
    return {
      dashboardUserInfo,
    };
  });
  const { title } = props;

  const [viewMode, setViewMode] = useState('view');

  if (!dashboardUserInfo) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '99%' }}>
      <Typography variant={'h1'}>{title}</Typography>
      {viewMode === 'edit' ? (
        <EditPersonalProfile
          user={dashboardUserInfo}
          onSuccess={() => setViewMode('view')}
          onCancel={() => setViewMode('view')}
        />
      ) : (
        <Grid container alignItems="flex-start" spacing={2}>
          <DataRowItem
            title="First name"
            data={dashboardUserInfo.first_name}
            titleSize={3}
          />
          <DataRowItem
            title="Last name"
            data={dashboardUserInfo.last_name}
            titleSize={3}
          />
          <DataRowItem
            title="Email address"
            data={dashboardUserInfo.email}
            titleSize={3}
          />
          <DataRowItem
            title="Phone number"
            data={dashboardUserInfo.phone}
            titleSize={3}
          />
          <DataRowItem
            title="Phone number 2 (optional)"
            data={dashboardUserInfo.phone2}
            titleSize={3}
          />
          <DataRowItem
            title="Facility name"
            data={dashboardUserInfo.facility_name}
            titleSize={3}
          />
          <DataRowItem
            title="Role"
            data={
              dashboardUserInfo.facility_role_id
                ? getFacilityRoleById(dashboardUserInfo.facility_role_id)
                : null
            }
            titleSize={3}
          />
          <DataRowItem
            title="Address"
            data={dashboardUserInfo.address1}
            titleSize={3}
          />
          <DataRowItem
            title="Address 2 (optional)"
            data={dashboardUserInfo.address2}
            titleSize={3}
          />
          <DataRowItem
            title="City"
            data={dashboardUserInfo.city}
            titleSize={3}
          />
          <DataRowItem
            title="State"
            data={dashboardUserInfo.state}
            titleSize={3}
          />
          <DataRowItem
            title="Zip code"
            data={dashboardUserInfo.zipcode}
            titleSize={3}
          />
          <DataRowItem
            title="Country"
            data={dashboardUserInfo.country}
            titleSize={3}
          />
          <DataRowItem title="PIN" data={dashboardUserInfo.pin} titleSize={3} />
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setViewMode('edit')}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default PersonalInfo;
