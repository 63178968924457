/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PharmaciesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Process med schedule file from EventBridge S3 event details
   * @param pharmacyId Pharmacy Id
   * @returns any Success
   * @throws ApiError
   */
  public processMedScheduleFile(pharmacyId?: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/pharmacies/{pharmacy_id}/files/medication-schedules',
      path: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get a list of med schedule files for a given pharmacy
   * @param pharmacyId Pharmacy Id
   * @returns any Success
   * @throws ApiError
   */
  public listPharmacyMedScheduleFiles(pharmacyId?: number): CancelablePromise<
    Array<{
      id?: number;
      file_s3_key?: string;
      file_name?: string;
      pharmacy?: string;
      uploaded_at?: string;
      processing_completed_at?: string;
      processing_result?: string;
      result_notes?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pharmacies/{pharmacy_id}/files',
      path: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get the processing status of a med schedule file
   * @param pharmacyId Pharmacy Id
   * @param fileId File ID
   * @returns any Success
   * @throws ApiError
   */
  public getMedScheduleProcessingStatus(
    pharmacyId?: number,
    fileId?: number
  ): CancelablePromise<{
    id?: number;
    file_s3_key?: string;
    file_name?: string;
    pharmacy?: string;
    uploaded_at?: string;
    processing_completed_at?: string;
    processing_result?: string;
    result_notes?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/pharmacies/{pharmacy_id}/files/{file_id}/status',
      path: {
        pharmacy_id: pharmacyId,
        file_id: fileId,
      },
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
