import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  SelectChangeEvent,
  Grid,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import Modal from '../../../Shared/Modal';
import StyledSelect from '../../../Shared/StyledSelect';

interface SetStatusModalProps {
  status: string;
  dispensedFlow: string;
  handleChangeDispensedFlow(event: SelectChangeEvent<string>): void;
  adherenceDoses: any[];
  handleChangeAdherenceStatuses(newStatusObj: any): void;
  deleteHistoryOnly: boolean;
  saveChanges(): void;
  onClose(): void;
}

function SetStatusModal(props: SetStatusModalProps) {
  const {
    status,
    dispensedFlow,
    handleChangeDispensedFlow,
    adherenceDoses,
    handleChangeAdherenceStatuses,
    deleteHistoryOnly,
    saveChanges,
    onClose,
  } = props;

  const [typedStatus, setTypedStatus] = useState('');
  const [adherenceStatus, setAdherenceStatus] = useState(
    Array(adherenceDoses.length).fill('administered')
  );
  const [adherenceTimestamp, setAdherenceTimestamp] = useState(DateTime.now());

  const updateAdherenceStatus = (statuses: string[]) => {
    const newAdherenceStatuses: any = {};
    statuses.forEach((status, idx) => {
      const doseObj = {
        dose_id: adherenceDoses[idx]?.id,
      };
      if (!Object.keys(newAdherenceStatuses).includes(status))
        newAdherenceStatuses[status] = [doseObj];
      else newAdherenceStatuses[status].push(doseObj);
    });
    handleChangeAdherenceStatuses({
      events: newAdherenceStatuses,
      timestamp: adherenceTimestamp.toUTC().toISO(),
    });
  };

  const dispencedStatus = status === 'dispensed';
  const markAdherenceStatus = status === 'adherence';
  const deleteEventStatus = status === 'delete';
  const resetEventStatus = status === 'reset';
  const incorrectTypedStatus = typedStatus.toLowerCase() !== status;

  useEffect(() => {
    if (markAdherenceStatus) updateAdherenceStatus(adherenceStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adherenceStatus, adherenceTimestamp, markAdherenceStatus]);

  return (
    <Modal
      onClose={onClose}
      showCloseX
      title={
        markAdherenceStatus
          ? 'Mark Dose Adherence Status'
          : deleteEventStatus
          ? 'Confirm delete medication event'
          : resetEventStatus
          ? 'Confirm reset medication event history'
          : 'Confirm status change'
      }
      actions={{
        primaryAction: {
          onClick: saveChanges,
          disabled:
            incorrectTypedStatus || (dispencedStatus && dispensedFlow === ''),
          text: !(deleteEventStatus || resetEventStatus)
            ? 'Confirm'
            : deleteHistoryOnly
            ? 'Reset'
            : 'Delete',
        },
      }}
    >
      {markAdherenceStatus && (
        <Grid container>
          <Grid item xs={6}>
            {adherenceDoses.map((dose, idx) => (
              <div key={idx} style={{ paddingBottom: '25px' }}>
                <FormControl
                  variant="standard"
                  sx={{ minWidth: '200px', marginLeft: '8px' }}
                >
                  <InputLabel>{dose?.drug_name}</InputLabel>
                  <StyledSelect
                    value={adherenceStatus[idx]}
                    onChange={(event: SelectChangeEvent<string>) => {
                      const tempAdherenceStatus = [...adherenceStatus];
                      tempAdherenceStatus[idx] = event.target.value;
                      setAdherenceStatus(tempAdherenceStatus);
                    }}
                    disabled={!markAdherenceStatus}
                    label={dose?.drug_name}
                  >
                    <MenuItem value={'administered'}>Administered</MenuItem>
                    <MenuItem value={'skipped'}>Skipped</MenuItem>
                  </StyledSelect>
                </FormControl>
              </div>
            ))}
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                label="Adherence DateTime"
                value={adherenceTimestamp}
                onChange={(datetime) => {
                  if (datetime === null) return;
                  setAdherenceTimestamp(datetime);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      )}
      <div style={{ marginBottom: '24px' }}>
        {markAdherenceStatus ? (
          <>
            Please type &quot;{status}&quot; to mark the adherence status of
            each dose in this medication event. You cannot undo this action.
          </>
        ) : deleteEventStatus || resetEventStatus ? (
          <>
            Please type &quot;{status}&quot; to delete the medication event(s)
            {deleteHistoryOnly ? <></> : <> AND</>} history. You cannot undo
            this action.
          </>
        ) : (
          <>
            Please type &quot;{status}&quot; to update the status of this
            medication event. You cannot undo this action.
          </>
        )}
      </div>
      <TextField
        value={typedStatus}
        variant="outlined"
        placeholder={`Type ${status}...`}
        onChange={(e) => setTypedStatus(e.target.value)}
        fullWidth
      />
      {dispencedStatus && !incorrectTypedStatus && (
        <div style={{ marginLeft: '15px', paddingTop: '10px' }}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: '200px', marginLeft: '8px' }}
          >
            <InputLabel>Dispensed Flow</InputLabel>
            <StyledSelect
              value={dispensedFlow}
              onChange={handleChangeDispensedFlow}
              disabled={!dispencedStatus || incorrectTypedStatus}
              label="Dispensed Flow"
            >
              <MenuItem value={'past_due'}>Past Due</MenuItem>
              <MenuItem value={'on_the_go'}>On The Go</MenuItem>
              <MenuItem value={'med_assist'}>Med Assist</MenuItem>
            </StyledSelect>
          </FormControl>
        </div>
      )}
    </Modal>
  );
}

export default SetStatusModal;
