/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Actionable snapshot information for the customer.
 */
export type GetQuickUpdate = {
  /**
   * The primary message of the quick update
   */
  title?: string;
  /**
   * The recommended action for the customer to take
   */
  call_to_action?: GetQuickUpdate.call_to_action;
  /**
   * Seconday recommended action for the customer to take
   */
  call_to_action_secondary?: GetQuickUpdate.call_to_action_secondary;
  /**
   * The focus of the quick updates.
   */
  type?: GetQuickUpdate.type;
  /**
   * The resource the quick update is focused on.
   */
  resource?: {
    /**
     * the id of the resource the quick update is about, for OWNER it is customer id.
     */
    id?: number;
    /**
     * the name of the resource the quick update is about.
     */
    name?: GetQuickUpdate.name;
  };
};

export namespace GetQuickUpdate {
  /**
   * The recommended action for the customer to take
   */
  export enum call_to_action {
    INVITE_CONTACTS = 'INVITE_CONTACTS',
    VIEW_HEALTH_PROFILE = 'VIEW_HEALTH_PROFILE',
    ACCEPT_INVITE = 'ACCEPT_INVITE',
  }

  /**
   * Seconday recommended action for the customer to take
   */
  export enum call_to_action_secondary {
    DECLINE_INVITE = 'DECLINE_INVITE',
  }

  /**
   * The focus of the quick updates.
   */
  export enum type {
    MEDICATION = 'MEDICATION',
    CONTACTS = 'CONTACTS',
    CONNECTIVITY = 'CONNECTIVITY',
  }

  /**
   * the name of the resource the quick update is about.
   */
  export enum name {
    OWNER = 'OWNER',
  }
}
