// [TODO] Convert AnserLib to TS
//@ts-ignore
import axios from 'axios';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '..';
import { oAuthHost, restHost } from '../../apiConfig';
import {
  deleteToken,
  formatUrl,
  getCookie,
  jsonToFormUrlEncoded,
} from '../../shared/utils';
import { TabSchema } from '../GlobalTypes';
import {
  Careteam,
  CareteamInvite,
  CustomerStore,
  CustomerStoreActionTypes,
  Owner,
  CreateCustomerPayload,
} from './customerTypes';

export const apiError = (error: any) => ({
  type: CustomerStoreActionTypes.CUSTOMERS_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: CustomerStoreActionTypes.CUSTOMERS_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const setCreateUserSuccessful = (success: boolean) => ({
  type: CustomerStoreActionTypes.SUCCESS_CREATE_USER,
  payload: { createUserSuccessful: success },
});

export const populateCustomers = (customerList: Owner[]) => ({
  type: CustomerStoreActionTypes.POPULATE_CUSTOMERS,
  payload: { customerList },
});

export const populateCustomer = (data: Owner) => ({
  type: CustomerStoreActionTypes.POPULATE_CUSTOMER,
  payload: { customer: data },
});

export const populateCustomerCareTeam = (data: Owner) => ({
  type: CustomerStoreActionTypes.POPULATE_CUSTOMER_CARE_TEAM,
  payload: { careTeam: data },
});

export const clearCustomerAndCareTeam = () => ({
  type: CustomerStoreActionTypes.CLEAR_CUSTOMER_AND_CARE_TEAM,
});

export const populateCareTeamDetails = (data: {
  [key: string]: Careteam[];
}) => ({
  type: CustomerStoreActionTypes.POPULATE_CARE_TEAM_DETAILS,
  payload: { careTeamDetails: data },
});

export const populateCareteamInvites = (data: CareteamInvite[] | null) => ({
  type: CustomerStoreActionTypes.POPULATE_CARETEAM_INVITES,
  payload: { careteamInvites: data },
});

export const clearCareTeamDetails = () => ({
  type: CustomerStoreActionTypes.CLEAR_CARE_TEAM_DETAILS,
});

const creatingNewPrimaryContactDidSuccess = () => ({
  type: CustomerStoreActionTypes.CREATE_PRIMARY_CONTACT_SUCCESS,
});

export const addNewPrimaryContact = (contactDetails: Owner) => {
  return async (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    const { customer_id, ...params } = contactDetails;
    axios
      .post(formatUrl(oAuthHost, `owner/${customer_id}/careteam`), params, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        if (!res.status) throw res;
        toast.success('The account has been created');
        dispatch(creatingNewPrimaryContactDidSuccess());
        dispatch(getCustomerCareTeam(customer_id));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        let message;
        if (err?.response?.status === 400) {
          message = 'This email address already exists, please try again';
        } else
          message = err?.response?.data || 'Failed to create a user account';
        dispatch(setLoadState(false));
        toast.error(message);
      });
  };
};

export const getCustomerList = (facilityId: number | null = null) => {
  return (dispatch: ThunkDispatch<RootState, void, AnyAction> & Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `customers`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        params: facilityId ? { facility_id: facilityId } : {},
      })
      .then((res) => {
        const customers =
          res.data.length > 0
            ? res.data.map((obj: { created_at: Date }) => {
                const createdAtDateTime = DateTime.fromJSDate(
                  new Date(obj.created_at)
                );
                const relativeCreatedAt = createdAtDateTime.toRelative(
                  DateTime.now()
                );
                return {
                  ...obj,
                  created_at: relativeCreatedAt,
                  created_at_string: new Date(obj.created_at).toLocaleString(),
                };
              })
            : [];
        dispatch(populateCustomers(customers));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getFacilityCustomers = (facilityId: number) => {
  return getCustomerList(facilityId);
};

export const createUser = (data: Partial<CreateCustomerPayload>) => {
  let params = jsonToFormUrlEncoded(data);

  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'facility/customer'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        if (!res.status) throw res;
        toast.success('The account has been created');
        dispatch(setCreateUserSuccessful(true));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        let message;
        if (err?.response?.status === 409) {
          message = 'This username already exists, please try again';
        } else message = 'Failed to create a user account';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getCustomer = (customerId: number) => {
  return (dispatch: ThunkDispatch<RootState, void, AnyAction> & Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `customer/${customerId}`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        if (res.data.is_patient) {
          dispatch(getCustomerCareTeam(res.data.customer_id));
        }
        dispatch(populateCustomer(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getCustomerCareTeam = (customerId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `customer/${customerId}/careteam`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateCustomerCareTeam(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getCareTeamDetails = (customerId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    axios
      .get(formatUrl(restHost, `customer/${customerId}`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateCareTeamDetails(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data || 'Failed to load user details');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getPendingCareteamInvites = (customerId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    axios
      .get(formatUrl(restHost, `customer/${customerId}/careteam/invites`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateCareteamInvites(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data || 'Failed to load pending invites');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const createCareteamInvite = (
  customerId: number,
  userProps: Partial<CareteamInvite>,
  type = 'send'
) => {
  return (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    axios
      .post(
        formatUrl(restHost, `customer/${customerId}/careteam/invites`),
        userProps,
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        dispatch(getPendingCareteamInvites(customerId));
        toast.success('Invite sent successfully');
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data || `Failed to ${type} invite`);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const deleteCareteamInvite = (customerId: number, email: string) => {
  return (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    axios
      .delete(
        formatUrl(restHost, `customer/${customerId}/careteam/invites/${email}`),
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        toast.success('Invite deleted successfully');
        dispatch(getPendingCareteamInvites(customerId));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data || 'Failed to delete invite');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const sideBarTabSelection = (selectedTab: TabSchema) => ({
  type: CustomerStoreActionTypes.CUSTOMERS_SIDE_BAR_TAB_SELECTION,
  payload: { selectedTab },
});

export const saveOwnerPin = (ownerPin: string | null) => ({
  type: CustomerStoreActionTypes.SAVE_OWNER_PIN,
  payload: { ownerPin: ownerPin },
});

export const fetchOwnerPin = (password: string, ownerId: number) => {
  const params = {
    password: password,
  };

  return (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    axios
      .post(formatUrl(restHost, `customer/${ownerId}/pin`), params, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(saveOwnerPin(res.data.pin));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};
