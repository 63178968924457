import axios from 'axios';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { restHost } from '../../apiConfig';
import {
  deleteToken,
  formatUrl,
  getCookie,
  jsonToFormUrlEncoded,
} from '../../shared/utils';
import { reloadSoftwareVersions } from '../devices/actionCreators';
import {
  SoftwareActionType,
  SoftwareDeployment,
  SoftwareDeploymentRequest,
  SoftwareStore,
  SoftwareVersion,
  SoftwareVersionRequest,
  SoftwareVersionRequestV2,
} from './softwareTypes';

export const apiError = (error: any) => ({
  type: SoftwareActionType.SOFTWARE_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: SoftwareActionType.SOFTWARE_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const softwareCreatedEdited = () => ({
  type: SoftwareActionType.SOFTWARE_CREATED_EDITED,
});

export const setSoftwareVersions = (software: SoftwareVersion) => ({
  type: SoftwareActionType.SET_SOFTWARE_VERSIONS,
  payload: { software },
});

export const getSoftwareVersions = () => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/softwareVersion'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setSoftwareVersions(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const setSoftwareDeployment = (deployments: SoftwareDeployment) => ({
  type: SoftwareActionType.SET_SOFTWARE_DEPLOYMENT,
  payload: { deployments },
});

export const getSoftwareDeployment = () => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/softwareDeployment'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setSoftwareDeployment(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const editSoftwareVersion = (
  data: Partial<SoftwareVersionRequestV2>
) => {
  return (
    dispatch: ThunkDispatch<SoftwareStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, 'eng/softwareVersion'), data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        dispatch(softwareCreatedEdited());
        dispatch(setLoadState(false));
        dispatch(reloadSoftwareVersions());
        dispatch(getSoftwareVersions());
        toast.success('The software version has been edited.');
      })
      .catch((err) => {
        let message;
        if (err.response.status === 401) {
          deleteToken();
        }
        if (err.response.status === 409) {
          message = 'This software version already exists, please try again';
          toast.info(message);
        }
        if (err.response.status !== 401 || err.response.status !== 409) {
          message = 'Failed to edit a software version';
          toast.error(message);
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const createSoftwareVersion = (data: SoftwareVersionRequest) => {
  let queryParams = jsonToFormUrlEncoded(data);
  return (
    dispatch: ThunkDispatch<SoftwareStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'eng/softwareVersion'), queryParams, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        console.log('createSoftware: ', res.data);
        dispatch(softwareCreatedEdited());
        dispatch(setLoadState(false));
        dispatch(reloadSoftwareVersions());
        toast.success('The software version has been created');
        dispatch(getSoftwareVersions());
      })
      .catch((err) => {
        let message;
        if (err.response.status === 401) {
          deleteToken();
        }
        if (err.response.status === 409) {
          message = 'This software version already exists, please try again';
          toast.info(message);
        }
        if (err.response.status !== 401 || err.response.status !== 409) {
          message = 'Failed to create a software version';
          toast.error(message);
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const setLatestSoftwareVersion = (version: Partial<SoftwareVersion>) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(
        formatUrl(restHost, `eng/setCurrentSoftwareVersion/${version.id}`),
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        dispatch(softwareCreatedEdited());
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const createSoftwareDeployment = (data: SoftwareDeploymentRequest) => {
  let queryParams = jsonToFormUrlEncoded({
    channel_id: data.channel_id,
    documentation_url: data.documentation_url,
  });
  return (
    dispatch: ThunkDispatch<SoftwareStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .post(
        formatUrl(restHost, `eng/deployVersion/${data.version_id}`),
        queryParams,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        dispatch(getSoftwareDeployment());
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};
