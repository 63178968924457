import { Reducer } from 'redux';

import {
  HubOwnerStore,
  HubOwnerStoreAction,
  HubOwnerStoreActionTypes,
} from './types';

const initialState: HubOwnerStore = {
  importMedicationRollSuccess: false,
};

const hubOwnerReducer: Reducer<HubOwnerStore, HubOwnerStoreAction> = (
  state = initialState,
  action
): HubOwnerStore => {
  switch (action.type) {
    case HubOwnerStoreActionTypes.CREATE_MEDICATION_ROLL_SUCCESS: {
      let newState = {
        ...state,
        importMedicationRollSuccess: true,
      };
      return newState;
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE_TEMPLATE: {
      const { medScheduleTemplate } = action.payload;

      let newState = {
        ...state,
        medScheduleTemplate: medScheduleTemplate,
      };
      return newState;
    }
    case HubOwnerStoreActionTypes.RESET_IMPORT_MED_ROLL_FORM: {
      return {
        ...state,
        importMedicationRollSuccess: false,
      };
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE: {
      const { medSchedule } = action.payload;

      return {
        ...state,
        medSchedule: medSchedule,
      };
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_EVENT_HISTORY: {
      const { medEventHistory, ownerId, medEventId } = action.payload;

      return {
        ...state,
        medEventHistory: {
          ownerId,
          medEventId,
          history: medEventHistory,
        },
      };
    }
    default:
      return state;
  }
};

export default hubOwnerReducer;
