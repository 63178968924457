import MaterialTable, { Column, Options } from '@material-table/core';
import { Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { sortByFullName, TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getCustomerList } from '../../store/customers/actionCreators';
import { Owner } from '../../store/customers/customerTypes';
import { getPharmacies } from '../../store/pharmacy/actionCreators';
import { Pharmacy } from '../../store/pharmacy/pharmacyTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';

const materialTableColumns: Column<Owner>[] = [
  {
    title: 'Name',
    field: 'name',
    customSort: sortByFullName,
  },
  {
    title: 'Username',
    field: 'email',
  },
  {
    title: 'Date of birth',
    field: 'date_of_birth',
    type: 'date',
  },
];

const Owners = () => {
  const { owners, connected, pharmacies } = useSelector<
    RootState,
    {
      owners: Owner[] | null;
      connected: boolean;
      pharmacies: Pharmacy[] | null;
    }
  >((state) => {
    const { customers, webSocket, pharmacy } = state;
    const { owners } = customers;
    const { connected } = webSocket;
    const { pharmacies } = pharmacy;
    return {
      owners,
      connected,
      pharmacies,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!owners) {
      dispatch(getCustomerList());
    }
    if (isNull(pharmacies)) {
      dispatch(getPharmacies());
    }
  }, [connected, owners, dispatch, pharmacies]);

  if (isNull(owners)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Owners</title>
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant={'h1'}>Owner list</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={materialTableColumns}
          data={owners.sort(sortByFullName)}
          options={{
            idSynonym: 'customer_id',
            pageSize: 150,
            pageSizeOptions: [150, 250, 350],
            ...(TableOptions as Partial<Options<Owner>>),
          }}
        />
      </Wrapper>
    </Container>
  );
};

export default Owners;
