/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventHubUptimeStatus = {
  owner_id?: number;
  status?: EventHubUptimeStatus.status;
};

export namespace EventHubUptimeStatus {
  export enum status {
    ONLINE = 'online',
    OFFLINE = 'offline',
  }
}
