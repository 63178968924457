import {
  faAngleDown,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  PopperProps,
  SelectChangeEvent,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/system';
import { cloneDeep, isEqual, isNil, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  darkPurple,
  lightBackground2,
  lightPurple20,
} from '../../../../mainTheme';
import {
  FACILITY_ROLE,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../../../shared/constants';
import {
  ageRangeFilter,
  dateRangeFilter,
  dropDownListFilter,
  getPrivilegeById,
  splitZipCodes,
} from '../../../../shared/utils';
import { RootState } from '../../../../store';
import { Owner } from '../../../../store/customers/customerTypes';
import { DashboardUser } from '../../../../store/dashboardUser/dashboardUserTypes';
import { FacilityInfo } from '../../../../store/facility/facilityTypes';
import { Pharmacy } from '../../../../store/pharmacy/pharmacyTypes';
import {
  buildFilterAction,
  changeFilterEvent,
  clearFilters,
  downloadAdherenceReport,
  getAdherenceData,
  removeFilterHandler,
  selectFilterValueEvent,
  selectRequiredFilterValueEvent,
} from '../../../../store/reporting/actionCreators';
import {
  AdherenceData,
  AgeRangeValue,
  BasicSelectedValue,
  FilterValues,
  RequiredFilterValues,
  SelectedFilter,
  SelectedValue,
} from '../../../../store/reporting/reportingTypes';
import AdherenceGraph from './AdherenceGraph';
import {
  AgeRanges,
  EventTypes,
  FilterData,
  Genders,
  MedAdminTimes,
  OwnerFilterDataItem,
  ReportDenominators,
  States,
  Timespans,
} from './FilterData';
import StyledSelect from '../../../Shared/StyledSelect';

const CustomChip = styled(Chip)({
  height: '50px',
  fontSize: '18px',
  color: darkPurple,
  background: lightPurple20,
  margin: '5px',
  borderRadius: '30px',
});

// datePicker header fits 18px font
const datePickerPopperProps: Partial<PopperProps> = {
  sx: { '.MuiPickersCalendarHeader-label': { fontSize: '18px' } },
  placement: 'bottom-start',
};

interface AdherenceReportProps {
  title: string;
}

function AdherenceReport(props: AdherenceReportProps) {
  const {
    filterValues,
    defaultFilterValues,
    selectedFilters,
    isLoading,
    selectedFilter,
    facilities,
    pharmacies,
    requiredFilterValues,
    adherenceData,
    dashboardUserInfo,
    facilityCustomers,
  } = useSelector<
    RootState,
    {
      filterValues: Partial<FilterValues>;
      defaultFilterValues: FilterValues;
      selectedFilters: SelectedFilter[];
      isLoading: boolean;
      selectedFilter: string;
      requiredFilterValues: RequiredFilterValues;
      adherenceData: AdherenceData | null;
      facilities: FacilityInfo[] | null;
      pharmacies: Pharmacy[] | null;
      dashboardUserInfo: DashboardUser | null;
      facilityCustomers: Owner[] | null;
    }
  >((state) => {
    const { dashboardUser, reporting, facility, pharmacy, customers } = state;
    const {
      filterValues,
      defaultFilterValues,
      selectedFilters,
      isLoading,
      selectedFilter,
      requiredFilterValues,
      adherenceData,
    } = reporting;
    const { dashboardUserInfo } = dashboardUser;
    const { facilities } = facility;
    const { pharmacies } = pharmacy;
    const { facilityCustomers } = customers;
    return {
      filterValues,
      defaultFilterValues,
      selectedFilters,
      isLoading,
      selectedFilter,
      facilities,
      pharmacies,
      requiredFilterValues,
      adherenceData,
      dashboardUserInfo,
      facilityCustomers,
    };
  });
  const dispatch = useDispatch();
  const { title } = props;

  const startYesterday = moment.utc().subtract(1, 'days').set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const endYesterday = moment.utc().subtract(1, 'days').set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });

  // values for each datePicker
  const [customFromDate, setCustomFromDate] =
    useState<moment.Moment>(startYesterday);
  const [customToDate, setCustomToDate] = useState<moment.Moment>(endYesterday);

  // most recent valid date range selected (to avoid duplicate filters)
  const [customDateRange, setCustomDateRange] = useState<moment.Moment[]>([
    startYesterday,
    endYesterday,
  ]);

  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [selectedAgeRange, setAgeRange] = useState([50, 65]);

  const closeIcon = (
    <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} />
  );

  const FacilityList = useMemo(() => {
    return facilities
      ? facilities.map((facility) => ({
          display: facility.name,
          value: `${facility.id}`,
        }))
      : [];
  }, [facilities]);

  const PharmacyList = useMemo(() => {
    return pharmacies
      ? pharmacies.map((pharmacy) => ({
          display: pharmacy.name,
          value: `${pharmacy.id}`,
        }))
      : [];
  }, [pharmacies]);

  const owners = useMemo(() => {
    if (
      (dashboardUserInfo?.facility_role_id &&
        !UNIFIED_FACILITY_ADMIN_ROLES.includes(
          dashboardUserInfo.facility_role_id
        )) ||
      !facilityCustomers
    )
      return [];
    return facilityCustomers
      .filter(
        (customer) =>
          customer.facility_role_id === FACILITY_ROLE.FACILITY_PATIENT
      )
      .sort((a, b) =>
        a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0
      )
      .map((customer) => ({
        display: customer.first_name + ' ' + customer.last_name,
        value: `${customer.customer_id}`,
      }));
  }, [facilityCustomers, dashboardUserInfo]);

  const filterData = useMemo(() => {
    const isEngineering =
      dashboardUserInfo &&
      getPrivilegeById(dashboardUserInfo.privilege_id) === 'engineering';
    if (isEngineering) {
      return FilterData;
    }
    const fData = FilterData.filter((item) => item.value !== 'facilityId');
    if (
      dashboardUserInfo?.facility_role_id &&
      UNIFIED_FACILITY_ADMIN_ROLES.includes(dashboardUserInfo.facility_role_id)
    ) {
      fData.push(OwnerFilterDataItem);
      const disableOtherFilters = selectedFilters.some(
        (item) => item.filterValue === 'ownerIds'
      );
      const disableOwnerFilter = selectedFilters.some(
        (item) =>
          item.filterValue !== 'medAdminTime' &&
          item.filterValue !== 'eventType' &&
          FilterData.find((fItem) => fItem.value === item.filterValue)
      );

      fData.forEach(
        (item) =>
          (item.disabled =
            item.value === 'ownerIds'
              ? disableOwnerFilter
              : item.value === 'medAdminTime' || item.value === 'eventType'
              ? false
              : disableOtherFilters)
      );

      fData.sort((a, b) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      );
    }

    return fData;
  }, [dashboardUserInfo, selectedFilters]);

  const dispatchRequiredFilterValueEvent = (
    payload: Partial<RequiredFilterValues>
  ) => {
    dispatch(selectRequiredFilterValueEvent(payload));
  };

  const onSetReportDenominator = (event: SelectChangeEvent<string>) => {
    return (
      dispatchRequiredFilterValueEvent({
        groupBy: event.target.value as string,
      }),
      dropDownListFilter(
        ReportDenominators,
        event.target.value as string,
        {
          display: 'Sort by',
          value: 'groupBy',
        },
        buildFilter
      )
    );
  };

  const onSetDateRange = (event: SelectChangeEvent<string>) => {
    switch (event.target.value) {
      case 'yesterday': {
        return (
          dispatchRequiredFilterValueEvent({
            dateRange: event.target.value,
            fromDate: startYesterday,
            toDate: endYesterday,
          }),
          dateRangeFilter(
            {
              groupBy: requiredFilterValues.groupBy,
              dateRange: event.target.value,
              fromDate: startYesterday,
              toDate: endYesterday,
            },
            {
              display: 'Date range',
              value: 'datePicker',
            },
            buildFilter
          )
        );
      }
      case 'last7Days': {
        const last7Days = moment(startYesterday).subtract(6, 'days');
        return (
          dispatchRequiredFilterValueEvent({
            dateRange: event.target.value,
            fromDate: last7Days,
            toDate: endYesterday,
          }),
          dateRangeFilter(
            {
              groupBy: requiredFilterValues.groupBy,
              dateRange: event.target.value,
              fromDate: last7Days,
              toDate: endYesterday,
            },
            {
              display: 'Date range',
              value: 'datePicker',
            },
            buildFilter
          )
        );
      }
      case 'last14Days': {
        const last14Days = moment(startYesterday).subtract(13, 'days');
        return (
          dispatchRequiredFilterValueEvent({
            dateRange: event.target.value,
            fromDate: last14Days,
            toDate: endYesterday,
          }),
          dateRangeFilter(
            {
              groupBy: requiredFilterValues.groupBy,
              dateRange: event.target.value,
              fromDate: last14Days,
              toDate: endYesterday,
            },
            {
              display: 'Date range',
              value: 'datePicker',
            },
            buildFilter
          )
        );
      }
      case 'last30Days': {
        const last30Days = moment(startYesterday).subtract(29, 'days');
        return (
          dispatchRequiredFilterValueEvent({
            dateRange: event.target.value,
            fromDate: last30Days,
            toDate: endYesterday,
          }),
          dateRangeFilter(
            {
              groupBy: requiredFilterValues.groupBy,
              dateRange: event.target.value,
              fromDate: last30Days,
              toDate: endYesterday,
            },
            {
              display: 'Date range',
              value: 'datePicker',
            },
            buildFilter
          )
        );
      }
      case 'datePicker': {
        return (
          dispatchRequiredFilterValueEvent({
            dateRange: event.target.value,
            fromDate: customFromDate,
            toDate: customToDate,
          }),
          dateRangeFilter(
            {
              groupBy: requiredFilterValues.groupBy,
              dateRange: event.target.value,
              fromDate: customFromDate,
              toDate: customToDate,
            },
            {
              display: 'Date range',
              value: 'datePicker',
            },
            buildFilter
          )
        );
      }
    }
  };

  const isValidDateRange = (
    beginDate: moment.Moment,
    endDate: moment.Moment
  ) => {
    return (
      beginDate?.isValid() &&
      endDate?.isValid() &&
      beginDate.isSameOrBefore(startYesterday, 'day') &&
      endDate.isSameOrBefore(endYesterday, 'day') &&
      beginDate.diff(endDate) <= 0
    );
  };

  const handleCustomDateRangeChange = (
    beginDate: moment.Moment,
    endDate: moment.Moment
  ) => {
    if (!isValidDateRange(beginDate, endDate)) return;
    return (
      setCustomDateRange([beginDate, endDate]),
      dispatchRequiredFilterValueEvent({
        dateRange: requiredFilterValues.dateRange,
        fromDate: beginDate,
        toDate: endDate,
      }),
      dateRangeFilter(
        {
          groupBy: requiredFilterValues.groupBy,
          dateRange: 'datePicker',
          fromDate: beginDate,
          toDate: endDate,
        },
        {
          display: 'Date range',
          value: 'datePicker',
        },
        buildFilter
      )
    );
  };

  const handleCustomFromDateChange = (date: moment.Moment | null) => {
    if (!date || !date.isValid()) return;
    date = date.utc(true).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    setCustomFromDate(date);
    if (date.isSame(customDateRange[0], 'day')) return;
    handleCustomDateRangeChange(date, customToDate);
  };

  const handleCustomToDateChange = (date: moment.Moment | null) => {
    if (!date || !date.isValid()) return;
    date = date.utc(true).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    setCustomToDate(date);
    if (date.isSame(customDateRange[1], 'day')) return;
    handleCustomDateRangeChange(customFromDate, date);
  };

  const zipCodeFilter = (
    selectedZipCodes: string | undefined,
    selectedFilter: BasicSelectedValue,
    buildFilter: (
      selectedFilter: BasicSelectedValue,
      selectedFilterValue: BasicSelectedValue | BasicSelectedValue[]
    ) => void
  ) => {
    if (!selectedZipCodes) {
      return;
    }
    const existingZipCodes = cloneDeep(
      selectedFilters.find((filter) => filter.filterValue === 'zipCodes')
    );
    const selectedExistingZipCodes =
      existingZipCodes && existingZipCodes.selectedValues
        ? existingZipCodes.selectedValues
        : [];
    const chips: BasicSelectedValue[] = [];
    const formattedZipCodes = splitZipCodes(selectedZipCodes);
    formattedZipCodes.zipCodeList.forEach((i) => {
      chips.push({
        value: i,
        display: i,
      });
    });
    const existingZipCodeValues = new Set(
      selectedExistingZipCodes.map(({ value }) => value)
    );
    const filteredChips = chips.filter(
      ({ value }) => !existingZipCodeValues.has(value)
    );

    if (chips.length > filteredChips.length) {
      toast.info('You have attempted to add zip code(s) that already exist');
    }
    if (formattedZipCodes.invalidZipCodeList.length > 0) {
      toast.info('Please add zip code(s) in correct zip code format');
    }
    if (formattedZipCodes.zipCodeList.length > 0) {
      buildFilter(selectedFilter, uniqBy(filteredChips, 'value'));
    }
  };

  const addNewFilter = () => {
    const newSelectedFilter = filterData.filter(
      (item) => item.value === selectedFilter
    )[0];
    if (newSelectedFilter.value === 'ageRange') {
      ageRangeFilter(
        AgeRanges,
        filterValues.ageRange,
        selectedAgeRange,
        newSelectedFilter,
        buildFilter
      );
      setAgeRange([50, 65]);
    } else if (newSelectedFilter.value === 'gender') {
      dropDownListFilter(
        Genders,
        filterValues.gender,
        newSelectedFilter,
        buildFilter
      );
    } else if (newSelectedFilter.value === 'eventType') {
      dropDownListFilter(
        EventTypes,
        filterValues.eventTypeId,
        newSelectedFilter,
        buildFilter
      );
    } else if (selectedFilter === 'state') {
      dropDownListFilter(
        States,
        filterValues.state,
        newSelectedFilter,
        buildFilter
      );
      setSelectedState(null);
    } else if (newSelectedFilter.value === 'zipCodes') {
      zipCodeFilter(filterValues.zipCodes, newSelectedFilter, buildFilter);
    } else if (newSelectedFilter.value === 'pharmacyId') {
      dropDownListFilter(
        PharmacyList,
        filterValues.pharmacyId,
        newSelectedFilter,
        buildFilter
      );
    } else if (newSelectedFilter.value === 'facilityId') {
      dropDownListFilter(
        FacilityList,
        filterValues.facilityId,
        newSelectedFilter,
        buildFilter
      );
    } else if (newSelectedFilter.value === 'medAdminTime') {
      dropDownListFilter(
        MedAdminTimes,
        filterValues.medAdminTime,
        newSelectedFilter,
        buildFilter
      );
    } else if (newSelectedFilter.value === 'ownerIds') {
      dropDownListFilter(
        owners,
        filterValues.ownerIds,
        newSelectedFilter,
        buildFilter
      );
    }
  };

  const buildFilter = (
    newSelectedFilter: BasicSelectedValue,
    selectedFilterValue: BasicSelectedValue | BasicSelectedValue[]
  ) => {
    if (!isLoading) {
      dispatch(
        buildFilterAction({
          selectedFilters: selectedFilters,
          selectedFilter: newSelectedFilter,
          selectedFilterValue: selectedFilterValue,
        })
      );
    }
  };

  const onFilterSelect = (event: SelectChangeEvent<string>) => {
    dispatch(changeFilterEvent(event.target.value as string));
  };

  const selectFilterValue = (selectedFilterValues: Partial<FilterValues>) => {
    dispatch(selectFilterValueEvent(selectedFilterValues));
  };

  const getFacilityFilterForAdminAndScheduler = () => {
    const facilityFilter: SelectedFilter[] = [];
    if (dashboardUserInfo) {
      if (getPrivilegeById(dashboardUserInfo.privilege_id) !== 'engineering') {
        facilityFilter.push({
          filterValue: 'facilityId',
          filterDisplay: 'Facility',
          selectedValues: [
            {
              display: dashboardUserInfo.facility_name
                ? dashboardUserInfo.facility_name
                : '',
              value: dashboardUserInfo.facility_name
                ? dashboardUserInfo.facility_name
                : '',
            },
          ],
        });
      }
    }
    return facilityFilter;
  };

  const facilityFilter = getFacilityFilterForAdminAndScheduler();

  const manipulatedSelectedFilters = [...selectedFilters, ...facilityFilter];

  const handleSearch = () => {
    dispatch(getAdherenceData(manipulatedSelectedFilters));
  };

  const onFilterValueSelect = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<string | AgeRangeValue>
  ) => {
    if (event.target.name === 'ageRange') {
      selectFilterValue({
        ageRange: event.target.value as string,
      });
    } else if (event.target.name === 'gender') {
      selectFilterValue({
        gender: event.target.value as string,
      });
    } else if (event.target.name === 'eventType') {
      selectFilterValue({
        eventTypeId: event.target.value as string,
      });
    } else if (event.target.name === 'state') {
      selectFilterValue({
        state: event.target.value as string,
      });
    } else if (event.target.name === 'zipCodes') {
      selectFilterValue({
        zipCodes: event.target.value as string,
      });
    } else if (event.target.name === 'pharmacyId') {
      selectFilterValue({
        pharmacyId: event.target.value as string,
      });
    } else if (event.target.name === 'facilityId') {
      selectFilterValue({
        facilityId: event.target.value as string,
      });
    } else if (event.target.name === 'medAdminTime') {
      selectFilterValue({
        medAdminTime: event.target.value as string,
      });
    } else if (event.target.name === 'ownerIds') {
      selectFilterValue({
        ownerIds: event.target.value as string,
      });
    }
  };

  const removeFilter = (mainFilter: string, filterVal: string) => {
    dispatch(
      removeFilterHandler({
        selectedFilters: selectedFilters,
        facilityFilter: facilityFilter,
        mainFilterValue: mainFilter,
        filterValue: filterVal,
        selectedFilter: selectedFilter,
        requiredFilterValues: requiredFilterValues,
      })
    );
  };

  const setCustomAgeRange = (_event: Event, ageRange: number | number[]) => {
    return (
      setAgeRange(ageRange as number[]),
      selectFilterValue({
        ageRange: 'custom',
        customAgeRange: ageRange as number[],
      })
    );
  };

  const resetFilters = () => {
    dispatch(clearFilters());
    setCustomFromDate(startYesterday);
    setCustomToDate(endYesterday);
    setCustomDateRange([startYesterday, endYesterday]);
  };

  const filterChip = (filterValue: string, val: SelectedValue) => {
    if (filterValue === 'datePicker' || filterValue === 'groupBy') {
      return <CustomChip key={val.display} label={val.display} />;
    } else
      return (
        <CustomChip
          key={val.display}
          label={val.display}
          onDelete={() => removeFilter(filterValue, val.value as string)}
          deleteIcon={
            <FontAwesomeIcon
              style={{ fontSize: '25px', color: darkPurple }}
              icon={faTimesCircle}
            />
          }
        />
      );
  };

  const filterChipWrapper = (item: SelectedFilter | undefined) => {
    if (!item) {
      return;
    }
    return (
      <List
        key={item.filterDisplay}
        sx={item.selectedValues.length > 4 ? { width: '610px' } : undefined}
        style={{ paddingBottom: 0 }}
      >
        {item.filterDisplay}:
        <ListItem style={{ paddingBottom: 0 }}>
          <Grid
            container
            spacing={3}
            style={{ marginLeft: '-12px', marginTop: '-12px' }}
          >
            <Grid container direction="row">
              {item.selectedValues.map((val) =>
                filterChip(item.filterValue as string, val)
              )}
            </Grid>
          </Grid>
        </ListItem>
      </List>
    );
  };

  return (
    <div>
      <Typography variant={'h1'}>{title}</Typography>
      <Grid container direction="row">
        <Grid item sx={{ marginRight: '75px' }}>
          <div>
            <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
              <InputLabel>Sort by*</InputLabel>
              <StyledSelect
                name="groupBy"
                value={requiredFilterValues.groupBy}
                onChange={onSetReportDenominator}
                sx={{ marginBottom: '25px', width: '300px' }}
                label="Sort by*"
              >
                {ReportDenominators.map((item) => (
                  <MenuItem
                    key={item.value as string}
                    value={item.value as string}
                  >
                    {item.display}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </div>
          <div>
            <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
              <InputLabel>Date range*</InputLabel>
              <StyledSelect
                name="dateRange"
                value={requiredFilterValues.dateRange}
                onChange={onSetDateRange}
                sx={{ marginBottom: '25px', width: '300px' }}
                label="Date range*"
              >
                {Timespans.map((item) => (
                  <MenuItem
                    key={item.value as string}
                    value={item.value as string}
                  >
                    {item.display}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </div>
          {requiredFilterValues.dateRange === 'datePicker' && (
            <div style={{ minWidth: '200px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="From date"
                  value={customFromDate}
                  onChange={handleCustomFromDateChange}
                  views={['year', 'month', 'day']}
                  maxDate={endYesterday}
                  PopperProps={datePickerPopperProps}
                  renderInput={(startProps: any) => (
                    <div style={{ display: 'block' }}>
                      <div style={{ marginBottom: '25px', width: '300px' }}>
                        <TextField
                          {...startProps}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="To date"
                  value={customToDate}
                  onChange={handleCustomToDateChange}
                  views={
                    customFromDate.isSame(moment(), 'month') &&
                    customToDate.isSame(moment(), 'month')
                      ? ['day']
                      : customFromDate.isSame(moment(), 'year') &&
                        customToDate.isSame(moment(), 'year')
                      ? ['month', 'day']
                      : ['year', 'month', 'day']
                  }
                  minDate={customFromDate}
                  maxDate={endYesterday}
                  PopperProps={datePickerPopperProps}
                  renderInput={(startProps: any) => (
                    <div style={{ display: 'block' }}>
                      <div style={{ marginBottom: '25px', width: '300px' }}>
                        <TextField
                          {...startProps}
                          variant="outlined"
                          fullWidth
                          helperText={
                            !isValidDateRange(customFromDate, customToDate)
                              ? 'Invalid Date Range'
                              : null
                          }
                        />
                      </div>
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
        </Grid>
        <Grid item>
          <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
            <InputLabel
              sx={{
                color:
                  isNil(
                    selectedFilters.find(
                      (filter) => filter.filterValue === 'groupBy'
                    )
                  ) ||
                  isNil(
                    selectedFilters.find(
                      (filter) => filter.filterValue === 'datePicker'
                    )
                  )
                    ? 'rgba(0,0,0,0.6)'
                    : undefined,
              }}
            >
              Add additional filters
            </InputLabel>
            <StyledSelect
              name="filterOptions"
              value={selectedFilter}
              onChange={onFilterSelect}
              inputProps={{
                id: 'filter-required',
              }}
              sx={{ marginBottom: '25px', width: '300px' }}
              disabled={
                isNil(
                  selectedFilters.find(
                    (filter) => filter.filterValue === 'groupBy'
                  )
                ) ||
                isNil(
                  selectedFilters.find(
                    (filter) => filter.filterValue === 'datePicker'
                  )
                )
              }
              label="Add additional filters"
            >
              {filterData.map((item) => (
                <MenuItem
                  key={item.value as string}
                  value={item.value as string}
                  disabled={item.disabled}
                >
                  {item.display}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          {selectedFilter === 'ageRange' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Age range</InputLabel>
                <StyledSelect
                  name="ageRange"
                  value={filterValues.ageRange}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Age range"
                >
                  {AgeRanges.map((item) => (
                    <MenuItem key={item.display} value={item.value as string}>
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
              {filterValues.ageRange === 'custom' && (
                <>
                  <Typography
                    id="range-slider"
                    sx={{ marginBottom: '35px', fontSize: '18px' }}
                  >
                    Custom age range
                  </Typography>
                  <Slider
                    value={selectedAgeRange}
                    onChange={setCustomAgeRange}
                    max={125}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    sx={{ marginBottom: '25px', width: '300px' }}
                  />
                </>
              )}
            </div>
          )}
          {selectedFilter === 'eventType' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Event type</InputLabel>
                <StyledSelect
                  name="eventType"
                  value={filterValues.eventTypeId}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Event type"
                >
                  {EventTypes.map((item) => (
                    <MenuItem
                      key={item.value as string}
                      value={item.value as string}
                    >
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          )}
          {selectedFilter === 'gender' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Gender</InputLabel>
                <StyledSelect
                  name="gender"
                  value={filterValues.gender}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Gender"
                >
                  {Genders.map((item) => (
                    <MenuItem
                      key={item.value as string}
                      value={item.value as string}
                    >
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          )}
          {selectedFilter === 'state' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <Autocomplete
                  id="state"
                  value={selectedState}
                  options={States}
                  getOptionLabel={(option) => option}
                  onChange={(_event, selectedValue) => {
                    if (!isNil(selectedValue)) {
                      setSelectedState(selectedValue);
                      const newEvent = {
                        target: {
                          name: 'state',
                          value: States.find(
                            (state) => state === selectedValue
                          ),
                        },
                      } as React.ChangeEvent<HTMLInputElement>;
                      onFilterValueSelect(newEvent);
                    } else {
                      setSelectedState('');
                      const newEvent = {
                        target: {
                          name: 'state',
                          value: '',
                        },
                      } as React.ChangeEvent<HTMLInputElement>;
                      onFilterValueSelect(newEvent);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="State" variant="outlined" />
                  )}
                  clearIcon={closeIcon}
                  popupIcon={
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{ color: '#737373', fontSize: '38px' }}
                    />
                  }
                  blurOnSelect
                  style={{ marginBottom: '25px', width: '300px' }}
                />
              </FormControl>
            </div>
          )}
          {selectedFilter === 'zipCodes' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <TextField
                  name="zipCodes"
                  value={filterValues.zipCodes}
                  variant="outlined"
                  label="Zip code(s)"
                  placeholder="12345"
                  multiline
                  maxRows={1}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                />
              </FormControl>
            </div>
          )}
          {selectedFilter === 'pharmacyId' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Pharmacy</InputLabel>
                <StyledSelect
                  name="pharmacyId"
                  value={filterValues.pharmacyId}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Pharamacy"
                >
                  {PharmacyList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          )}
          {selectedFilter === 'facilityId' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Facility</InputLabel>
                <StyledSelect
                  name="facilityId"
                  value={filterValues.facilityId}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="StyledSelect a facility"
                >
                  {FacilityList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          )}
          {selectedFilter === 'medAdminTime' && (
            <div style={{ paddingBottom: '10px' }}>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Prescribed time</InputLabel>
                <StyledSelect
                  name="medAdminTime"
                  value={filterValues.medAdminTime as string}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Prescribed time"
                >
                  {MedAdminTimes.map((item) => (
                    <MenuItem key={item.display} value={item.value as string}>
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          )}
          {selectedFilter === 'ownerIds' && (
            <div>
              <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
                <InputLabel>Owner</InputLabel>
                <StyledSelect
                  name="ownerIds"
                  value={filterValues.ownerIds}
                  onChange={onFilterValueSelect}
                  sx={{ marginBottom: '25px', width: '300px' }}
                  label="Owner"
                >
                  {owners.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.display}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
              <div
                style={{
                  maxWidth: '305px',
                  marginTop: '-15px',
                  marginBottom: '15px',
                  fontSize: '13px',
                }}
              >
                If selecting a specific owner, only prescribed time is available
                as an additional filter.
              </div>
            </div>
          )}
          {selectedFilters.length !== 0 && (
            <Grid item container direction="column" sx={{ minWidth: '200px' }}>
              {selectedFilter !== '' && (
                <div style={{ marginBottom: '25px' }}>
                  <Button
                    disabled={
                      isEqual(filterValues, defaultFilterValues) || isLoading
                    }
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={() => {
                      addNewFilter();
                    }}
                  >
                    Add filter
                  </Button>
                </div>
              )}
              <div
                style={selectedFilter === '' ? { marginTop: 10 } : undefined}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleSearch}
                  disabled={
                    requiredFilterValues.groupBy === '' ||
                    requiredFilterValues.dateRange === '' ||
                    (requiredFilterValues.dateRange === 'datePicker' &&
                      requiredFilterValues.fromDate === '')
                  }
                >
                  Search
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {selectedFilters.length > 0 && (
            <Card
              sx={{
                minWidth: '500px',
                marginLeft: '50px',
                background: lightBackground2,
              }}
            >
              <CardContent>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    {!isNil(
                      selectedFilters.find(
                        (filter) => filter.filterValue === 'groupBy'
                      )
                    )
                      ? filterChipWrapper(
                          selectedFilters.find(
                            (filter) => filter.filterValue === 'groupBy'
                          )
                        )
                      : null}
                  </Grid>
                  <Grid item xs={6}>
                    {!isNil(
                      selectedFilters.find(
                        (filter) => filter.filterValue === 'datePicker'
                      )
                    )
                      ? filterChipWrapper(
                          selectedFilters.find(
                            (filter) => filter.filterValue === 'datePicker'
                          )
                        )
                      : null}
                  </Grid>
                </Grid>
                {selectedFilters.filter(
                  (item) =>
                    item.filterValue !== 'datePicker' &&
                    item.filterValue !== 'groupBy'
                ).length > 0 && (
                  <>
                    <span style={{ fontWeight: 600 }}>Additional filters</span>
                    <List style={{ paddingBottom: 0 }}>
                      {selectedFilters
                        .filter(
                          (item) =>
                            item.filterValue !== 'datePicker' &&
                            item.filterValue !== 'groupBy'
                        )
                        .map((item) => filterChipWrapper(item))}
                    </List>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={resetFilters}
                  sx={{ marginLeft: '17px' }}
                >
                  Clear all
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>
      </Grid>
      {!isNil(adherenceData) && (
        <>
          <div style={{ width: '600px', height: '600px' }}>
            <AdherenceGraph adherenceData={adherenceData} />
          </div>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() =>
              dispatch(downloadAdherenceReport(manipulatedSelectedFilters))
            }
            disabled={
              adherenceData.numberAdherent === 0 &&
              adherenceData.numberNonAdherent === 0 &&
              adherenceData.numberEvents === 0
            }
            sx={{ margin: '50px 0 0 8px' }}
          >
            Download CSV
          </Button>
        </>
      )}
    </div>
  );
}

export default AdherenceReport;
