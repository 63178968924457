/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of med event status
 */
export enum MedEventStatusEnum {
  MISSED = 'MISSED',
  COMPLETE = 'COMPLETE',
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
}
