import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import DataRowItem from '../../Shared/DataRowItem';
import { Owner } from '../../../store/customers/customerTypes';
import {
  convertSecondsToMinutes,
  formatDurationFromMinutes,
} from '../../../shared/utils';

interface OwnerDetailViewProps {
  customer: Owner;
  title: string;
  isLoading: boolean;
  setShowPinRequestModal(arg0: boolean): void;
  isCC: boolean;
}

const OwnerDetailView = (props: OwnerDetailViewProps) => {
  const { customer, title, isLoading, setShowPinRequestModal, isCC } = props;

  return (
    <div>
      <Typography variant={'h1'}>{title}</Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        <DataRowItem
          title="First name"
          data={customer.first_name}
          titleSize={3}
        />
        <DataRowItem
          title="Last name"
          data={customer.last_name}
          titleSize={3}
        />
        <DataRowItem
          title="Date of birth"
          data={customer.long_format_dob}
          titleSize={3}
        />
        <DataRowItem
          title="Phone number"
          data={customer.phone_number}
          titleSize={3}
        />
        <DataRowItem
          title="Associated facility"
          data={customer.facility_name}
          titleSize={3}
        />
        <DataRowItem
          title="Medication window time"
          data={formatDurationFromMinutes(
            convertSecondsToMinutes(customer.med_window_time_sec)
          )}
          titleSize={3}
        />
        <DataRowItem title="Address" data={customer.address1} titleSize={3} />
        <DataRowItem
          title="Address 2 (optional)"
          data={customer.address2}
          titleSize={3}
        />
        <DataRowItem title="City" data={customer.city} titleSize={3} />
        <DataRowItem title="State" data={customer.state} titleSize={3} />
        <DataRowItem title="Zip code" data={customer.zipcode} titleSize={3} />
        <DataRowItem title="Country" data={customer.country} titleSize={3} />
        <DataRowItem
          title="Pharmacy"
          data={customer.pharmacy_name}
          titleSize={3}
        />
        <DataRowItem
          title="Pharmacy owner ID"
          data={customer.pharmacy_patient_id}
          titleSize={3}
        />
        {isCC && (
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={() => setShowPinRequestModal(true)}
            sx={{ margin: '17px 0 0 17px' }}
          >
            View PIN
          </Button>
        )}
      </Grid>
    </div>
  );
};

export default OwnerDetailView;
