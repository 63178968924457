import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oAuthHost, restHost, storageHost, webSocketHost } from './apiConfig';
import Caregivers from './components/Customers/Caregivers';
import CareTeamDetails from './components/Customers/components/CareTeamDetails';
import CreatePrimaryContact from './components/Customers/components/CreatePrimaryContact';
import CustomerDetail from './components/Customers/components/CustomerDetail';
import MedicationEventHistory from './components/Customers/components/MedicationEventHistory';
import CreateNewFacilityUser from './components/Customers/CreateNewFacilityUser';
import CreateNewOwner from './components/Customers/CreateNewOwner';
import Customers from './components/Customers/Customers';
import Owners from './components/Customers/Owners';
import Dashboard from './components/Dashboard/Dashboard';
import DeviceDetail from './components/Devices/DeviceDetail';
import Devices from './components/Devices/Devices';
import Operations from './components/Operations/Operations';
import AddNewFacility from './components/Facility/AddNewFacility';
import Facilities from './components/Facility/Facilities';
import FacilityDetail from './components/Facility/FacilityDetail';
import MyFacilityDetail from './components/Facility/MyFacilityDetail';
import MyAccount from './components/MyAccount/MyAccount';
import NavBar from './components/Dashboard/NavBar';
import Pharmacies from './components/Pharmacy/Pharmacies';
import PharmacyDetail from './components/Pharmacy/PharmacyDetail';
import Register from './components/Register';
import Reporting from './components/Reporting/Reporting';
import CreateNewSoftwareVersion from './components/SoftwareVersions/CreateNewSoftwareVersion';
import EditSoftwareVersion from './components/SoftwareVersions/EditSoftwareVersion';
import SoftwareDeployment from './components/SoftwareVersions/SoftwareVersionDeployment';
import Software from './components/SoftwareVersions/SoftwareVersions';
import './css/App.css';
import './css/toast.css';
import { formatUrl, getCookie } from './shared/utils';
import 'react-toastify/dist/ReactToastify.css';
import { Toolbar } from '@mui/material';

const App = () => {
  const match_register = useRouteMatch<{ token: string }>('/register/:token');

  const SentryRoute = Sentry.withSentryRouting(Route);

  const history = useHistory();

  Sentry.init({
    dsn:
      process.env.NODE_ENV === 'development'
        ? ''
        : 'https://207dc18ba022416c8b41fb9b4c2351ee@o926607.ingest.sentry.io/6375017',
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [oAuthHost, restHost, storageHost, webSocketHost],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  if (match_register) {
    return <Register match={match_register} />;
  } else if (!getCookie('access_token')) {
    window.location.href = formatUrl(oAuthHost);
    return null;
  } else {
    return (
      <BrowserRouter>
        <NavBar />
        <Toolbar />
        <Switch>
          <SentryRoute exact path="/" component={Dashboard} />
          <SentryRoute path="/caregivers" component={Caregivers} />
          <SentryRoute exact path="/customers" component={Customers} />
          <SentryRoute
            exact
            path="/customers/create"
            component={CreateNewFacilityUser}
          />
          <SentryRoute
            exact
            path="/customers/owner/create"
            component={CreateNewOwner}
          />
          <SentryRoute
            exact
            path="/customers/:customer_id"
            component={CustomerDetail}
          />
          <SentryRoute
            exact
            path="/customers/:customer_id/medication/:med_event_id/history"
            component={MedicationEventHistory}
          />
          <SentryRoute
            exact
            path="/customers/:customer_id/care-team/:care_team_id"
            component={CareTeamDetails}
          />
          <SentryRoute
            exact
            path="/customers/:customer_id/create-primary-contact"
            component={CreatePrimaryContact}
          />
          <SentryRoute path="/devices" component={Devices} />
          <SentryRoute
            path="/device/:deviceSerialNumber"
            component={DeviceDetail}
          />
          <SentryRoute exact path="/facility" component={MyFacilityDetail} />
          <SentryRoute exact path="/facilities" component={Facilities} />
          <SentryRoute
            exact
            path="/facilities/add"
            component={AddNewFacility}
          />
          <SentryRoute
            exact
            path="/facilities/:facility_id"
            component={FacilityDetail}
          />
          <SentryRoute exact path="/myAccount" component={MyAccount} />
          <SentryRoute path="/owners" component={Owners} />
          <SentryRoute exact path="/pharmacies" component={Pharmacies} />
          <SentryRoute
            exact
            path="/pharmacies/:pharmacy_id"
            component={PharmacyDetail}
          />
          <SentryRoute exact path="/reporting" component={Reporting} />
          <SentryRoute exact path="/ops" component={Operations} />
          <SentryRoute path="/software" component={Software} />
          <SentryRoute
            path="/softwareDeployment"
            component={SoftwareDeployment}
          />
          <SentryRoute
            path="/softwareVersion/create"
            component={CreateNewSoftwareVersion}
          />
          <SentryRoute
            path="/softwareVersion/:versionId"
            component={EditSoftwareVersion}
          />
        </Switch>
        <ToastContainer
          position="bottom-left"
          autoClose={10000}
          hideProgressBar={true}
          closeOnClick
          transition={Slide}
        />
      </BrowserRouter>
    );
  }
};

export default App;
