import { Box, Grid, Card, CardContent } from '@mui/material';
import React from 'react';
import { FormikProps, Field } from 'formik';

import { TextInputFormField } from '../TextInputFormField';

import { Pharmacy } from '../../../store/pharmacy/pharmacyTypes';

import { FormValues } from '../ImportMedicationRollStepFormTypes';

import * as Yup from 'yup';
import { DateTime } from 'luxon';

import { startCase as _startCase } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { Medication } from '../../../generated';

interface MedicationRollFormReviewProps {
  formik: FormikProps<FormValues>;
  pharmacies: Pharmacy[] | null;
  templateRemoteDate?: DateTime;
  templateRemoteBatchId?: Number;
  key: number;
}

const MedicationRollFormReview = (props: MedicationRollFormReviewProps) => {
  const { formik, pharmacies } = props;

  const meds = useSelector<RootState, Array<Medication>>(
    (state) => state.medications.medications
  );

  const pharmacyOptions = pharmacies
    ? pharmacies.map((pharmacy) => {
        return {
          label: pharmacy.name,
          value: pharmacy.id,
        };
      })
    : [];

  /* TODO: map this to a pharmacy configuration rather than a hardcoded pharmacy name */
  const isFullPharmacyIntegration: boolean =
    props.pharmacies?.find((x) => x.id === formik.values.pharmacy)?.name !==
    'DivvyDOSE';

  return (
    <React.Fragment>
      <Grid container alignItems="flex-start">
        <Grid item xs={12}>
          <Box fontWeight={'bold'} display={'inline'}>
            Pharmacy:{' '}
          </Box>
          <Box display={'inline'}>
            {pharmacyOptions?.length && props.formik.values.pharmacy
              ? pharmacyOptions.find(
                  (x) => x.value === props.formik.values.pharmacy
                )?.label
              : 'N/A'}
          </Box>
        </Grid>
        {isFullPharmacyIntegration && (
          <Grid item xs={12}>
            <Box fontWeight={'bold'} display={'inline'}>
              Batch ID:{' '}
            </Box>
            <Box display={'inline'}>{formik.values.batchId}</Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box fontWeight={'bold'} display={'inline'}>
            Start date:{' '}
          </Box>
          <Box display={'inline'}>
            {formik.values.startDate.toLocaleString(DateTime.DATE_FULL)}
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ marginTop: '10px' }}>
          <Box fontWeight={'bold'} display={'inline'}>
            Repeating for:{' '}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Field
            label=""
            name="scheduleTemplateRepeatDays"
            component={TextInputFormField}
            InputProps={{
              endAdornment: <Box>Days</Box>,
            }}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} marginTop={'5px'}>
        {props.formik.values.medEvents.map((medEvent, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container alignItems={'flex-start'}>
                    <Grid item xs={12}>
                      <Box>
                        Daily,
                        {' ' +
                          medEvent.desiredTimeOfDay?.toLocaleString(
                            DateTime.TIME_SIMPLE
                          )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        {_startCase(
                          medEvent.eventType
                            .replaceAll('_', ' ')
                            .toLocaleLowerCase()
                        )}
                      </Box>
                    </Grid>
                    {isFullPharmacyIntegration && (
                      <Grid item xs={12}>
                        <Box>{medEvent.pouches.length} Pouch</Box>
                      </Grid>
                    )}
                    <Grid container>
                      {medEvent.pouches.map((pouch, pIndex) => {
                        return (
                          <Grid item xs={12} key={pIndex} marginTop={'20px'}>
                            {isFullPharmacyIntegration && (
                              <Box display={'block'} fontWeight={'bold'}>
                                Pouch {pouch.pouchNumber}
                              </Box>
                            )}
                            {pouch.drugs.map((drug) => {
                              return (
                                <Box display={'block'} key={drug.ndc}>
                                  {(meds.find(
                                    (x) => x.national_drug_code === drug.ndc
                                  )?.name || drug.ndc) +
                                    ` - ${
                                      drug.rollPackaged === true
                                        ? 'Roll'
                                        : 'Vial'
                                    } - ${
                                      drug.isPrn === true
                                        ? 'As Needed'
                                        : 'Routine'
                                    }`}
                                </Box>
                              );
                            })}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

MedicationRollFormReview.label = 'Review medication schedule';
MedicationRollFormReview.initialValues = {
  scheduleTemplateRepeatDays: 30,
};

MedicationRollFormReview.validationSchema = Yup.object({});

export default MedicationRollFormReview;
