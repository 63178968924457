/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of med assist
 */
export enum MedEventType {
  SMART_ASSIST = 'SMART_ASSIST',
  CAREGIVER_ASSIST = 'CAREGIVER_ASSIST',
  ON_THE_GO = 'ON_THE_GO',
}
