import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';
import Modal from '../../Shared/Modal';
import { TextInputFormField } from '../../Shared/TextInputFormField';

interface ConfirmNewFacilityModalProps {
  onClose(): void;
  onContinue(arg: { name: string }): void;
  facilityName: string;
}

const ConfirmNewFacilityModal = (props: ConfirmNewFacilityModalProps) => {
  const { onClose, onContinue, facilityName } = props;
  const formikRef = useRef<FormikProps<{ name: string }>>(null);

  const schema = yup.object({
    name: yup
      .string()
      .required('This field is required')
      .test('match', 'Facility name does not match', function (value) {
        if (!value) {
          return false;
        }
        return value === facilityName;
      }),
  });

  const handleContinue = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const onSubmit = () => {
    if (formikRef.current) {
      onContinue({ name: formikRef.current.values.name });
    }
  };

  return (
    <Modal
      onClose={onClose}
      actions={{ primaryAction: { onClick: handleContinue, text: 'Continue' } }}
    >
      <div style={{ marginBottom: 10 }}>
        Are you sure you want to add {facilityName}?
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          name: '',
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Repeat name"
              placeholder={`Enter ${facilityName} to continue`}
              component={TextInputFormField}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfirmNewFacilityModal;
