import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import React from 'react';

const CustomExpandMore = ({ ...rest }) => {
  return <FontAwesomeIcon icon={faAngleDown} size="2x" {...rest} />;
};

interface StyledSelectProps {
  label?: string;
  name?: string;
  children: JSX.Element | JSX.Element[];
  value: any;
  onChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  inputProps?: any;
  sx?: SxProps<Theme> | undefined;
}

function StyledSelect(props: StyledSelectProps) {
  const { label, name, value, onChange, disabled, children, inputProps, sx } =
    props;

  return (
    <Select
      fullWidth
      labelId="select_form_field"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputProps={inputProps}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            maxHeight: '500px',
          },
        },
      }}
      displayEmpty
      IconComponent={CustomExpandMore}
      sx={sx}
    >
      {children}
    </Select>
  );
}

export default StyledSelect;
