import { Medication } from '../../generated';

export enum MedicationStoreActionTypes {
  GET_MEDICATION_SUCCESS = 'GET_MEDICATION_SUCCESS',
}

export type GetMedicationSuccessAction = {
  type: MedicationStoreActionTypes.GET_MEDICATION_SUCCESS;
  payload: Array<Medication>;
};

export type MedicationStore = {
  medications: Array<Medication>;
};

export type MedicationStoreAction = GetMedicationSuccessAction;

export interface Drug {
  nationalDrugCode: string;
  name: string;
}
