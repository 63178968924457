import { Button, DialogActions, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { SOFTWARE_CHANNEL_ID } from '../../../shared/constants';
import {
  SoftwareDeploymentRequest,
  SoftwareVersion,
} from '../../../store/software/softwareTypes';
import Modal from '../../Shared/Modal';
import SelectFormField from '../../Shared/SelectFormField';
import { TextInputFormField } from '../../Shared/TextInputFormField';

interface DeploySoftwareVersionModalProps {
  onClose(): void;
  softwareVersionsList: { label: string; value: number }[];
  onSubmit(
    data: SoftwareDeploymentRequest & { confirm_version?: string }
  ): void;
  softwareVersionMap: { [key: string]: SoftwareVersion };
}
const softwareChannels = [
  { label: 'Stable', value: SOFTWARE_CHANNEL_ID.STABLE },
  { label: 'Beta', value: SOFTWARE_CHANNEL_ID.BETA },
];

const DeploySoftwareVersionModal = (props: DeploySoftwareVersionModalProps) => {
  const { softwareVersionMap, softwareVersionsList, onClose, onSubmit } = props;

  const schema = yup.object({
    version_id: yup.number().required('This field is required'),
    channel_id: yup.number().required('This field is required'),
    confirm_version: yup
      .string()
      .required('This field is required')
      .test('match', 'Versions do not match', function (value) {
        const data = this.parent;
        return softwareVersionMap[data.version_id]
          ? softwareVersionMap[data.version_id].version === value
          : false;
      }),
  });

  const handleOnSubmit = (
    data: SoftwareDeploymentRequest & { confirm_version: string }
  ) => {
    onSubmit(data);
  };

  return (
    <Modal onClose={onClose}>
      <div style={{ marginBottom: 10 }}>Depoly new software version</div>
      <Formik
        validationSchema={schema}
        onSubmit={handleOnSubmit}
        initialValues={{
          version_id: '',
          channel_id: '',
          documentation_url: '',
          confirm_version: '',
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field
                  label="Version"
                  name="version_id"
                  placeholder="Version"
                  component={SelectFormField}
                  options={softwareVersionsList}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Software channel"
                  name="channel_id"
                  placeholder="Please select a software channel"
                  component={SelectFormField}
                  options={softwareChannels}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Documentation URL"
                  name="documentation_url"
                  placeholder="Documentation URL"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Confirm software version"
                  name="confirm_version"
                  placeholder="Confirm software version"
                  component={TextInputFormField}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                Deploy
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DeploySoftwareVersionModal;
