import { REPORTING_TABS } from '../../shared/constants';
import isNil from 'lodash/isNil';
import {
  ReportingAction,
  ReportingStore,
  ReportingStoreActionTypes,
} from './reportingTypes';
import { Reducer } from 'redux';

const initialState: ReportingStore = {
  error: null,
  isLoading: false,
  selectedTab: REPORTING_TABS.DASHBOARD,
  selectedFilters: [],
  requiredFilterValues: {
    groupBy: '',
    dateRange: '',
    fromDate: '',
    toDate: '',
  },
  filterValues: {
    ageRange: '',
    customAgeRange: [],
    gender: '',
    state: '',
    zipCodes: '',
    pharmacyId: '',
    facilityId: '',
    medAdminTime: '',
    ownerIds: '',
    eventTypeId: '',
  },
  defaultFilterValues: {
    ageRange: '',
    customAgeRange: [],
    gender: '',
    state: '',
    zipCodes: '',
    pharmacyId: '',
    facilityId: '',
    medAdminTime: '',
    ownerIds: '',
    eventTypeId: '',
  },
  selectedFilter: '',
  adherenceData: null,
};

const reportingReducer: Reducer<ReportingStore, ReportingAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ReportingStoreActionTypes.REPORTING_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', error);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case ReportingStoreActionTypes.REPORTING_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case ReportingStoreActionTypes.REPORTING_SIDE_BAR_TAB_SELECTION: {
      const { selectedTab } = action.payload;
      let newState = {
        ...state,
        selectedTab: selectedTab,
      };
      return newState;
    }
    case ReportingStoreActionTypes.BUILD_FILTER_EVENT: {
      const { selectedFilters } = action.payload;
      const newState = {
        ...state,
        selectedFilters: selectedFilters,
        // Resetting the value after adding
        filterValues: initialState.filterValues,
      };
      return newState;
    }
    case ReportingStoreActionTypes.RECIEVE_ADHERENCE_DATA: {
      const { adherenceData } = action.payload;
      const newState = {
        ...state,
        adherenceData: isNil(adherenceData)
          ? initialState.adherenceData
          : {
              numberAdherent: adherenceData.numberAdherent,
              numberNonAdherent: adherenceData.numberNonAdherent,
              numberEvents:
                adherenceData.numberAdherent + adherenceData.numberNonAdherent,
              groupBy: adherenceData.groupBy,
            },
      };
      return newState;
    }
    case ReportingStoreActionTypes.REMOVE_FILTER_EVENT: {
      const { selectedFilters, selectedFilter, requiredFilterValues } =
        action.payload;
      const newState = {
        ...state,
        selectedFilters: selectedFilters,
        selectedFilter: selectedFilter,
        adherenceData: initialState.adherenceData,
        requiredFilterValues: requiredFilterValues,
      };
      return newState;
    }
    case ReportingStoreActionTypes.CHANGE_FILTER_EVENT: {
      const { selectedFilter } = action.payload;
      const newState = {
        ...state,
        selectedFilter: selectedFilter,
        filterValues: initialState.filterValues,
      };
      return newState;
    }
    case ReportingStoreActionTypes.SELECT_FILTER_VALUE_EVENT: {
      const { filterValues } = action.payload;
      const newState = {
        ...state,
        filterValues: filterValues,
      };
      return newState;
    }
    case ReportingStoreActionTypes.SELECT_REQUIRED_FILTER_VALUE_EVENT: {
      const { groupBy, dateRange, fromDate, toDate } = action.payload;
      const newState = {
        ...state,
        requiredFilterValues: {
          groupBy: !isNil(groupBy)
            ? groupBy
            : state.requiredFilterValues.groupBy,
          dateRange: !isNil(dateRange)
            ? dateRange
            : state.requiredFilterValues.dateRange,
          fromDate: !isNil(fromDate)
            ? fromDate
            : state.requiredFilterValues.fromDate,
          toDate: !isNil(toDate) ? toDate : state.requiredFilterValues.toDate,
        },
      };
      return newState;
    }
    default:
      return state;
  }
};

export default reportingReducer;
