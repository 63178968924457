import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, {
  Column,
  Options,
  MTableToolbar,
} from '@material-table/core';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Link,
} from '@mui/material';
import { styled } from '@mui/system';
import isNull from 'lodash/isNull';
import { DateTime } from 'luxon';
import { newlyCreated, TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getBoundingBoxImages } from '../../store/devices/actionCreators';
import { BBImageSetMetadata } from '../../store/devices/deviceTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';
import ImageCarousel from './components/ImageCarousel';

function Operations() {
  const { bbImages } = useSelector<
    RootState,
    { bbImages: BBImageSetMetadata[] | null }
  >((state) => {
    const { devices } = state;
    const { bbImages } = devices;
    return {
      bbImages,
    };
  });

  const dispatch = useDispatch();

  const [openCarousel, setOpenCarousel] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[] | null>(null);
  const [selectedSentryEventId, setSelectedSentryEventId] = useState<
    string | null
  >(null);
  const [filterOption, setFilterOption] = useState<string>('Last 7 Days');

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption]);

  const handleViewClick = (images: string[], sentryEventId: string) => {
    setSelectedSentryEventId(sentryEventId);
    setSelectedImages(images);
    setOpenCarousel(true);
  };

  const handleFilterChange = (event: any) => {
    setFilterOption(event.target.value as string);
  };

  const applyFilter = () => {
    let startDate: DateTime | undefined = undefined;

    switch (filterOption) {
      case 'Last 7 Days':
        startDate = DateTime.now().minus({ days: 7 });
        break;
      case 'Last 2 Weeks':
        startDate = DateTime.now().minus({ weeks: 2 });
        break;
      case 'Last Month':
        startDate = DateTime.now().minus({ months: 1 });
        break;
      case 'All Time':
      default:
        startDate = undefined;
    }

    dispatch(getBoundingBoxImages(startDate));
  };

  const TableActionsWrapper = styled('div')(() => ({
    position: 'absolute',
    top: '10px',
    left: '10px',
  }));
  const CustomToolbar = (props: any) => {
    return (
      <div>
        <MTableToolbar {...props} />
        <TableActionsWrapper>
          <FormControl variant="outlined" size="medium" sx={{ width: '200px' }}>
            <InputLabel>Filter time range</InputLabel>
            <Select
              value={filterOption}
              onChange={handleFilterChange}
              label="Filter Time Range"
            >
              <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
              <MenuItem value="Last 2 Weeks">Last 2 Weeks</MenuItem>
              <MenuItem value="Last Month">Last Month</MenuItem>
              <MenuItem value="All Time">All Time</MenuItem>
            </Select>
          </FormControl>
        </TableActionsWrapper>
      </div>
    );
  };

  const sentryDeepLinkPrefix =
    'https://omcare.sentry.io/performance/summary/events/?project=5877591&query=id%3A';
  const sentryDeepLinkSuffix =
    '&sort=timestamp&statsPeriod=30d&transaction=Dispense';
  const columns: Column<BBImageSetMetadata>[] = [
    {
      title: 'Sentry Event',
      field: 'sentry_event_id',
      render: (rowData) => (
        <Link
          href={`${sentryDeepLinkPrefix}${rowData.sentry_event_id}${sentryDeepLinkSuffix}`}
          target="_blank"
          rel="noopener"
        >
          {rowData.sentry_event_id}
        </Link>
      ),
    },
    {
      title: 'Serial number',
      field: 'serial_number',
    },
    {
      title: 'Owner Id',
      field: 'owner_id',
    },
    {
      title: 'Prescribed Date',
      field: 'med_event_id',
      render: (rowData) =>
        rowData.med_event_id === 'demo_mode' ? (
          <span>Demo Mode</span>
        ) : (
          <span>
            {DateTime.fromISO(
              new Date(rowData.med_event_id).toISOString()
            ).toLocaleString(DateTime.DATETIME_MED)}
          </span>
        ),
    },
    {
      title: 'Uploaded At',
      field: 'uploaded_at',
      defaultSort: 'desc',
      customSort: (a, b) => a.uploaded_at.localeCompare(b.uploaded_at),
      render: (rowData) => {
        const newRowData: any = {};
        newRowData.created_at_string = new Date(
          rowData.uploaded_at
        ).toLocaleString();
        newRowData.created_at = DateTime.fromISO(
          rowData.uploaded_at
        ).toRelative(DateTime.now());
        return newlyCreated(newRowData, 1);
      },
    },
    {
      sorting: false,
      render: (rowData) => (
        <Button
          variant="contained"
          color="info"
          sx={{
            minWidth: '0px',
            borderRadius: '0px',
          }}
          onClick={() =>
            handleViewClick(
              rowData.images.map((img) => img.presignedUrl),
              rowData.sentry_event_id
            )
          }
        >
          View
        </Button>
      ),
    },
  ];

  if (isNull(bbImages)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Operations</title>
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant={'h1'}>Bounding Box Images</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={columns}
          data={bbImages}
          onRowClick={(_, rowData) => {
            if (rowData)
              handleViewClick(
                rowData.images.map((img) => img.presignedUrl),
                rowData.sentry_event_id
              );
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 100, 150],
            ...(TableOptions as Partial<Options<BBImageSetMetadata>>),
          }}
        />
      </Wrapper>
      <Dialog
        open={openCarousel}
        onClose={() => setOpenCarousel(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Link
            href={`${sentryDeepLinkPrefix}${selectedSentryEventId}${sentryDeepLinkSuffix}`}
            target="_blank"
            rel="noopener"
          >
            {selectedSentryEventId}
          </Link>
        </DialogTitle>
        <DialogContent>
          {selectedImages && <ImageCarousel images={selectedImages} />}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default Operations;
