import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  getDeviceList,
  requestDeviceLog,
} from '../../../store/devices/actionCreators';
import { setLoadState } from '../../../store/software/actionCreators';
import Modal from '../../Shared/Modal';
import { TextInputFormField } from '../../Shared/TextInputFormField';

interface RequestDeviceLogProps {
  serialNumber: string;
  onSuccess(): void;
  onCancel(): void;
}

const RequestDeviceLog = (props: RequestDeviceLogProps) => {
  const { serialNumber, onSuccess, onCancel } = props;

  const dispatch = useDispatch();

  const formikRef = useRef<FormikProps<{ note: string }>>(null);

  const schema = yup.object({
    note: yup.string().required('This field is required'),
  });

  const onSubmit = (values: FormValues) => {
    dispatch(setLoadState(true));
    dispatch(requestDeviceLog(serialNumber, values.note));
    dispatch(getDeviceList());
    dispatch(setLoadState(false));
    onSuccess();
  };

  interface FormValues {
    note: string;
  }

  const initialValues: FormValues = { note: '' };

  return (
    <Modal
      onClose={onCancel}
      actions={{
        primaryAction: {
          onClick: () => {
            formikRef.current && formikRef.current.submitForm();
          },
          text: 'Request',
        },
      }}
    >
      <div style={{ marginBottom: 10 }}>Why do you want to pull this log?</div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="note"
              label="Log reason"
              placeholder="Log reason"
              component={TextInputFormField}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RequestDeviceLog;
