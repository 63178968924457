import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import restClient from '../../services/rest';
import {
  MedicationStoreActionTypes,
  MedicationStore,
  GetMedicationSuccessAction,
} from './types';
import { Medication } from '../../generated';

function getMedicationSuccess(
  data: Array<Medication>
): GetMedicationSuccessAction {
  return {
    type: MedicationStoreActionTypes.GET_MEDICATION_SUCCESS,
    payload: data,
  };
}

export const getMedications = (pharmacyId?: number) => {
  return async (
    dispatch: ThunkDispatch<MedicationStore, void, AnyAction> & Dispatch
  ) => {
    try {
      const meds = await restClient.medications.listMedications(pharmacyId);

      dispatch(getMedicationSuccess(meds));
    } catch (err: any) {
      toast.error(err?.response?.data || err);
    }
  };
};
