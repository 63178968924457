export interface FacilityInfo extends Facility {
  admins: Admin[];
  invites: FacilityAdminInvite[];
}

export interface Facility {
  id: number;
  name: string;
  med_window_time_sec: number;
  created_at: string;
}

export interface Admin {
  name: string;
  email: string;
}

export interface FacilityAdminInvite {
  email: string;
  facility_id: number;
  facility_role_id: number;
  expires_at: string;
}

export interface NewFacility {
  name: string;
  med_window_time_sec?: number;
}

export interface UpdateFacility {
  med_window_time_sec: number;
}

export interface FacilityAdminRegisterData {
  first_name: string;
  last_name: string;
  password: string;
  password2: string;
  token: string;
}

export interface FacilityDevice {
  id: number;
  serial_number: string;
  owner_name?: string;
  synced_by_name?: string;
  owner?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  synced_by?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  hardware_version?: string;
  software_version?: string;
  software_channel_id?: number;
  pinned_software_version_id?: number;
  pinned_software_version_name?: string;
}

export interface FacilityDevicesState {
  facility_id: number;
  devices: FacilityDevice[];
}

export enum FacilityDeviceAssociationAction {
  LINK = 'LINK',
  UNLINK = 'UNLINK',
}

export interface FacilityStore {
  facilities: FacilityInfo[] | null;
  facilityInfo: Facility | null;
  facilityDevices?: FacilityDevicesState;
  goToFacilityPage: boolean;
  error: any | null;
  isLoading: boolean;
  showSuccessAlert: boolean;
  showErrorAlert: boolean;
  showDuplicateAlert: boolean;
  showDuplicateAlertFacility: boolean;
  showDuplicateAlertCareTeam: boolean;
}

export enum FacilityStoreActionTypes {
  FACILITY_ADDED = 'FACILITY_ADDED',
  FACILITY_API_ERR = 'FACILITY_API_ERR',
  FACILITY_SET_LOAD_STATE = 'FACILITY_SET_LOAD_STATE',
  POPULATE_FACILITIES = 'POPULATE_FACILITIES',
  POPULATE_FACILITY_DEVICES = 'POPULATE_FACILITY_DEVICES',
  CLEAR_FACILITY_DEVICES = 'CLEAR_FACILITY_DEVICES',
  POPULATE_FACILITY_INFO = 'POPULATE_FACILITY_INFO',
  SET_ERROR_ALERT = 'SET_ERROR_ALERT',
  SET_FACILITY_ADMINS = 'SET_FACILITY_ADMINS',
  SET_FACILITY_INVITES = 'SET_FACILITY_INVITES',
  SET_SUCCESS_ALERT = 'SET_SUCCESS_ALERT',
  SET_DUPLICATE_ALERT = 'SET_DUPLICATE_ALERT',
  SET_DUPLICATE_ALERT_FACILITY = 'SET_DUPLICATE_ALERT_FACILITY',
  SET_DUPLICATE_ALERT_CARETEAM = 'SET_DUPLICATE_ALERT_CARETEAM',
}

export type FacilityAddedType = {
  type: FacilityStoreActionTypes.FACILITY_ADDED;
};

export type ApiErrorType = {
  type: FacilityStoreActionTypes.FACILITY_API_ERR;
  payload: { error: any };
};

export type SetLoadStateType = {
  type: FacilityStoreActionTypes.FACILITY_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type PopulateFacilitiesType = {
  type: FacilityStoreActionTypes.POPULATE_FACILITIES;
  payload: { facilities: FacilityInfo[] };
};

export type PopulateFacilityDevicesType = {
  type: FacilityStoreActionTypes.POPULATE_FACILITY_DEVICES;
  payload: { facilityDevices: FacilityDevicesState };
};

export type ClearFacilityDevicesType = {
  type: FacilityStoreActionTypes.CLEAR_FACILITY_DEVICES;
};

export type PopulateFacilityInfo = {
  type: FacilityStoreActionTypes.POPULATE_FACILITY_INFO;
  payload: { facilityInfo: Facility };
};

export type SetErrorAlertType = {
  type: FacilityStoreActionTypes.SET_ERROR_ALERT;
  payload: { showErrorAlert: boolean };
};
export type SetDuplicateAlertType = {
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT;
  payload: { showDuplicateAlert: boolean };
};
export type SetDuplicateAlertTypeFacility = {
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT_FACILITY;
  payload: { showDuplicateAlertFacility: boolean };
};
export type SetDuplicateAlertTypeCareteam = {
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT_CARETEAM;
  payload: { showDuplicateAlertCareTeam: boolean };
};

export type SetFacilityAdminsType = {
  type: FacilityStoreActionTypes.SET_FACILITY_ADMINS;
  payload: { facilityId: number; admins: Admin[] };
};

export type SetFacilityInvitesType = {
  type: FacilityStoreActionTypes.SET_FACILITY_INVITES;
  payload: { facilityId: number; invites: FacilityAdminInvite[] };
};

export type SetSuccessAlertType = {
  type: FacilityStoreActionTypes.SET_SUCCESS_ALERT;
  payload: { showSuccessAlert: boolean };
};

export type FacilityStoreAction =
  | FacilityAddedType
  | ApiErrorType
  | SetLoadStateType
  | PopulateFacilitiesType
  | PopulateFacilityDevicesType
  | ClearFacilityDevicesType
  | PopulateFacilityInfo
  | SetErrorAlertType
  | SetFacilityAdminsType
  | SetFacilityInvitesType
  | SetDuplicateAlertType
  | SetDuplicateAlertTypeFacility
  | SetDuplicateAlertTypeCareteam
  | SetSuccessAlertType;
