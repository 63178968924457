import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import moment from 'moment';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamondExclamation } from '@fortawesome/pro-light-svg-icons';
import StyledSelect from '../../../Shared/StyledSelect';
import SetStatusModal from './SetStatusModal';
import SetCaregiverCustomerId from './SetCaregiverCustomerId';
import { UpdateForm } from '.';
import { Careteam } from '../../../../store/customers/customerTypes';

function areTimesEqual(dates: any[]) {
  // Parse the dates and extract the hours and minutes
  const parsedTimes = dates.map((date) => {
    const dt = DateTime.fromISO(date.prescribed_datetime);
    return { hour: dt.hour, minute: dt.minute };
  });

  // Compare the hours and minutes
  return parsedTimes.every((time, i, arr) => {
    // If it's the first element, return true as there's nothing to compare yet
    if (i === 0) return true;

    // Compare with the previous element
    return time.hour === arr[i - 1].hour && time.minute === arr[i - 1].minute;
  });
}

type Props = {
  privilegeId?: number;
  careTeam: Careteam[] | null;
  selectedMedEvents: any[];
  saveChanges(form: UpdateForm): void;
};

const UpdateDropdowns = (props: Props) => {
  const { privilegeId, careTeam, selectedMedEvents, saveChanges } = props;

  // newEventType state
  const [newEventType, setNewEventType] = useState('');
  const [newTime, setNewTime] = useState('');
  const [newStatus, setStatus] = useState('');
  const [dispensedFlow, setDispensedFlow] = useState('');
  const [caregiverCustomerId, setCaregiverCustomerId] = useState('');
  const [adherenceStatuses, setAdherenceStatuses] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCaregiverAssistModal, setShowCaregiverAssistModal] =
    useState(false);
  const [disableDesiredTime, setDisableDesiredTime] = useState(false); // Initialize disableDesiredTime state
  const [disableStatus, setDisableStatus] = useState(false); // Initialize disableStatus state
  const [disableMedEventUpdates, setDisableMedEventUpdates] = useState(false); // Initialize disableMedEventUpdates state
  const [disableAdherenceStatus, setDisableAdherenceStatus] = useState(false); // Initialize disableAdherenceStatus state
  const [deleteHistoryOnly, setDeleteHistoryOnly] = useState(true);

  const clearDropDowns = () => {
    setNewTime('');
    setStatus('');
    setNewEventType('');
    setDispensedFlow('');
    setCaregiverCustomerId('');
    setAdherenceStatuses({});
    setDisableStatus(false);
    setDisableMedEventUpdates(false);
  };

  let helperText = () => {
    const showDesiredTimeWarning = disableDesiredTime;
    const showInvalidatedStatusWarning = newStatus === 'INVALIDATED';
    const showDispensedStatusWarning = newStatus === 'DISPENSED';
    const showAdherenceStatusWarning = newStatus === 'ADHERENCE';
    const showDeleteStatusWarning = newStatus === 'DELETE';
    const showResetStatusWarning = newStatus === 'RESET';

    return (
      <>
        {showDesiredTimeWarning && (
          <>
            To edit the desired time, please select medication events that only
            have the same scheduled time or event type.
          </>
        )}
        {showDesiredTimeWarning &&
          (showInvalidatedStatusWarning ||
            showDispensedStatusWarning ||
            showAdherenceStatusWarning ||
            showDeleteStatusWarning) && <br />}
        {showDispensedStatusWarning && (
          <>
            <FontAwesomeIcon icon={faDiamondExclamation} /> Updating a
            medication event to dispensed status will remove the scheduled
            dose(s) from the corresponding care team&apos;s schedule.
          </>
        )}
        {showInvalidatedStatusWarning && (
          <>
            <FontAwesomeIcon icon={faDiamondExclamation} /> Deactivating a
            medication event will remove the scheduled dose(s) from the
            corresponding care team&apos;s schedule.
          </>
        )}
        {showAdherenceStatusWarning && (
          <>
            <FontAwesomeIcon icon={faDiamondExclamation} /> Marking Adherence on
            medication dose(s) will remove the scheduled dose(s) from the
            corresponding care team&apos;s schedule.
            {disableAdherenceStatus && (
              <>
                <br />
                <FontAwesomeIcon icon={faDiamondExclamation} /> Can only mark
                adherence on medication dose(s) in one medication event at a
                time.
              </>
            )}
          </>
        )}
        {showDeleteStatusWarning && (
          <>
            <FontAwesomeIcon icon={faDiamondExclamation} /> Deleting a
            medication event will remove the scheduled dose(s) from the
            corresponding care team&apos;s schedule.
          </>
        )}
        {showResetStatusWarning && (
          <>
            <FontAwesomeIcon icon={faDiamondExclamation} /> Resetting a
            medication event history will restore its status back to prescribed.
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (selectedMedEvents?.length) {
      const disableDesiredTimeStatus = !areTimesEqual(selectedMedEvents);
      setDisableDesiredTime(disableDesiredTimeStatus); // Update disableDesiredTime state
      if (disableDesiredTimeStatus) setNewTime('');
    } else clearDropDowns();
    setDisableAdherenceStatus(selectedMedEvents.length > 1);
  }, [selectedMedEvents]);

  const generateTimeSelections = () => {
    if (!selectedMedEvents.length) {
      return [];
    } else {
      const start = DateTime.fromISO(selectedMedEvents[0].prescribed_datetime, {
        setZone: true,
      }).minus({
        hour: 4,
      });

      const times = Array(33)
        .fill(0)
        .map((_, index) => {
          const newTime = start.plus({ minute: 15 * index });
          return {
            display: newTime.toLocaleString(DateTime.TIME_SIMPLE),
            value: newTime.toFormat('HH:mm'),
          };
        });

      const sortedTimes = times.sort(
        (a, b) =>
          moment(a.display, 'h:mm a').unix() -
          moment(b.display, 'h:mm a').unix()
      );
      return sortedTimes;
    }
  };

  const handleChangeDesiredTime = (event: SelectChangeEvent<string>) => {
    setDisableStatus(true);
    return setNewTime(event.target.value);
  };

  const handleChangeEventType = (event: SelectChangeEvent<string>) => {
    setDisableStatus(true);
    return setNewEventType(event.target.value);
  };

  const handleChangeStatus = (event: SelectChangeEvent<string>) => {
    setDisableMedEventUpdates(true);
    setDeleteHistoryOnly(!(event.target.value === 'DELETE'));
    return setStatus(event.target.value);
  };

  const handleChangeDispensedFlow = (event: SelectChangeEvent<string>) => {
    return setDispensedFlow(event.target.value);
  };

  const handleChangeCaregiverCustomerId = (
    event: SelectChangeEvent<string>
  ) => {
    return setCaregiverCustomerId(event.target.value);
  };

  const handleChangeAdherenceStatuses = (newStatusObj: any) => {
    setAdherenceStatuses(newStatusObj);
  };

  const cancelModal = () => {
    setShowStatusModal(false);
    setShowCaregiverAssistModal(false);
    clearDropDowns();
  };

  let disabled =
    (newTime === '' && newEventType === '' && newStatus === '') ||
    (newStatus === 'ADHERENCE' && disableAdherenceStatus) ||
    !selectedMedEvents.length;

  const customerCareActions = {
    display: privilegeId && privilegeId === 2 ? undefined : 'none',
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '15px', paddingTop: '10px' }}>
        Select one or more medication event(s) to edit the desired time, event
        type or status.
      </div>
      <div style={{ margin: '5px 36px 0 36px' }}>
        <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
          <InputLabel
            disabled={
              !selectedMedEvents.length ||
              disableDesiredTime ||
              disableMedEventUpdates
            }
          >
            Desired time
          </InputLabel>
          <StyledSelect
            value={newTime}
            onChange={handleChangeDesiredTime}
            disabled={
              !selectedMedEvents.length ||
              disableDesiredTime ||
              disableMedEventUpdates
            }
            label="Desired time"
          >
            {generateTimeSelections().map((time, index) => (
              <MenuItem key={index} value={time.value}>
                {time.display}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        <FormControl
          variant="outlined"
          sx={{ minWidth: '200px', marginLeft: '8px' }}
        >
          <InputLabel
            disabled={!selectedMedEvents.length || disableMedEventUpdates}
          >
            Event type
          </InputLabel>
          <StyledSelect
            value={newEventType}
            onChange={handleChangeEventType}
            disabled={!selectedMedEvents.length || disableMedEventUpdates}
            label="Event type"
          >
            <MenuItem value={'CAREGIVER_ASSIST'}>Caregiver assist</MenuItem>
            <MenuItem value={'SMART_ASSIST'}>Smart assist</MenuItem>
          </StyledSelect>
        </FormControl>
        <FormControl
          variant="outlined"
          sx={{ minWidth: '200px', marginLeft: '8px' }}
        >
          <InputLabel disabled={!selectedMedEvents.length || disableStatus}>
            Status
          </InputLabel>
          <StyledSelect
            value={newStatus}
            onChange={handleChangeStatus}
            disabled={!selectedMedEvents.length || disableStatus}
            label="Status"
          >
            <MenuItem value={'DISPENSED'}>Dispensed</MenuItem>
            <MenuItem value={'INVALIDATED'}>Invalidated</MenuItem>
            <MenuItem value={'ADHERENCE'}>Mark Adherence</MenuItem>
            <MenuItem style={customerCareActions} value={'RESET'}>
              Reset Med Event
            </MenuItem>
            <MenuItem style={customerCareActions} value={'DELETE'}>
              Delete Med Event
            </MenuItem>
          </StyledSelect>
        </FormControl>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => {
            if (newStatus.length) setShowStatusModal(true);
            else if (newEventType === 'CAREGIVER_ASSIST')
              setShowCaregiverAssistModal(true);
            else {
              saveChanges({
                newTime,
                newEventType,
                newStatus,
                dispensedFlow,
                caregiverCustomerId,
                adherenceStatuses,
                deleteHistoryOnly,
                selectedMedEvents,
              });
              cancelModal();
            }
          }}
          sx={{ marginLeft: '8px' }}
        >
          Save
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          disabled={false}
          onClick={() => {
            clearDropDowns();
          }}
          sx={{ marginLeft: '8px' }}
        >
          Cancel
        </Button>
        <FormHelperText sx={{ color: '#d32f2f' }}>
          {helperText()}
        </FormHelperText>
      </div>
      {showStatusModal && (
        <SetStatusModal
          status={newStatus.toLowerCase()}
          dispensedFlow={dispensedFlow}
          handleChangeDispensedFlow={handleChangeDispensedFlow}
          adherenceDoses={selectedMedEvents[0]?.doses}
          handleChangeAdherenceStatuses={handleChangeAdherenceStatuses}
          deleteHistoryOnly={deleteHistoryOnly}
          onClose={cancelModal}
          saveChanges={() => {
            saveChanges({
              newTime,
              newEventType,
              newStatus,
              dispensedFlow,
              caregiverCustomerId,
              adherenceStatuses,
              deleteHistoryOnly,
              selectedMedEvents,
            });
            cancelModal();
          }}
        />
      )}
      {showCaregiverAssistModal && (
        <SetCaregiverCustomerId
          careTeam={careTeam}
          caregiverCustomerId={caregiverCustomerId}
          handleChangeCaregiverCustomerId={handleChangeCaregiverCustomerId}
          onClose={cancelModal}
          saveChanges={() => {
            saveChanges({
              newTime,
              newEventType,
              newStatus,
              dispensedFlow,
              caregiverCustomerId,
              adherenceStatuses,
              deleteHistoryOnly,
              selectedMedEvents,
            });
            cancelModal();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default UpdateDropdowns;
