import { faRedo, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Grid, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PHARMACY_ROLE } from '../../shared/constants';
import {
  NameFormatter,
  sortByFullName,
  TableOptions,
} from '../../shared/tableUtils';
import { RootState } from '../../store';
import { ConfirmModalProps } from '../../store/GlobalTypes';
import {
  createPharmacyInvite,
  deletePharmacyInvite,
  getPharmacies,
  getPharmacyInvites,
  getPharmacyTechs,
} from '../../store/pharmacy/actionCreators';
import {
  Pharmacy,
  PharmacyInviteDeletePayload,
  PharmacyTechnician,
  PharmacyTechnicianInvite,
} from '../../store/pharmacy/pharmacyTypes';
import CreateInviteModal from '../Shared/CreateInviteModal';
import DataRowItem from '../Shared/DataRowItem';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Modal from '../Shared/Modal';
import { HeaderWrapper } from '../Shared/HeaderWrapper';

function PharmacyDetail() {
  const { pharmacies } = useSelector<
    RootState,
    {
      pharmacies: Pharmacy[] | null;
    }
  >((state) => {
    const { pharmacy } = state;
    const { pharmacies } = pharmacy;
    return {
      pharmacies,
    };
  });

  const dispatch = useDispatch();

  let { pharmacy_id = '' } = useParams<{ pharmacy_id: string }>();

  let pharmacy =
    pharmacies &&
    pharmacies.find((pharmacy) => pharmacy.id === Number(pharmacy_id));

  let [showCreateInviteModal, setShowCreateInviteModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] =
    useState<ConfirmModalProps | null>(null);

  useEffect(() => {
    if (isNil(pharmacy)) {
      getPharmacies();
    } else {
      if (isNil(pharmacy.techs)) {
        dispatch(getPharmacyTechs(Number(pharmacy_id)));
      }
      if (isNil(pharmacy.invites)) {
        dispatch(getPharmacyInvites(Number(pharmacy_id)));
      }
    }
  }, [pharmacy, pharmacy_id, dispatch]);

  const tech_columns: Column<PharmacyTechnician>[] = [
    {
      title: 'Name',
      field: 'name',
      render: NameFormatter,
      customSort: sortByFullName,
    },
    { title: 'Email address', field: 'email' },
  ];

  const invite_columns: Column<PharmacyTechnicianInvite>[] = [
    { title: 'Email address', field: 'email', defaultSort: 'asc' },
    {
      title: 'Expires',
      field: 'expires_at',
      type: 'datetime',
    },
  ];

  const handleCancelModal = () => {
    setShowCreateInviteModal(false);
  };

  const removeInvite = (activeInvite: PharmacyInviteDeletePayload) => {
    dispatch(deletePharmacyInvite(Number(pharmacy_id), activeInvite));
  };

  const createResendInvite = (activeInvite: PharmacyTechnicianInvite) => {
    dispatch(createPharmacyInvite(Number(pharmacy_id), activeInvite));
    setShowCreateInviteModal(false);
  };

  const handleAddNewTechnicianClick = () => {
    setShowCreateInviteModal(true);
  };

  if (
    isNil(pharmacies) ||
    isNil(pharmacy) ||
    isNil(pharmacy.techs) ||
    isNil(pharmacy.invites)
  ) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Pharmacies - {pharmacy.name}</title>
      </Helmet>
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        style={{ marginBottom: 20 }}
      >
        <DataRowItem title="Name" data={pharmacy.name} titleSize={2} />
        <DataRowItem
          title="Created at"
          data={pharmacy.created_at}
          titleSize={2}
        />
      </Grid>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Pharmacy Support</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={tech_columns}
          data={pharmacy.techs.sort(sortByFullName)}
          options={{
            idSynonym: 'name',
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            ...(TableOptions as Partial<Options<PharmacyTechnician>>),
          }}
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Pending invites</Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddNewTechnicianClick}
            >
              Add pharmacy support
            </Button>
          </div>
        </HeaderWrapper>
        <MaterialTable
          columns={invite_columns.map((c) => ({
            ...c,
            tableData: undefined,
          }))}
          data={pharmacy.invites}
          options={{
            idSynonym: 'email',
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
            ...(TableOptions as Partial<Options<PharmacyTechnicianInvite>>),
          }}
          actions={[
            {
              icon: function ResendIcon() {
                return <FontAwesomeIcon size="sm" icon={faRedo} />;
              },
              tooltip: 'Resend invite',
              onClick: (_event, rowData) =>
                setConfirmModalProps({
                  primaryActionText: 'Resend',
                  //@ts-ignore
                  // onClick passes rowData as PharmacyTechnicianInvite | PharmacyTechnicianInvite[] but for our use will
                  // only ever be a single PharmacyTechnicianInvite
                  description: `Are you sure you want to resend invite to ${rowData.email}?`,
                  onSuccess: () => {
                    //@ts-ignore
                    createResendInvite(rowData);
                    setConfirmModalProps(null);
                  },
                }),
            },
            {
              icon: function DeleteIcon() {
                return <FontAwesomeIcon size="sm" icon={faTrashAlt} />;
              },
              tooltip: 'Delete invite',
              onClick: (_event, rowData) =>
                setConfirmModalProps({
                  primaryActionText: 'Delete',
                  description: `Are you sure you want to remove invite to ${
                    (rowData as PharmacyTechnicianInvite).email
                  }?`,
                  onSuccess: () => {
                    removeInvite(rowData as PharmacyTechnicianInvite);
                    setConfirmModalProps(null);
                  },
                }),
            },
          ]}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      </div>
      {confirmModalProps ? (
        <Modal
          onClose={() => setConfirmModalProps(null)}
          actions={{
            primaryAction: {
              onClick: confirmModalProps.onSuccess,
              text: confirmModalProps.primaryActionText,
            },
          }}
        >
          <div style={{ marginBottom: 10 }}>
            {confirmModalProps.description}
          </div>
        </Modal>
      ) : null}

      {showCreateInviteModal ? (
        <CreateInviteModal
          text={`Invite a new user to join ${pharmacy.name}`}
          onClose={handleCancelModal}
          onContinue={createResendInvite}
          userRole={{ pharmacy_role_id: PHARMACY_ROLE.TECHNICIAN }}
        />
      ) : null}
    </Container>
  );
}

export default PharmacyDetail;
