import React, { useState } from 'react';
import Modal from '../../Shared/Modal';
import { TextField, List, ListItem, ListItemText } from '@mui/material';
import { FacilityDevice } from '../../../store/facility/facilityTypes';

interface ConfirmUnlinkDevicesModalProps {
  devices: FacilityDevice[];
  confirmUnlink(): void;
  onClose(): void;
}

function ConfirmUnlinkDevicesModal(props: ConfirmUnlinkDevicesModalProps) {
  const { devices, confirmUnlink, onClose } = props;

  const [typedText, setTypedText] = useState('');
  const confirmText = 'remove';

  return (
    <Modal
      onClose={onClose}
      showCloseX
      title="Remove Devices"
      actions={{
        primaryAction: {
          onClick: confirmUnlink,
          disabled: typedText.toLowerCase() !== confirmText,
          text: 'Remove',
        },
      }}
    >
      <div style={{ marginBottom: '24px' }}>
        Please type &quot;{confirmText}&quot; to remove the following devices
        from the facility.{' '}
        <b>
          If a device has been previously synced without an owner assignment,
          the synced status will be resetted upon performing this action.
        </b>{' '}
        You cannot undo this action.
      </div>
      <List>
        {devices.map((device) => (
          <ListItem key={device.id}>
            <ListItemText secondary={device.serial_number} />
          </ListItem>
        ))}
      </List>
      <TextField
        value={typedText}
        variant="outlined"
        placeholder={`Type ${confirmText}...`}
        onChange={(e) => setTypedText(e.target.value)}
        fullWidth
      />
    </Modal>
  );
}

export default ConfirmUnlinkDevicesModal;
