import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceLog } from '../../../store/devices/actionCreators';
import Modal from '../../Shared/Modal';
import { RootState } from '../../../store';
import { DeviceLog } from '../../../store/devices/deviceTypes';
import { TableOptions } from '../../../shared/tableUtils';

const SUCCESSFUL_MESSAGE_REGEX = /DISPENSING PACKET SUCCEEDED:.*/gim;
const FAILED_DISPENSE_MESSAGE = /DISPENSING PACKET FAILED:.*/gim;
const STEPS_REGEX = /\d* steps/gim;
const SECONDS_REGEX = /\d*\.*\d* seconds/gim;

interface DispenserStatisticsTableProps {
  logId: number | null;
  onClose(): void;
}

function DispenserStatisticsTable(props: DispenserStatisticsTableProps) {
  const { logId, onClose } = props;

  const { currentLog } = useSelector<
    RootState,
    { currentLog: DeviceLog[] | null }
  >((state) => {
    const { devices } = state;
    const { currentLog } = devices;
    return {
      currentLog,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!logId) {
      return;
    }
    if (!currentLog) {
      dispatch(getDeviceLog(logId));
    }
  }, [currentLog, logId, dispatch]);

  if (!currentLog) {
    return null;
  }

  const columns: Column<Object>[] = [
    { title: 'Activity', field: 'activity' },
    { title: 'Average', field: 'average' },
    { title: 'Min', field: 'min' },
    { title: 'Max', field: 'max' },
  ];

  interface MetricObject {
    activity: string;
    occurance: number[];
    type?: string;
  }

  interface Metrics {
    [key: string]: MetricObject;
  }

  const metrics: Metrics = {
    time_to_dispense: {
      activity: 'Time to dispense',
      occurance: [],
    },
    steps_to_position_seam: {
      activity: 'Steps to position seam',
      occurance: [],
    },
    successful_dispenses: {
      activity: 'Count of successful dispenses',
      occurance: [],
      type: 'count',
    },
    failed_dispenses: {
      activity: 'Count of failed dispenses',
      occurance: [],
      type: 'count',
    },
  };

  const parseSuccessfulDispense = (message: string) => {
    if (!message) {
      return;
    }
    const successfulMessage = message.match(SUCCESSFUL_MESSAGE_REGEX);
    if (!successfulMessage) {
      return;
    } else {
      let steps;
      let seconds;
      if (successfulMessage[0]) {
        steps = successfulMessage[0].match(STEPS_REGEX);
        seconds = successfulMessage[0].match(SECONDS_REGEX);
        if (steps) {
          steps = steps[0].match(/\d/gm);
        }
        if (seconds) {
          seconds = seconds[0].match(/\d/gm);
        }
        if (steps) {
          steps = steps[0];
        }
        if (seconds) {
          seconds = seconds[0];
        }
      }

      return {
        steps,
        seconds,
      };
    }
  };

  const parseSeamPosition = (message: string) => {
    // TODO Parse seam position data once implemented in back end
    if (message) {
      let steps = 'N/A';
      return {
        steps,
      };
    }
  };

  const parseFailedDispensing = (message: string) => {
    if (!message) {
      return;
    }
    const failedDispenseMessage = message.match(FAILED_DISPENSE_MESSAGE);
    if (failedDispenseMessage) {
      return true;
    }
  };

  currentLog.forEach((logItem) => {
    const successfulDispenseData = parseSuccessfulDispense(logItem.MESSAGE);
    if (successfulDispenseData) {
      metrics.time_to_dispense.occurance.push(
        Number(successfulDispenseData.seconds)
      );
      metrics.successful_dispenses.occurance.push(1);
    }

    const seamPositionData = parseSeamPosition(logItem.MESSAGE);
    if (seamPositionData) {
      metrics.time_to_dispense.occurance.push(Number(seamPositionData.steps));
    }

    const failedDispenseData = parseFailedDispensing(logItem.MESSAGE);
    if (failedDispenseData) {
      metrics.failed_dispenses.occurance.push(1);
    }
  });

  const rows = Object.keys(metrics).map((metric, index) => {
    const currentMetric = metrics[metric];
    if (currentMetric.type === 'count') {
      return {
        id: index,
        activity: metrics[metric].activity,
        min: 'N/A',
        max: 'N/A',
        average: currentMetric.occurance.length,
      };
    }
    return {
      id: index,
      activity: metrics[metric].activity,
      min: currentMetric.occurance.sort()[0] || 'N/A',
      max: currentMetric.occurance.sort().reverse()[0] || 'N/A',
      average:
        Number(
          (
            currentMetric.occurance.reduce(
              (acl: number, curr: number) => acl + curr,
              0
            ) / currentMetric.occurance.length
          ).toFixed(2)
        ) || 'N/A',
    };
  });

  return (
    <Modal title="Statistics" showCloseX onClose={onClose}>
      <MaterialTable
        columns={columns}
        data={rows}
        options={{
          paging: false,
          toolbar: false,
          ...(TableOptions as Partial<Options<Object>>),
        }}
      />
    </Modal>
  );
}

export default DispenserStatisticsTable;
