/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMedEventList } from '../models/GetMedEventList';
import type { GetQuickUpdate } from '../models/GetQuickUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create non-owner temporary pins ACESS -> Facility Caregiver, Careteam
   * @param nonOwnerId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public createNonOwnerPin(
    nonOwnerId?: number,
    requestBody?: {
      /**
       * Device id of owner
       */
      device_id?: number;
    }
  ): CancelablePromise<{
    pin?: string;
    expires_at?: string;
    device_id?: number;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{non_owner_id}/temp_device_pins',
      path: {
        non_owner_id: nonOwnerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `UnAuthorised`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * get non-owner temporary pins ACESS -> ENG, CC,Careteam
   * @param nonOwnerId
   * @param deviceId
   * @returns any Success
   * @throws ApiError
   */
  public getNonOwnerPin(
    nonOwnerId?: number,
    deviceId?: number
  ): CancelablePromise<{
    pin?: string;
    expires_at?: string;
    device_id?: number;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customers/{non_owner_id}/temp_device_pins/{device_id}',
      path: {
        non_owner_id: nonOwnerId,
        device_id: deviceId,
      },
      errors: {
        400: `Bad request`,
        401: `UnAuthorised`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get Customers
   * @param facilityId
   * @returns any Success
   * @throws ApiError
   */
  public getCustomers(facilityId?: number): CancelablePromise<
    Array<{
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      gender_id?: number;
      date_of_birth?: string;
      is_patient?: boolean;
      previlege_id?: number;
      med_window_time_sec?: number;
      last_login_at?: string;
      email_verified_at?: string;
      created_at?: string;
      modified_at?: string;
      facility_id?: number;
      facility_role_id?: number;
      device_time_zone?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customers',
      query: {
        facility_id: facilityId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Deletes Omcare Caregivers. (do not support deleting owner or facility associated user currently)
   * @param customerId customer id of user you want to delete
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public deleteUser(
    customerId: number,
    requestBody?: {
      'deletion_reason?'?: string;
      'additional_details?'?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/customers/{customer_id}',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get quick updates by Customer id
   * @param customerId customer id of customer for whom we are fetching updates
   * @returns GetQuickUpdate Success
   * @throws ApiError
   */
  public getQuickUpdates(
    customerId: number
  ): CancelablePromise<GetQuickUpdate> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customers/{customer_id}/quick_updates',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Save onboarding progress
   * @param customerId id of logged in user
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addOnboardingProgress(
    customerId: number,
    requestBody?: {
      device_id?: number;
      owner_id?: number;
      last_step_completed?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{customer_id}/onboarding_progress',
      query: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        409: `Duplicate entry`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get details on incomplete onboarding
   * @param customerId id of logged in user
   * @returns any Success
   * @throws ApiError
   */
  public getOnboardingProgress(customerId: number): CancelablePromise<
    Array<{
      owner?: {
        id?: number;
        first_name?: string;
        last_name?: string;
      };
      device?: {
        id?: number;
        serial_number?: string;
      };
      last_step_completed?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customers/{customer_id}/onboarding_progress',
      query: {
        customer_id: customerId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * link invitee to Owner
   * @param customerId id of invitee who is accepting invite
   * @param ownerCustomerId id of owner who sent the invite
   * @param accepted true if invite is accepted and false if invite is declined
   * @returns any Success
   * @throws ApiError
   */
  public acceptInvite(
    customerId: number,
    ownerCustomerId: number,
    accepted: boolean
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/customers/{customer_id}/careteams/{owner_id}',
      path: {
        customer_id: customerId,
        owner_customer_id: ownerCustomerId,
      },
      query: {
        accepted: accepted,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Active invite status not found`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Delete onboarding progress
   * @param customerId The id of logged in user
   * @param deviceId The id of device on which onboarding is in progress
   * @returns any Success
   * @throws ApiError
   */
  public deleteOnboardingProgress(
    customerId: number,
    deviceId: number
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/customers/{customer_id}/onboarding_progress/{device_id}',
      query: {
        customer_id: customerId,
        device_id: deviceId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns med schedule by caregiver id
   * @param customerId customer id of caregiver
   * @param startDate Start date for medication schedule
   * @param endDate End date for medication schedule
   * @param ownerId Customer id of owner. This query param allows you to filter by owner id
   * @param caregiverAssist returns customer's care giving  schedule for owners they are assigned.
   * @param includeInvalidated Flag to determine if to include doses with invalidated statuses
   * @returns GetMedEventList Success
   * @throws ApiError
   */
  public getMedScheduleByCaregiverId(
    customerId: number,
    startDate: any,
    endDate: any,
    ownerId?: any,
    caregiverAssist?: boolean,
    includeInvalidated?: boolean
  ): CancelablePromise<GetMedEventList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customers/{customer_id}/medication_events',
      path: {
        customer_id: customerId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        owner_id: ownerId,
        caregiver_assist: caregiverAssist,
        include_invalidated: includeInvalidated,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update customer's acceptance of terms of use
   * @param customerId customer id accepting the terms of use
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateTermsOfUse(
    customerId: number,
    requestBody?: {
      /**
       * method used for accepting terms of use
       */
      acceptance_method?: 'HUB' | 'MOBILE';
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{customer_id}/terms_of_use',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Decouple customer id from FCM token
   * @param customerId customer id to decouple
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public decoupleFcmTokenFromUser(
    customerId: number,
    requestBody?: {
      fcm_token?: string;
    }
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{customer_id}/decouple/fcm_token',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update customer's survey
   * @param customerId customer id filling survey
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateCustomerSurvey(
    customerId: number,
    requestBody?: {
      /**
       * platform used by user to fill survey
       */
      source?: 'HUB' | 'MOBILE';
      /**
       * four score given 1 being lowest and 4 being highest
       */
      score?: 4 | 3 | 2 | 1;
      /**
       * customer response on whether they are ok to get a call.(optional)(lowercase)
       */
      'can_reach_out?'?: boolean;
      /**
       * describes the mode hub is at
       */
      'hub_mode?'?: 'demo' | 'normal';
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{customer_id}/satisfaction_survey',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update webrtc stats
   * @param callerCustomerId customer id of caller
   * @param recipientCustomerId customer id of recipient
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public recordCallStat(
    callerCustomerId: number,
    recipientCustomerId: number,
    requestBody?: {
      stat?: {
        HubInboundStream?: any;
        HubOutBoundStream?: any;
        'call-duration'?: number;
      };
      /**
       * describes the mode hub is at
       */
      'hub_mode?'?: 'demo' | 'normal';
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customers/{caller_customer_id}/webrtc_stats/{recipient_customer_id}',
      path: {
        caller_customer_id: callerCustomerId,
        recipient_customer_id: recipientCustomerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
