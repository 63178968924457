/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetMedDose = {
  id: number;
  national_drug_code: string;
  drug_name: string;
  dosage?: string;
  roll_packaged?: boolean;
  status_id?: number;
  status: string;
  last_received_event?: GetMedDose.last_received_event;
  roll_id?: string;
  batch_id?: string;
  pouch_number?: number;
  consumption_notes?: string;
};

export namespace GetMedDose {
  export enum last_received_event {
    PRESCRIBED = 'prescribed',
    LOADED = 'loaded',
    DISPENSED_FAILED = 'dispensed_failed',
    BAG_REMOVED = 'bag_removed',
    ADHERENT_SELF_REPORTED = 'adherent_self_reported',
    NON_ADHERENT_SELF_REPORTED = 'non_adherent_self_reported',
  }
}
