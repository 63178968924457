import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import isNull from 'lodash/isNull';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/omcareBigLogo.png';
import { RootState } from '../../store';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import { getAdherenceData } from '../../store/reporting/actionCreators';
import AdherenceGraph from '../Reporting/components/AdherenceReport/AdherenceGraph';
import { Timespans } from '../Reporting/components/AdherenceReport/FilterData';
import LoadingSpinner from '../Shared/LoadingSpinner';
import StyledSelect from '../Shared/StyledSelect';
import LeastAdherentTable from './components/LeastAdherentTable';
import MostRecentMissedTable from './components/MostRecentMissedTable';
import UpcomingMedEventsTable from './components/UpcomingMedEventsTable';
import SkippedMedCount from './components/SkippedMedCount';
import { useHistory } from 'react-router-dom';
import { getFacilityCustomers } from '../../store/customers/actionCreators';
import OnlineOwnerCount from './components/OnlineOwnerCount';
import MissedMedEvventCount from './components/MissedMedEventCount';

const stateSelector = (state: RootState) => {
  const { dashboardUser, reporting, customers, webSocket } = state;
  const { dashboardUserInfo } = dashboardUser;
  const { adherenceData } = reporting;
  const { facilityCustomers } = customers;
  const { connected } = webSocket;
  return {
    dashboardUserInfo,
    adherenceData,
    facilityCustomers,
    connected,
  };
};

let inDevEnv = process.env.NODE_ENV === 'development';

function Dashboard() {
  const { dashboardUserInfo, adherenceData, facilityCustomers, connected } =
    useSelector(stateSelector);

  const dispatch = useDispatch();

  const history = useHistory();

  const startYesterday = moment.utc().subtract(1, 'days').set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const endYesterday = moment.utc().subtract(1, 'days').set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });

  const [dateRange, setDateRange] = useState({
    fromDate: startYesterday,
    toDate: endYesterday,

    value: 'yesterday',
  });

  const onSetDateRange = (event: SelectChangeEvent<string>) => {
    switch (event.target.value) {
      case 'yesterday': {
        return setDateRange({
          fromDate: startYesterday,
          toDate: endYesterday,

          value: 'yesterday',
        });
      }
      case 'last7Days': {
        const last7Days = moment(startYesterday).subtract(6, 'days');
        return setDateRange({
          fromDate: last7Days,
          toDate: endYesterday,

          value: 'last7Days',
        });
      }
      case 'last14Days': {
        const last14Days = moment(startYesterday).subtract(13, 'days');
        return setDateRange({
          fromDate: last14Days,
          toDate: endYesterday,

          value: 'last14Days',
        });
      }
      case 'last30Days': {
        const last30Days = moment(startYesterday).subtract(29, 'days');
        return setDateRange({
          fromDate: last30Days,
          toDate: endYesterday,

          value: 'last30Days',
        });
      }
    }
  };

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  useEffect(() => {
    if (
      dashboardUserInfo &&
      dashboardUserInfo.facility_name &&
      dashboardUserInfo.facility_id
    ) {
      dispatch(getFacilityCustomers(dashboardUserInfo.facility_id));
      dispatch(
        getAdherenceData([
          {
            filterValue: 'groupBy',
            filterDisplay: 'Sort by',
            selectedValues: [{ display: 'Medication', value: 'pill' }],
          },
          {
            filterValue: 'datePicker',
            filterDisplay: 'Date Range',
            selectedValues: [
              {
                display: dateRange.value,
                value: {
                  fromDate: dateRange.fromDate,
                  toDate: dateRange.toDate,
                },
              },
            ],
          },
          {
            filterValue: 'facilityId',
            filterDisplay: 'Facility',
            selectedValues: [
              {
                display: dashboardUserInfo.facility_name,
                value: dashboardUserInfo.facility_name,
              },
            ],
          },
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUserInfo, dateRange.fromDate, connected]);

  if (
    isNull(dashboardUserInfo) ||
    (dashboardUserInfo.facility_name &&
      (isNull(adherenceData) || isNull(facilityCustomers)))
  ) {
    return <LoadingSpinner />;
  } else if (isNull(dashboardUserInfo.facility_name)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Helmet>
          <title>Ōmcare - Dashboard</title>
        </Helmet>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>
    );
  }

  let customers = facilityCustomers
    ? facilityCustomers.filter((customer) => {
        return customer.privilege_id !== 2 && customer.privilege_id !== 3;
      })
    : [];
  let onlineCustomers = customers.filter((customer) => {
    return customer.is_online;
  });

  return (
    <div style={{ textAlign: 'center' }}>
      <Helmet>
        <title>Ōmcare - Dashboard</title>
      </Helmet>
      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <FormControl variant="outlined" sx={{ minWidth: '200px' }}>
          <InputLabel>Date range</InputLabel>
          <StyledSelect
            name="dateRange"
            value={dateRange.value}
            onChange={onSetDateRange}
            sx={{ marginBottom: '25px', width: '300px', textAlign: 'center' }}
            label="Date range*"
          >
            {Timespans.slice(0, -1).map((item) => (
              <MenuItem key={item.value as string} value={item.value as string}>
                {item.display}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          container
          item
          spacing={2}
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Grid item xs={4}>
            <Card
              sx={{ minWidth: 275, background: '#F2F2F7', cursor: 'pointer' }}
              onClick={() => history.push('/customers')}
            >
              <CardContent style={{ width: '600px', height: '600px' }}>
                <OnlineOwnerCount
                  onlineCustomers={onlineCustomers.length}
                  customers={customers.length}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{ minWidth: 275, background: '#F2F2F7', cursor: 'pointer' }}
              onClick={() => history.push('/reporting')}
            >
              <CardContent style={{ width: '600px', height: '600px' }}>
                <AdherenceGraph adherenceData={adherenceData} />
              </CardContent>
            </Card>
          </Grid>
          {inDevEnv && (
            <Grid item xs={4}>
              <Card
                sx={{ minWidth: 275, background: '#F2F2F7', cursor: 'pointer' }}
                // TODO Decide where to go from this card
                onClick={() => console.log('Go to med schedule page')}
              >
                <CardContent style={{ width: '600px', height: '600px' }}>
                  <MissedMedEvventCount />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        {inDevEnv && (
          <Grid
            container
            item
            spacing={2}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275, background: '#F2F2F7', cursor: 'pointer' }}
              >
                <CardContent style={{ width: '600px', height: '600px' }}>
                  <LeastAdherentTable />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275, background: '#F2F2F7', cursor: 'pointer' }}
              >
                <CardContent style={{ width: '600px', height: '600px' }}>
                  <UpcomingMedEventsTable />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {inDevEnv && (
          <Grid
            container
            item
            spacing={2}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={6}>
              <Card sx={{ minWidth: 275, background: '#F2F2F7' }}>
                <CardContent style={{ width: '600px', height: '600px' }}>
                  <MostRecentMissedTable />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minWidth: 275, background: '#F2F2F7' }}>
                <CardContent style={{ width: '600px', height: '600px' }}>
                  <SkippedMedCount />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Dashboard;
