import { Button, Container, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import isNull from 'lodash/isNull';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { doesVersionExist } from '../../shared/utils';
import { RootState } from '../../store';
import {
  createSoftwareVersion,
  getSoftwareVersions,
} from '../../store/software/actionCreators';
import { SoftwareVersionRequest } from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import SelectFormField from '../Shared/SelectFormField';
import { TextInputFormField } from '../Shared/TextInputFormField';

function CreateNewSoftwareVersion() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { softwareVersions } = useSelector((state: RootState) => {
    const { software } = state;
    const { softwareVersions } = software;
    return {
      softwareVersions,
    };
  });

  useEffect(() => {
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }
  }, [softwareVersions, dispatch]);

  const schema = yup.object({
    version_major: yup
      .number()
      .required('This field is required')
      .test('match', 'This software version already exists', function (value) {
        const data = this.parent;
        return !doesVersionExist(
          softwareVersions,
          value,
          data.version_minor,
          data.version_prerelease
        );
      }),
    version_minor: yup
      .number()
      .required('This field is required')
      .test('match', 'This software version already exists', function (value) {
        const data = this.parent;
        return !doesVersionExist(
          softwareVersions,
          data.version_major,
          value,
          data.version_prerelease
        );
      }),
    version_prerelease: yup
      .number()
      .required('This field is required')
      .test('match', 'This software version already exists', function (value) {
        const data = this.parent;
        return !doesVersionExist(
          softwareVersions,
          data.version_major,
          data.version_minor,
          value
        );
      }),
    full_url: yup.string().required('This field is required'),
  });

  const software_versions_list = useMemo(() => {
    if (!softwareVersions) return [];
    return softwareVersions.map((version) => {
      let cur_version = version.version_major + '.' + version.version_minor;
      if (version.version_prerelease !== 0) {
        cur_version += 'pre' + version.version_prerelease;
      }
      return { label: cur_version, value: version.id };
    });
  }, [softwareVersions]);

  const onSubmit = async (data: SoftwareVersionRequest) => {
    await dispatch(createSoftwareVersion(data));
    history.push('/software');
  };

  const handleCancel = () => {
    history.push('/software');
  };

  if (isNull(softwareVersions)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Software Version - Create</title>
      </Helmet>
      <Typography variant={'h1'}>Create a software version</Typography>

      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          version_major: '' as never,
          version_minor: '' as never,
          version_prerelease: '' as never,
          documentation_url: '',
          base_version_id: '' as never,
          full_url: '',
          patch_url: '',
          software_product_id: 1,
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={4}>
                <Field
                  label="Major version"
                  name="version_major"
                  placeholder="Major version"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="Minor version"
                  name="version_minor"
                  placeholder="Minor version"
                  component={TextInputFormField}
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  label="Pre release version"
                  name="version_prerelease"
                  placeholder="Pre release version"
                  component={TextInputFormField}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  label="Base version"
                  name="base_version_id"
                  placeholder="Base version"
                  component={SelectFormField}
                  options={software_versions_list}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  label="Full URL"
                  name="full_url"
                  placeholder="Full URL"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Patch URL"
                  name="patch_url"
                  placeholder="Patch URL"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Documentation URL"
                  name="documentation_url"
                  placeholder="Documentation URL"
                  component={TextInputFormField}
                />
              </Grid>
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default CreateNewSoftwareVersion;
