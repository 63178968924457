/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventNewMedSchedule = {
  owner_id?: number;
  target?: EventNewMedSchedule.target;
};

export namespace EventNewMedSchedule {
  export enum target {
    HUB = 'hub',
    CAREGIVER = 'caregiver',
    ALL = 'all',
  }
}
