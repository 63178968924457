import * as Sentry from '@sentry/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { restHost } from '../../apiConfig';
import { deleteToken, formatUrl, getCookie } from '../../shared/utils';
import {
  DashboardUser,
  DashboardUserProfile,
  DashboardUserStore,
  DashboardUserStoreActionTypes,
} from './dashboardUserTypes';

export const apiError = (error: any) => ({
  type: DashboardUserStoreActionTypes.DASHBOARD_USER_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: DashboardUserStoreActionTypes.DASHBOARD_USER_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const populateDashboardUser = (dashboardUserInfo: DashboardUser) => ({
  type: DashboardUserStoreActionTypes.POPULATE_DASHBOARD_USER,
  payload: { dashboardUserInfo },
});

export const getDashboardUser = () => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'customer'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        Sentry.setUser({ id: res.data.customer_id });
        dispatch(populateDashboardUser(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error(err.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateDashboardUserProfile =
  (profile: DashboardUserProfile) =>
  async (
    dispatch: ThunkDispatch<DashboardUserStore, void, AnyAction> & Dispatch
  ) => {
    try {
      await axios.put(formatUrl(restHost, 'customer'), profile, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      });
      dispatch(getDashboardUser());
      toast.success('Personal info updated');
    } catch (error: any) {
      if (error.response.status === 401) {
        deleteToken();
      }
      dispatch(apiError(error));
      dispatch(setLoadState(false));
      let message;
      if (error.response.status === 400) {
        message = 'Incorrect request, please try again';
      }
      if (error.response.status === 500 || error.response.status === 503) {
        message = 'System down, please try again';
      }
      toast.error(message);
    }
  };
