/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceShadowOptions = {
  application_log_level?: DeviceShadowOptions.application_log_level;
  dispenser_blade_bottom_value?: number;
  dispenser_enable_nick?: boolean;
  webrtc_audio_echo_cancellation?: boolean;
  webrtc_audio_noise_suppression?: boolean;
  webrtc_audio_use_fec?: boolean;
  webrtc_disable_decoder_during_dispense?: boolean;
  webrtc_disable_encoder_during_dispense?: boolean;
  med_event_window_sec?: number;
  dispenser_debug_bounding_box_enable?: DeviceShadowOptions.dispenser_debug_bounding_box_enable;
  reset_medication_schedule?: boolean;
  enable_system_test_mode?: boolean;
  seam_position_confidence?: number;
  webrtc_stats_collection?: boolean;
  sentry_sample_rate?: number;
  is_sentry_control_group?: boolean;
};

export namespace DeviceShadowOptions {
  export enum application_log_level {
    DEBUG = 'debug',
    INFO = 'info',
    WARNING = 'warning',
    CRITICAL = 'critical',
    FATAL = 'fatal',
  }

  export enum dispenser_debug_bounding_box_enable {
    '_0' = 0,
    '_1' = 1,
  }
}
