/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of med dose status
 */
export enum MedicationDoseStatusType {
  PRESCRIBED = 'prescribed',
  LOADED = 'loaded',
  DISPENSED = 'dispensed',
  BAG_REMOVED = 'bag_removed',
  SKIPPED = 'skipped',
  ADMINISTERED = 'administered',
  INVALIDATED = 'invalidated',
  MISSED = 'missed',
  PRE_DISPENSED = 'pre-dispensed',
}
