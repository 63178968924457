/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MedEventType } from './MedEventType';

export type ImportMedicationRoll = {
  batch_id: number;
  start_date: string;
  pharmacy_id: number;
  schedule_template: Array<{
    prescribed_time_of_day_24: string;
    /**
     * Defaults to prescribed time of day if not set
     */
    desired_time_of_day_24: string;
    event_type?: MedEventType;
    pouches: Array<{
      pouch_number: number;
      drugs: Array<{
        national_drug_code: string;
        roll_packaged: boolean;
        is_prn?: boolean;
      }>;
    }>;
  }>;
  schedule_template_cadence?: ImportMedicationRoll.schedule_template_cadence;
  schedule_template_duration?: number;
};

export namespace ImportMedicationRoll {
  export enum schedule_template_cadence {
    REPEAT_DAYS = 'REPEAT_DAYS',
  }
}
