/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetMedDoseList } from './GetMedDoseList';
import type { MedEventStatusEnum } from './MedEventStatusEnum';
import type { MedEventType } from './MedEventType';

export type LoadScheduleMatchItem = {
  /**
   * treat this as an arbitray string and do not store locally for later reuse, the response value is subject to change in the future
   */
  med_event_id?: string;
  /**
   * When the pharmacy prescribed the medication to be taken - immutable
   */
  prescribed_datetime?: string;
  /**
   * The time the owner has configured omcare to take their medication, within an acceptable range of the prescribed_datetime
   */
  desired_datetime?: string;
  /**
   * The 24 hour clock time of day the owner is allowed to take their medication, in the owners local timezone
   */
  prescribed_time_of_day_24?: string;
  /**
   * The 24 hour clock time of day the owner has configured omcare to take their medication, in the owners local timezone
   */
  desired_time_of_day_24?: string;
  /**
   * The catergorization of time based on morning, afternoon and evening
   */
  part_of_day?: LoadScheduleMatchItem.part_of_day;
  /**
   * Name of the assigned caregiver for given owner.
   */
  caregiver_name?: string;
  /**
   * Customer id of assigned caregiver for given owner.
   */
  caregiver_customer_id?: number;
  /**
   * full name of owner
   */
  owner_name?: string;
  /**
   * customer id of owner
   */
  owner_id?: number;
  event_type?: MedEventType;
  event_type_id?: number;
  status?: MedEventStatusEnum;
  doses?: GetMedDoseList;
};

export namespace LoadScheduleMatchItem {
  /**
   * The catergorization of time based on morning, afternoon and evening
   */
  export enum part_of_day {
    MORNING = 'Morning',
    AFTERNOON = 'Afternoon',
    EVENING = 'Evening',
  }
}
