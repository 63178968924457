import moment from 'moment';
import { Reducer } from 'redux';
import { DATE_TIME_FORMAT } from '../../shared/constants';
import {
  SoftwareAction,
  SoftwareActionType,
  SoftwareStore,
  SoftwareVersion,
} from './softwareTypes';

import semverParse from 'semver/functions/parse';

const initialState: SoftwareStore = {
  softwareVersions: null,
  baseVersionOptions: [],
  deployments: null,
  deployment_loaded: false,
  error: null,
  isLoading: false,
  goToSoftwareVersionsPage: false,
};

const softwareReducer: Reducer<SoftwareStore, SoftwareAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SoftwareActionType.SET_SOFTWARE_VERSIONS: {
      const { software } = action.payload;
      var baseVersionOptions: SoftwareVersion[] = [];
      software.forEach((version) => {
        let createdAt = moment(version.created_at);
        version.created_at = createdAt.isValid()
          ? createdAt.format(DATE_TIME_FORMAT)
          : '-';
        let modifiedAt = moment(version.modified_at);
        version.modified_at = modifiedAt.isValid()
          ? modifiedAt.format(DATE_TIME_FORMAT)
          : '-';
        if (version.base_version_id === version.id) {
          baseVersionOptions.push(version);
        }

        if (version.version_raw) {
          version.semver = semverParse(version.version_raw);

          if (version.semver?.build.length) {
            version.commit_url = new URL(
              `https://github.com/AnserInnovation/meta-omcare/commit/${version.semver.build[0]}`
            );
          }
        }
      });

      let newState = {
        ...state,
        softwareVersions: software,
        baseVersionOptions: baseVersionOptions,
      };
      return newState;
    }
    case SoftwareActionType.SET_SOFTWARE_DEPLOYMENT: {
      const { deployments } = action.payload;
      deployments.forEach((deployment) => {
        let createdAt = moment(deployment.created_at);
        deployment.created_at = createdAt.isValid()
          ? createdAt.format(DATE_TIME_FORMAT)
          : '-';
      });
      let newState = {
        ...state,
        deployments: deployments,
      };
      return newState;
    }
    case SoftwareActionType.SOFTWARE_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', action.payload);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case SoftwareActionType.SOFTWARE_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    case SoftwareActionType.SOFTWARE_CREATED_EDITED: {
      let newState = {
        ...state,
        goToSoftwareVersionsPage: true,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default softwareReducer;
