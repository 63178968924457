/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportingService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Adherence Data
   * @param requestBody
   * @returns any Successfully get adherence data report
   * @throws ApiError
   */
  public getAdherence(requestBody?: {
    /**
     * pill for by pill or medAssist for by event
     */
    group_by?: string;
    /**
     * string 2020-05-01 (up till Yesterday)
     */
    to_date?: string;
    /**
     * string 2020-05-01
     */
    from_date?: string;
    start_age?: string;
    end_age?: string;
    /**
     * 1 for male 2 for female and 3 for undisclosed
     */
    gender?: Array<string>;
    state?: Array<string>;
    zip_code?: Array<string>;
    pharmacy_name?: Array<string>;
    facility_name?: Array<string>;
    medAdmin_to_time?: string;
    medAdmin_from_time?: string;
    owner_id?: number;
    event_type_id?: number;
  }): CancelablePromise<{
    numberAdherent?: number;
    total?: number;
    groupBy?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/reporting/adherence',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Download adherence Data
   * @param requestBody
   * @returns any Successfully downloaded csv file
   * @throws ApiError
   */
  public downloadCsvReport(requestBody?: {
    /**
     * pill for by pill or medAssist for by event
     */
    group_by?: string;
    /**
     * string 2020-05-01 (up till Yesterday)
     */
    to_date?: string;
    /**
     * string 2020-05-01
     */
    from_date?: string;
    start_age?: string;
    end_age?: string;
    /**
     * 1 for male 2 for female and 3 for undisclosed
     */
    gender?: Array<string>;
    state?: Array<string>;
    zip_code?: Array<string>;
    pharmacy_name?: Array<string>;
    facility_name?: Array<string>;
    medAdmin_to_time?: string;
    medAdmin_from_time?: string;
    owner_id?: number;
    event_type_id?: number;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/reporting/adherence/download',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
