/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get ones own customer information
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerInfo(): CancelablePromise<{
    customer_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    phone2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    gender_id?: number;
    date_of_birth?: string;
    age?: number;
    is_patient?: boolean;
    med_window_time_sec?: number;
    previlege_id?: number;
    email_verified_at?: string;
    facility_id?: number;
    facility_role_id?: number;
    pharmacy_name?: string;
    pharmacy_patient_id?: string;
    device_serial_number?: string;
    device_id?: string;
    device_software_version?: string;
    device_hardware_version?: string;
    device_time_zone?: string;
    device_active_fault_codes?: Array<string>;
    facility_name?: string;
    _global_permissions?: Array<'user.onboarding'>;
    synced_devices?: Array<{
      device_id?: number;
      assigned_customer_id?: number;
    }>;
    /**
     * If hub is "online" or "offline"
     */
    hub_connectivity_status?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/',
      errors: {
        400: `Customer not found`,
        401: `UNAUTHORIZED`,
        403: `Forbidden`,
        404: `NOT_FOUND`,
        500: `Operation Failed`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Update the current logged in customer profile
   * @param selfOnboarding Optional boolean value to determine self onboarding
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public updateCustomerInfo(
    selfOnboarding?: boolean,
    requestBody?: {
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      gender_id?: number;
      date_of_birth?: string;
      'device_id?'?: number;
    }
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/customer/',
      query: {
        self_onboarding: selfOnboarding,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Retrieve customers information
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerInfoById(customerId?: number): CancelablePromise<{
    customer_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    phone2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    gender_id?: number;
    date_of_birth?: string;
    age?: number;
    is_patient?: boolean;
    med_window_time_sec?: number;
    previlege_id?: number;
    email_verified_at?: string;
    facility_id?: number;
    facility_role_id?: number;
    pharmacy_name?: string;
    pharmacy_patient_id?: string;
    device_serial_number?: string;
    device_id?: string;
    device_software_version?: string;
    device_hardware_version?: string;
    device_time_zone?: string;
    device_active_fault_codes?: Array<string>;
    facility_name?: string;
    _global_permissions?: Array<'user.onboarding'>;
    synced_devices?: Array<{
      device_id?: number;
      assigned_customer_id?: number;
    }>;
    /**
     * If hub is "online" or "offline"
     */
    hub_connectivity_status?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Customer not found`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `Operation failed`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * get customer info by email
   * @param email email of customer
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerInfoEmail(email?: string): CancelablePromise<{
    customer_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    phone2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    gender_id?: number;
    date_of_birth?: string;
    age?: number;
    is_patient?: boolean;
    med_window_time_sec?: number;
    previlege_id?: number;
    email_verified_at?: string;
    facility_id?: number;
    facility_role_id?: number;
    pharmacy_name?: string;
    pharmacy_patient_id?: string;
    device_serial_number?: string;
    device_id?: string;
    device_software_version?: string;
    device_hardware_version?: string;
    device_time_zone?: string;
    device_active_fault_codes?: Array<string>;
    facility_name?: string;
    _global_permissions?: Array<'user.onboarding'>;
    synced_devices?: Array<{
      device_id?: number;
      assigned_customer_id?: number;
    }>;
    /**
     * If hub is "online" or "offline"
     */
    hub_connectivity_status?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/email/{email}',
      path: {
        email: email,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Link a customer to patient
   * @param patientCustomerId assignee customer's id
   * @param customerId customer id
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addCustomerToCareteam(
    patientCustomerId?: number,
    customerId?: number,
    requestBody?: {
      careteam_role_id?: number;
    }
  ): CancelablePromise<{
    message?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customer/{patient_customer_id}/link/{customer_id}',
      path: {
        patient_customer_id: patientCustomerId,
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get the pending careteam owner for the invite
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getPendingCareteamInvites(customerId?: number): CancelablePromise<
    Array<{
      first_name?: string;
      last_name?: string;
      email?: string;
      careteam_role_id?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}/careteam/invites',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Customer not found`,
        403: `Only facility admin / scheduler can list invite careteam members.`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Invite a care team members
   * @param customerId customer ID
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public inviteCareteamMember(
    customerId?: number,
    requestBody?: {
      first_name?: string;
      last_name?: string;
      email?: string;
      careteam_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customer/{customer_id}/careteam/invites',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Customer not found`,
        403: `Only facility admin / scheduler can invite careteam members"`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get a customer's careteam
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getCareteamByCustomer(customerId?: number): CancelablePromise<{
    careteam_id?: Array<{
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      is_patient?: boolean;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: number;
      country?: string;
      date_of_birth?: string;
      privilege_id?: number;
      patient_customer_id?: number;
      facility_id?: number;
      facility_role_id?: number;
      careteam_role_id?: number;
      device_id?: number;
      device_maintenance_required?: boolean;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}/careteam',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Deletes an invite
   * @param email email of invitee
   * @param customerId assignee customer's id
   * @returns void
   * @throws ApiError
   */
  public deleteCareteamInvite(
    email: any,
    customerId?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/customer/{customer_id}/careteam/invites/{email}',
      path: {
        customer_id: customerId,
        email: email,
      },
      errors: {
        400: `Customer not found`,
        403: `Only facility admin / scheduler can delete careteam invite`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * save customer"s notification tokens
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public saveNotificationToken(requestBody?: {
    fcm_token?: string;
    /**
     * number 1 is android and 2 is IOS
     */
    platform_id?: number;
    'pushkit_token?'?: string | null;
    'callkeep_enabled?'?: boolean;
  }): CancelablePromise<{
    message?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customer/notificationToken',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Retrieve a medication image(redirect from FDB)
   * @param drugId Drug ID
   * @returns any Success
   * @throws ApiError
   */
  public getMedicationImage(drugId?: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/medication/{drug_id}/image',
      path: {
        drug_id: drugId,
      },
      errors: {
        302: `Redirect`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of customers alerts
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getAlertsByCustomer(customerId?: number): CancelablePromise<
    Array<{
      id?: number;
      customer_id?: number;
      alert_code?: number;
      related_customer_id?: number;
      created_at?: string;
      title?: string;
      body?: string;
      related_first_name?: string;
      related_last_name?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}/alert',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Retrieve user notes
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerNote(customerId?: number): CancelablePromise<{
    id?: number;
    patient_customer_id?: number;
    title?: string;
    note?: string;
    modified_at?: string;
    modified_by?: number;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}/note',
      path: {
        customer_id: customerId,
      },
      errors: {
        401: `UnAuthorized`,
        403: `UnAuthorized to view note`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update a note for a user
   * @param customerId customer ID
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addCustomerNote(
    customerId?: number,
    requestBody?: {
      note?: string;
      title?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/customer/{customer_id}/note',
      path: {
        customer_id: customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UnAuthorized`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Retrieve owner pin (Only for Customer Care)
   * @param requestBody Customer Care Password
   * @param ownerId Owner ID
   * @returns any Success (pin is empty if it is not found)
   * @throws ApiError
   */
  public getOwnerPin(
    requestBody: {
      password?: string;
    },
    ownerId?: number
  ): CancelablePromise<{
    pin?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customer/{owner_id}/pin',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Malformed request`,
        401: `UnAuthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Retrieve Customer Flutter logs
   * @param customerId customer ID
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerFlutterLogs(customerId?: number): CancelablePromise<
    Array<{
      id?: number;
      created_by?: number;
      file_name?: string;
      uploaded_at?: string;
      deleted_at?: string;
      deleted_by?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/customer/{customer_id}/flutter_logs',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Invalid parameters`,
        401: `UnAuthorized`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
