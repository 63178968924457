import { Button, Container } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { RootState } from '../../store';
import { addFacility } from '../../store/facility/actionCreators';
import { TextInputFormField } from '../Shared/TextInputFormField';
import ConfirmNewFacilityModal from './components/ConfirmNewFacilityModal';
import { convertMinutesToSeconds } from '../../shared/utils';

const schema = yup.object({
  name: yup.string().trim().required('This field is required'),
});

function AddNewFacility() {
  const dispatch = useDispatch();
  const { goToFacilityPage } = useSelector<
    RootState,
    { goToFacilityPage: boolean }
  >((state) => {
    const { facility } = state;
    const { goToFacilityPage } = facility;
    return {
      goToFacilityPage,
    };
  });
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const formikRef =
    useRef<FormikProps<{ name: string; medWindowTime?: number }>>(null);

  if (goToFacilityPage) {
    return <Redirect to="/facilities" />;
  }

  const onSubmit = () => {
    setShowModal(true);
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Facilities - Add</title>
      </Helmet>
      <div>
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            name: '',
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                name="name"
                label="Name"
                placeholder="Name"
                component={TextInputFormField}
              />
              <div style={{ marginTop: '10px' }}>
                <Field
                  name="medWindowTime"
                  label="Med Window Time (Minutes)"
                  placeholder="30"
                  type="number"
                  component={TextInputFormField}
                />
              </div>

              <div style={{ margin: '20px 0' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: 10 }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    history.push('/facilities');
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showModal ? (
          <ConfirmNewFacilityModal
            onClose={() => setShowModal(false)}
            onContinue={({ name }) =>
              dispatch(
                addFacility({
                  name,
                  med_window_time_sec: formikRef.current?.values.medWindowTime
                    ? convertMinutesToSeconds(
                        formikRef.current.values.medWindowTime
                      )
                    : undefined,
                })
              )
            }
            facilityName={
              formikRef.current ? formikRef.current.values.name : ''
            }
          />
        ) : null}
      </div>
    </Container>
  );
}

export default AddNewFacility;
