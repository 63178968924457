import React from 'react';
import { FormControl, MenuItem } from '@mui/material';
import Modal from '../../../Shared/Modal';
import StyledSelect from '../../../Shared/StyledSelect';
import { Careteam } from '../../../../store/customers/customerTypes';

interface SetCaregiverCustomerIdProps {
  careTeam: Careteam[] | null;
  caregiverCustomerId: string;
  handleChangeCaregiverCustomerId(e: any): void;
  saveChanges(): void;
  onClose(): void;
}

function SetCaregiverCustomerId(props: SetCaregiverCustomerIdProps) {
  const {
    careTeam,
    caregiverCustomerId,
    handleChangeCaregiverCustomerId,
    saveChanges,
    onClose,
  } = props;

  const selectOptions: any = [
    { first_name: '<Select', last_name: 'None>', customer_id: '' },
  ].concat(careTeam as any);
  return (
    <Modal
      onClose={onClose}
      showCloseX
      title="Select Caregiver"
      actions={{
        primaryAction: {
          onClick: saveChanges,
          disabled: false,
          text: 'Update',
        },
      }}
    >
      <div style={{ marginBottom: '24px' }}>
        Please select a caregiver to update the status of this medication event.
        You cannot undo this action.
      </div>
      {careTeam && careTeam.length ? (
        <FormControl variant="outlined" fullWidth={true}>
          <StyledSelect
            value={caregiverCustomerId}
            onChange={handleChangeCaregiverCustomerId}
            disabled={careTeam === null}
          >
            {selectOptions.map((caregiver: any, idx: number) => (
              <MenuItem
                key={idx}
                value={`${caregiver.customer_id}`}
              >{`${caregiver.first_name} ${caregiver.last_name}`}</MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      ) : (
        <strong>No care team available to select caregiver</strong>
      )}
    </Modal>
  );
}

export default SetCaregiverCustomerId;
