import MaterialTable, { Column, Options } from '@material-table/core';
import { Typography } from '@mui/material';
import React from 'react';
import { storageHost } from '../../../apiConfig';
import { TableOptions } from '../../../shared/tableUtils';
import { formatUrl } from '../../../shared/utils';
import { SeamImage } from '../../../store/devices/deviceTypes';
import AuthenticatedLink from '../../AuthenticatedLink';

interface SeamImageTableProps {
  seamImages: SeamImage[] | null;
}

const SeamImageTable = (props: SeamImageTableProps) => {
  const { seamImages } = props;

  const columns: Column<SeamImage>[] = [
    { title: 'Image ID', field: 'id', defaultSort: 'desc' },
    {
      title: 'Created at',
      field: 'created_at',
      type: 'datetime',
    },
    {
      title: 'File name',
      field: 'storage_bucket_path',
      render: function UrlLink(rowData) {
        let fileName = rowData.storage_bucket_path.split('/');
        return (
          <AuthenticatedLink
            url={
              new URL(
                formatUrl(storageHost, 'device/' + rowData.storage_bucket_path)
              )
            }
            fileName={fileName[fileName.length - 1]}
          >
            {fileName[fileName.length - 1]}
          </AuthenticatedLink>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant={'h1'}>Seam Images</Typography>
      <MaterialTable
        columns={columns}
        data={seamImages || []}
        options={{
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          ...(TableOptions as Partial<Options<SeamImage>>),
        }}
      />
    </>
  );
};

export default SeamImageTable;
