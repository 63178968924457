import { faRedo, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FACILITY_ROLE } from '../../shared/constants';
import { TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import {
  createFacilityInvite,
  deleteFacilityInvite,
  getFacilities,
  getFacilityAdmins,
  getFacilityInvites,
} from '../../store/facility/actionCreators';
import { Admin, FacilityAdminInvite } from '../../store/facility/facilityTypes';
import CreateInviteModal from '../Shared/CreateInviteModal';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Modal from '../Shared/Modal';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import FacilityData from './components/FacilityData';
import FacilityDevices from './components/FacilityDevices';

const adminColumns: Column<Admin>[] = [
  {
    title: 'Name',
    field: 'name',
    customSort: (a, b) => a.name.localeCompare(b.name),
  },
  { title: 'Email address', field: 'email' },
];

const inviteColumns: Column<FacilityAdminInvite>[] = [
  { title: 'Email address', field: 'email', defaultSort: 'asc' },
  {
    title: 'Expires',
    field: 'expires_at',
    type: 'datetime',
  },
];

const stateSelector = (state: RootState) => {
  const { facility } = state;
  const { facilities } = facility;
  return {
    facilities,
  };
};

function FacilityDetail() {
  const dispatch = useDispatch();

  const { facilities } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const { facility_id: facilityId } = useParams<{ facility_id: string }>();

  let facility =
    facilities &&
    facilities.find((facility) => facility.id === Number(facilityId));

  let [showCreateInviteModal, setShowCreateInviteModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState<{
    primaryActionText: string;
    description: string;
    onSuccess: () => void;
  } | null>(null);

  useEffect(() => {
    if (isNil(facility)) {
      dispatch(getFacilities());
    } else {
      if (isNil(facility.admins)) {
        dispatch(getFacilityAdmins(Number(facilityId)));
      }
      if (isNil(facility.invites)) {
        dispatch(getFacilityInvites(Number(facilityId)));
      }
    }
  }, [facility, facilityId, dispatch]);

  const handleCancelModal = () => {
    setShowCreateInviteModal(false);
  };

  const removeInvite = (activeInvite: FacilityAdminInvite) => {
    dispatch(deleteFacilityInvite(Number(facilityId), activeInvite));
  };

  const createResendInvite = (activeInvite: FacilityAdminInvite) => {
    dispatch(createFacilityInvite(Number(facilityId), activeInvite));
    setShowCreateInviteModal(false);
  };

  const handleAddNewFacilityAdminClick = () => {
    setShowCreateInviteModal(true);
  };

  if (
    isNil(facilities) ||
    isNil(facility) ||
    isNil(facility.admins) ||
    isNil(facility.invites)
  ) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Facilities - {facility.name}</title>
      </Helmet>
      <FacilityData
        facility={facility}
        facilityId={Number(facilityId)}
        engOrCustomerCare={true}
      />
      <FacilityDevices facilityId={Number(facilityId)} />
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Facility administrators</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={adminColumns}
          data={facility.admins.sort((a, b) => a.name.localeCompare(b.name))}
          options={{
            idSynonym: 'name',
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            ...(TableOptions as Partial<Options<Admin>>),
          }}
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Pending invites</Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddNewFacilityAdminClick}
            >
              Add facility administrator
            </Button>
          </div>
        </HeaderWrapper>
        <MaterialTable
          columns={inviteColumns.map((c) => ({
            ...c,
            tableData: undefined,
          }))}
          data={facility.invites}
          options={{
            idSynonym: 'email',
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: -1,
            ...(TableOptions as Partial<Options<FacilityAdminInvite>>),
          }}
          actions={[
            {
              icon: function ResendIcon() {
                return <FontAwesomeIcon size="sm" icon={faRedo} />;
              },
              tooltip: 'Resend invite',
              onClick: (_, rowData) => {
                setConfirmModalProps({
                  primaryActionText: 'Resend',
                  description: `Are you sure you want to resend invite to ${
                    (rowData as FacilityAdminInvite).email
                  }?`,
                  onSuccess: () => {
                    createResendInvite(rowData as FacilityAdminInvite);
                    setConfirmModalProps(null);
                  },
                });
              },
            },
            {
              icon: function DeleteIcon() {
                return <FontAwesomeIcon size="sm" icon={faTrashAlt} />;
              },
              tooltip: 'Delete invite',
              onClick: (_, rowData) => {
                setConfirmModalProps({
                  primaryActionText: 'Delete',
                  description: `Are you sure you want to remove invite to ${
                    (rowData as FacilityAdminInvite).email
                  }?`,
                  onSuccess: () => {
                    removeInvite(rowData as FacilityAdminInvite);
                    setConfirmModalProps(null);
                  },
                });
              },
            },
          ]}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      </div>
      {confirmModalProps ? (
        <Modal
          onClose={() => setConfirmModalProps(null)}
          actions={{
            primaryAction: {
              onClick: confirmModalProps.onSuccess,
              text: confirmModalProps.primaryActionText,
            },
          }}
        >
          <div style={{ marginBottom: 10 }}>
            {confirmModalProps.description}
          </div>
        </Modal>
      ) : null}
      {showCreateInviteModal ? (
        <CreateInviteModal
          text={`Invite a new user to join ${facility.name}`}
          onClose={handleCancelModal}
          onContinue={createResendInvite}
          userRole={{ facility_role_id: FACILITY_ROLE.FACILITY_ADMIN }}
        />
      ) : null}
    </Container>
  );
}

export default FacilityDetail;
