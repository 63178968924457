import MaterialTable, { Column, Options } from '@material-table/core';
import { Button, Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getFacilities } from '../../store/facility/actionCreators';
import { FacilityInfo } from '../../store/facility/facilityTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';

const stateSelector = (state: RootState) => {
  const { facility } = state;
  const { facilities, isLoading } = facility;
  return {
    facilities,
    isLoading,
  };
};

const columns: Column<FacilityInfo>[] = [
  { title: 'Name', field: 'name', defaultSort: 'asc' },
  {
    title: 'Created at',
    field: 'created_at',
    type: 'datetime',
  },
];

function Facilities() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { facilities, isLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  useEffect(() => {
    if (isNull(facilities)) {
      dispatch(getFacilities());
    }
  }, [facilities, dispatch]);

  const onRowClick = (
    _: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: FacilityInfo | undefined
  ) => {
    if (rowData) {
      history.push('/facilities/' + rowData.id);
    }
  };

  const handleAddNew = () => {
    history.push('/facilities/add');
  };

  if (isLoading || isNull(facilities)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Facilities</title>
      </Helmet>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Facilities</Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddNew}
            >
              Add new
            </Button>
          </div>
        </HeaderWrapper>
        <MaterialTable
          columns={columns}
          data={facilities}
          onRowClick={onRowClick}
          options={{
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            ...(TableOptions as Partial<Options<FacilityInfo>>),
          }}
        />
      </div>
    </Container>
  );
}

export default Facilities;
