import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Button, Container, Grid, Typography } from '@mui/material';
// [TODO] Convert AnserLib to TS
//@ts-ignore
import AnserInputValidation from 'anser-lib-nodejs/InputValidation';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { FACILITY_ROLE } from '../../shared/constants';
import { RootState } from '../../store';
import {
  createUser,
  setCreateUserSuccessful,
} from '../../store/customers/actionCreators';
import { CreateCustomerPayload } from '../../store/customers/customerTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import SelectFormField from '../Shared/SelectFormField';
import {
  IconAdornment,
  TextInputFormField,
} from '../Shared/TextInputFormField';

const schema = yup.object({
  first_name: yup.string().trim().required('This field is required'),
  last_name: yup.string().trim().required('This field is required'),

  username: yup
    .string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email address'),

  facility_role_id: yup.string().required(),

  password: yup
    .string()
    .test('match', AnserInputValidation.passwordRequirements, function (value) {
      return AnserInputValidation.validatePassword(value);
    }),

  password2: yup
    .string()
    .test('match', 'Passwords do not match', function (value) {
      const { password } = this.parent;
      return password === value;
    }),
});

function CreateNewFacilityUser() {
  const { isLoading, createUserSuccessful } = useSelector<
    RootState,
    {
      isLoading: boolean;
      createUserSuccessful: boolean;
    }
  >((state) => {
    const { customers } = state;
    const { isLoading, createUserSuccessful } = customers;
    return {
      isLoading,
      createUserSuccessful,
    };
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((previousState) => !previousState);
  };

  useEffect(() => {
    if (createUserSuccessful) {
      history.push('/customers');
      dispatch(setCreateUserSuccessful(false));
    }
  }, [createUserSuccessful, history, dispatch]);

  const onSubmit = (data: Partial<CreateCustomerPayload>) => {
    delete data.password2;
    dispatch(createUser(data));
  };

  const handleCancel = () => {
    history.push('/customers');
  };

  const INITAL_STATE = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      username: '',
      facility_role_id: 3,
      password: '',
      password2: '',
    };
  }, []);

  const roleData = useMemo(() => {
    return [
      {
        label: 'Professional caregiver',
        value: FACILITY_ROLE.FACILITY_CAREGIVER,
      },
      { label: 'Scheduler', value: FACILITY_ROLE.FACILITY_SCHEDULER },
      { label: 'Nurse', value: FACILITY_ROLE.FACILITY_NURSE },
    ];
  }, []);

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Customers - Create Facility User</title>
      </Helmet>
      <Typography variant={'h1'}>Create a user account</Typography>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={INITAL_STATE}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Email address"
                  name="username"
                  placeholder="Email address"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Confirm password"
                  name="password2"
                  placeholder="Confirm password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Role"
                  name="facility_role_id"
                  placeholder="Role"
                  component={SelectFormField}
                  options={roleData}
                />
              </Grid>
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isLoading}
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                disabled={isLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {isLoading && <LoadingSpinner />}
    </Container>
  );
}

export default CreateNewFacilityUser;
