import React from 'react';
import MaterialTable, { Column, Options } from '@material-table/core';
import Modal from '../../Shared/Modal';
import { DeviceLog } from '../../../store/devices/deviceTypes';
import { TableOptions } from '../../../shared/tableUtils';

interface LogViewProps {
  handleClose(): void;
  logs: DeviceLog[];
}

function LogView(props: LogViewProps) {
  const { handleClose, logs } = props;

  const columns: Column<DeviceLog>[] = [
    {
      title: 'Row ID',
      field: 'id',
      width: '110px',
    },
    {
      title: 'TIMESTAMP',
      field: '__REALTIME_TIMESTAMP',
      type: 'datetime',
      defaultSort: 'desc',
      render: function span({ formatted_timestamp }) {
        return <span>{formatted_timestamp}</span>;
      },
      width: '165px',
    },
    { title: 'MESSAGE', field: 'MESSAGE' },
    { title: 'SYSLOG_IDENTIFIER', field: 'SYSLOG_IDENTIFIER', width: '170px' },
    { title: 'CODE_FILE', field: 'CODE_FILE' },
    { title: 'CODE_FUNC', field: 'CODE_FUNC' },
  ];

  return (
    <Modal onClose={handleClose} showCloseX title="Log View" fullScreen noScoll>
      <MaterialTable
        columns={columns}
        data={logs}
        options={{
          maxBodyHeight: '99vh',
          paging: false,
          rowStyle: {
            fontSize: 12,
          },
          headerStyle: {
            fontSize: 14,
            position: 'sticky',
            top: 0,
          },
          ...(TableOptions as Partial<Options<DeviceLog>>),
        }}
      />
    </Modal>
  );
}

export default LogView;
