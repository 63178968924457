import { ComputedDatum, ResponsivePie } from '@nivo/pie';
import React from 'react';
import { darkPurple, lightPurple } from '../../../../mainTheme';
import { AdherenceData } from '../../../../store/reporting/reportingTypes';

interface LayerProps {
  centerX: number;
  centerY: number;
}

const generateCenterText = (adherenceData: AdherenceData) =>
  function Layer(layerProps: LayerProps) {
    const { centerX, centerY } = layerProps;

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill={darkPurple}
        style={{
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        Number of{' '}
        {adherenceData.groupBy === 'medAssist'
          ? 'medication events: '
          : 'pills dispensed: '}
        {adherenceData.numberEvents}
      </text>
    );
  };

const getColors = (
  color: Omit<
    ComputedDatum<{ id: string; label: string; value: number }>,
    'fill' | 'color' | 'arc'
  >
) => {
  if (color.id === 'Adherent') {
    return darkPurple;
  }
  return lightPurple;
};

interface AdherenceGraphProps {
  adherenceData: AdherenceData | null;
}

const AdherenceGraph = (props: AdherenceGraphProps) => {
  const { adherenceData } = props;
  if (!adherenceData) {
    return null;
  }
  const data = [
    {
      id: 'Adherent',
      label: 'Adherent',
      value: adherenceData.numberAdherent,
    },
    {
      id: 'Non-adherent',
      label: 'Non-adherent',
      value: adherenceData.numberNonAdherent,
    },
  ];

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.8}
      padAngle={0.9}
      enableArcLinkLabels={false}
      arcLinkLabelsTextColor={darkPurple}
      arcLabelsTextColor="#FFFFFF"
      colors={getColors}
      layers={[
        'arcs',
        'arcLabels',
        'arcLinkLabels',
        'legends',
        generateCenterText(adherenceData),
      ]}
      theme={{
        legends: {
          text: {
            fontSize: 20,
            fontFamily: 'ChaletLondonSixty',
          },
        },
        labels: {
          text: {
            fontSize: 20,
            fontFamily: 'ChaletLondonSixty',
          },
        },
      }}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          translateX: -8,
          translateY: -20,
          itemWidth: 125,
          itemHeight: 18,
          itemTextColor: darkPurple,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default AdherenceGraph;
