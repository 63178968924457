import { TabSchema } from '../GlobalTypes';
import { MyAccountActionTypes } from './myAccountTypes';

export const apiError = () => ({
  type: MyAccountActionTypes.MY_ACCOUNT_API_ERR,
});

export const sideBarTabSelection = (selectedTab: TabSchema) => ({
  type: MyAccountActionTypes.MY_ACCOUNT_SIDE_BAR_TAB_SELECTION,
  payload: { selectedTab },
});
