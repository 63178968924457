import { Link } from '@mui/material';
import React, { ReactChild, useRef } from 'react';
import { getCookie } from '../shared/utils';
import { toast } from 'react-toastify';

interface AuthenticatedLinkProps {
  url: URL;
  fileName: string;
  children: ReactChild;
}

export default function AuthenticatedLink(props: AuthenticatedLinkProps) {
  const { url, fileName, children } = props;
  const link = useRef<HTMLAnchorElement | null>(null);

  const handleAction = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!link.current) {
      return;
    }

    let currentHref: URL = new URL(link.current.href);

    if (
      currentHref.hostname !== url.hostname &&
      currentHref.pathname !== url.pathname
    ) {
      return;
    }

    e.preventDefault();

    try {
      const result = await fetch(url.href, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      });

      const blob = await result.blob();
      const href = window.URL.createObjectURL(blob);

      link.current.download = fileName;
      link.current.href = href;

      link.current.click();
    } catch (err) {
      console.error(err);
      toast.error('Failed to download log');
    }
  };

  return (
    <Link href={url.href} ref={link} onClick={handleAction}>
      {children}
    </Link>
  );
}
