/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Medication } from '../models/Medication';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MedicationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List medications
   * @param pharmacyId
   * @returns Medication SUCCESSFUL
   * @throws ApiError
   */
  public listMedications(
    pharmacyId?: number
  ): CancelablePromise<Array<Medication>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/medications',
      query: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
