import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import { Wrapper } from '../../../Shared/Wrapper';
import { HeaderWrapper } from '../../../Shared/HeaderWrapper';
import {
  Button,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridRowHeightParams,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMedicationSchedule,
  addMedicationDoseEvents,
  updateMedEvents,
  deleteOwnersMedicationDoses,
} from '../../../../store/hubOwner/actionCreators';
import { getPharmacies } from '../../../../store/pharmacy/actionCreators';
import { Owner, Careteam } from '../../../../store/customers/customerTypes';
import { Pharmacy } from '../../../../store/pharmacy/pharmacyTypes';
import { DashboardUser } from '../../../../store/dashboardUser/dashboardUserTypes';
import { RootState } from '../../../../store';
import {
  LoadScheduleMatchItem,
  MedicationDoseEventType,
  UpdateMedEvents,
} from '../../../../generated';
import DosesCell from './DosesCell';
import UpdateDropdowns from './UpdateDropdowns';
import ImportMedicationRollStepForm from '../../../Shared/ImportMedicationRollStepFormModal';
import { getPrivilegeById } from '../../../../shared/utils';

export interface UpdateForm {
  newTime: string;
  newEventType: string;
  newStatus: string;
  dispensedFlow: string;
  caregiverCustomerId: string;
  adherenceStatuses: {
    timestamp?: string;
    events?: {
      [key: string]: Array<any>;
    };
  };
  deleteHistoryOnly: boolean;
  selectedMedEvents: LoadScheduleMatchItem[];
}

const humanize = (str: string): string => {
  var i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    var firstLetter = frags[i].charAt(0);
    frags[i] =
      (i === 0 ? firstLetter.toUpperCase() : firstLetter.toLocaleLowerCase()) +
      frags[i].slice(1).toLocaleLowerCase();
  }
  return frags.join(' ');
};

const gridNillComparator = (v1: any, v2: any): number | null => {
  if (v1 === null && v2 !== null) {
    return -1;
  }
  if (v2 === null && v1 !== null) {
    return 1;
  }
  if (v1 === null && v2 === null) {
    return 0;
  }

  return null;
};

const columns: GridColDef[] = [
  {
    field: 'prescribed_datetime',
    headerName: 'Prescribed date',
    valueFormatter: (params: GridValueFormatterParams) =>
      `${DateTime.fromISO(params.value, { setZone: true }).toLocaleString(
        DateTime.DATETIME_MED
      )}`,
    width: 250,
    sortComparator: (v1, v2) => {
      const value1 = new Date(v1);
      const value2 = new Date(v2);
      const nillResult = gridNillComparator(value1, value2);
      if (nillResult !== null) {
        return nillResult;
      }

      if (value1! > value2!) {
        return 1;
      }
      if (value1! < value2!) {
        return -1;
      }
      return 0;
    },
  },
  // {
  //   field: 'relative_desired_datetime',
  //   headerName: 'When',
  //   width: 150,
  //   valueGetter: (params: GridValueGetterParams) =>
  //     DateTime.fromISO(params.row.desired_datetime)
  //       .toLocal()
  //       .toRelativeCalendar(),
  // },
  {
    field: 'desired_datetime',
    headerName: 'Desired time',
    width: 150,
    valueGetter: (params: GridValueGetterParams) =>
      DateTime.fromISO(params.row.desired_datetime, {
        setZone: true,
      }).toLocaleString(DateTime.TIME_SIMPLE),
    editable: false,
  },
  {
    field: 'event_type',
    headerName: 'Event type',
    width: 180,
    editable: false,
    valueGetter: (params: GridValueGetterParams) =>
      humanize(params.row.event_type),
  },
  {
    field: 'doses',
    headerName: 'Doses',
    description: 'This column has a value getter and is not sortable.',
    renderCell: (params: GridRenderCellParams) => (
      <DosesCell
        doses={params.row.doses}
        setRowHeight={(height) =>
          params.api.unstable_setRowHeight(params.id, height)
        }
      ></DosesCell>
    ),
    sortable: false,
    width: 350,
  },
  {
    field: 'batch_id',
    headerName: 'Batch IDs',
    description: 'This is the parata batch id.',
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      Array.from(new Set(params.row.doses.map((x: any) => x.batch_id))).join(
        ', '
      ),
    width: 150,
  },
  {
    field: 'pouches',
    headerName: 'Pouch numbers',
    description: 'A list of pouch numbers.',
    renderCell: (params: GridRenderCellParams) =>
      Array.from(
        new Set(params.row.doses.map((x: any) => x.pouch_number))
      ).join(', '),
    sortable: false,
    width: 150,
  },
];

interface MedicationScheduleProps {
  customer: Owner;
  careTeam: Careteam[] | null;
}

export default function MedicationScheduleTable(
  props: MedicationScheduleProps
) {
  const { customer, careTeam } = props;
  const [showImportMedRoll, setImportMedRollModalVisibility] = useState(false);
  const [showCompletedMedEvents, setShowCompletedMedEvents] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const medSchedule = useSelector<
    RootState,
    LoadScheduleMatchItem[] | undefined
  >((state) => {
    if (!showCompletedMedEvents) {
      return state.hubOwner.medSchedule?.filter((medEvent) => {
        let containsIncompleteMedEvent = false;
        medEvent.doses?.forEach((dose) => {
          if (containsIncompleteMedEvent) return;
          if (
            (dose.roll_packaged ||
              ['active', 'upcoming'].includes(dose.status)) &&
            dose.last_received_event &&
            ![
              'administered',
              'invalidated',
              'skipped',
              'missed',
              'pre_dispensed',
            ].includes(dose.last_received_event.toLowerCase())
          )
            containsIncompleteMedEvent = true;
        });
        return containsIncompleteMedEvent;
      });
    }
    return state.hubOwner.medSchedule;
  });
  const pharmacies = useSelector<RootState, Pharmacy[] | null>((state) => {
    return state.pharmacy.pharmacies;
  });
  const dashboardUserInfo = useSelector<RootState, DashboardUser | null>(
    (state) => {
      return state.dashboardUser.dashboardUserInfo;
    }
  );

  // useEffect to retrieve medication schedule data
  useEffect(() => {
    if (
      !medSchedule ||
      (medSchedule.length && medSchedule[0].owner_id !== customer.customer_id)
    ) {
      dispatch(getMedicationSchedule(customer.customer_id));
    }
    if (isNull(pharmacies)) {
      dispatch(getPharmacies());
    }

    if (dashboardUserInfo) {
      if (
        getPrivilegeById(dashboardUserInfo.privilege_id) === 'customer_care' &&
        columns[columns.length - 1].field !== 'med_event_id'
      ) {
        columns.push({
          field: 'med_event_id',
          headerName: 'History',
          description: 'Click button to view medication event history',
          renderCell: (params: GridRenderCellParams) => (
            <Button
              variant="contained"
              color="info"
              sx={{
                minWidth: '0px',
                borderRadius: '0px',
              }}
              onClick={() => {
                history.push(
                  `/customers/${params.row.owner_id}/medication/${params.value}/history`
                );
              }}
            >
              History
            </Button>
          ),
          sortable: false,
        });
      }
    }
  }, [
    dashboardUserInfo,
    medSchedule,
    dispatch,
    customer.customer_id,
    pharmacies,
    history,
  ]);

  const saveChanges = (form: UpdateForm) => {
    if (
      form.adherenceStatuses?.timestamp &&
      form.adherenceStatuses?.events &&
      form.newStatus === 'ADHERENCE'
    ) {
      Object.keys(form.adherenceStatuses.events).forEach((adherenceStatus) => {
        const requestBody = {
          timestamp: form.adherenceStatuses.timestamp as string,
          doses: (form.adherenceStatuses.events as any)[adherenceStatus],
        };
        dispatch(
          addMedicationDoseEvents(
            customer.customer_id,
            adherenceStatus as MedicationDoseEventType,
            requestBody
          )
        );
      });
    } else if (['DELETE', 'RESET'].includes(form.newStatus)) {
      const medEventIds: string[] = form.selectedMedEvents.map(
        (medEvent) => medEvent.med_event_id as string
      );
      const requestBody = {
        filters: {
          med_event_ids: medEventIds,
        },
        delete_history_only: form.deleteHistoryOnly,
      };
      dispatch(deleteOwnersMedicationDoses(customer.customer_id, requestBody));
    } else if (form.newStatus.length) {
      const doses = form.selectedMedEvents
        .map((medEvent) =>
          medEvent?.doses?.map((dose) => {
            const doseObj: any = {
              dose_id: dose.id,
            };
            if (form.dispensedFlow.length)
              doseObj['dispensed_flow'] = form.dispensedFlow;
            return doseObj;
          })
        )
        .flat(1);
      const requestBody = {
        timestamp: DateTime.now().toUTC().toISO(),
        doses,
      };
      dispatch(
        addMedicationDoseEvents(
          customer.customer_id,
          form.newStatus.toLowerCase() as MedicationDoseEventType,
          requestBody
        )
      );
    } else {
      const medEventIds: string[] = form.selectedMedEvents.map(
        (medEvent) => medEvent.med_event_id as string
      );
      const requestBody: UpdateMedEvents = {
        med_event_ids: medEventIds,
      };
      if (form.newTime.length) requestBody.desired_admin_time = form.newTime;
      if (form.newEventType.length)
        requestBody.event_type =
          form.newEventType as UpdateMedEvents.event_type;
      if (form.caregiverCustomerId.length)
        requestBody.caregiver_customer_id = Number(form.caregiverCustomerId);
      dispatch(updateMedEvents(customer.customer_id, requestBody));
    }
  };

  const onImportMedRollSuccess = () => {
    setImportMedRollModalVisibility(false);
    dispatch(getMedicationSchedule(customer.customer_id));
  };

  const handleSwitchShowCompletedMedEvents = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowCompletedMedEvents(event.target.checked);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant={'h1'}>
          Medication Schedule
          {
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setImportMedRollModalVisibility(true)}
              sx={{ marginLeft: '10px' }}
            >
              Import Medication Roll
            </Button>
          }
        </Typography>
      </HeaderWrapper>
      <UpdateDropdowns
        privilegeId={
          dashboardUserInfo ? dashboardUserInfo.privilege_id : undefined
        }
        careTeam={careTeam}
        saveChanges={saveChanges}
        selectedMedEvents={
          medSchedule?.filter((row) =>
            selectedRowIds.includes(row.med_event_id as string)
          ) ?? []
        }
      ></UpdateDropdowns>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showCompletedMedEvents}
              onChange={handleSwitchShowCompletedMedEvents}
            />
          }
          label="Show Completed Medication Events"
        />
      </FormGroup>
      <Box sx={{ width: '100%' }}>
        {medSchedule?.length ? (
          <DataGrid
            autoHeight
            rows={medSchedule?.map((medEvent) => {
              return {
                ...medEvent,
                toggleRowHeight: false,
              };
            })}
            columns={columns}
            getRowHeight={(params: GridRowHeightParams) => {
              return params.model.toggleRowHeight ? 200 : 52;
            }}
            getRowId={(row) => row.med_event_id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            pageSizeOptions={[100]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(params) => {
              setSelectedRowIds(params.map((id) => id.toString()));
            }}
            sortModel={[
              {
                field: 'prescribed_datetime',
                sort: 'desc',
              },
            ]}
          />
        ) : medSchedule !== undefined ? (
          'Empty Med Schedule...'
        ) : (
          'Loading...'
        )}
      </Box>
      {showImportMedRoll && (
        <ImportMedicationRollStepForm
          ownerId={customer.customer_id}
          ownerName={`${customer.first_name} ${customer.last_name}`}
          ownerPharmacyName={customer.pharmacy_name || ''}
          onClose={() => setImportMedRollModalVisibility(false)}
          onSuccess={onImportMedRollSuccess}
          pharmacies={pharmacies}
        />
      )}
    </Wrapper>
  );
}
