import { TextField } from '@mui/material';
import { FieldAttributes, getIn } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';

interface MaskedInputProps {
  format: string;
  field: { name: string; onBlur(): void; onChange(): void; value: string };
  form: FieldAttributes<any>;
  extra: { children: any; label: string; margin: string; placeHolder: string };
}

function MaskedInput(props: MaskedInputProps) {
  const { format, field, form, ...extra } = props;
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <NumberFormat
      format={format}
      customInput={TextField}
      fullWidth
      variant="outlined"
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...extra}
    />
  );
}

export default MaskedInput;
