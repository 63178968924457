import { WebSocketStoreActionTypes } from './webSocketTypes';

export const wsConnected = (host: string) => ({
  type: WebSocketStoreActionTypes.WS_CONNECTED,
  payload: { host },
});

export const wsConnect = (host: string, token: string) => ({
  type: WebSocketStoreActionTypes.WS_CONNECT,
  payload: { host, token },
});

export const wsConnecting = (host: string) => ({
  type: WebSocketStoreActionTypes.WS_CONNECTING,
  payload: { host },
});

export const wsDisconnect = (host: string) => ({
  type: WebSocketStoreActionTypes.WS_DISCONNECT,
  payload: { host },
});

export const wsDisconnected = (host: string) => ({
  type: WebSocketStoreActionTypes.WS_DISCONNECTED,
  payload: { host },
});
