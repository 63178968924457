import axios from 'axios';
import request from 'superagent';

import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { oAuthHost, restHost } from '../../apiConfig';
import {
  deleteToken,
  formatUrl,
  getCookie,
  jsonToFormUrlEncoded,
} from '../../shared/utils';
import { DashboardUser } from '../dashboardUser/dashboardUserTypes';
import {
  Admin,
  FacilityAdminRegisterData,
  FacilityInfo,
  Facility,
  FacilityStore,
  FacilityStoreActionTypes,
  FacilityAdminInvite,
  NewFacility,
  UpdateFacility,
  FacilityDevice,
  FacilityDeviceAssociationAction,
} from './facilityTypes';

export const apiError = (error: any) => ({
  type: FacilityStoreActionTypes.FACILITY_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: FacilityStoreActionTypes.FACILITY_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const setSuccessAlert = (showAlert: boolean) => ({
  type: FacilityStoreActionTypes.SET_SUCCESS_ALERT,
  payload: { showSuccessAlert: showAlert },
});
export const setDuplicateAlert = (showDuplicateAlert: boolean) => ({
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT,
  payload: { showDuplicateAlert: showDuplicateAlert },
});

export const setDuplicateAlertFacility = (
  showDuplicateAlertFacility: boolean
) => ({
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT_FACILITY,
  payload: { showDuplicateAlertFacility: showDuplicateAlertFacility },
});

export const setDuplicateAlertCareTeam = (
  showDuplicateAlertCareTeam: boolean
) => ({
  type: FacilityStoreActionTypes.SET_DUPLICATE_ALERT_CARETEAM,
  payload: { showDuplicateAlertCareTeam: showDuplicateAlertCareTeam },
});

export const setErrorAlert = (showAlert: boolean, error_msg?: string) => ({
  type: FacilityStoreActionTypes.SET_ERROR_ALERT,
  payload: { showErrorAlert: showAlert, error_msg },
});

export const createFacilityAdmin = (data: FacilityAdminRegisterData) => {
  let params = jsonToFormUrlEncoded(data);

  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));

    request
      .post(formatUrl(oAuthHost, 'register')) // Specify POST method and URL
      .set('Content-Type', 'application/x-www-form-urlencoded') // Set headers
      .send(params)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(setSuccessAlert(true));
          dispatch(setLoadState(false));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          dispatch(apiError('Account already exists'));
          dispatch(setDuplicateAlert(true));
          dispatch(setLoadState(false));
        } else if (error.response && error.response.status === 406) {
          dispatch(apiError('Facility account already exists'));
          dispatch(setDuplicateAlertFacility(true));
          dispatch(setLoadState(false));
        } else if (error.response && error.response.status === 405) {
          dispatch(apiError('Care team account already exists'));
          dispatch(setDuplicateAlertCareTeam(true));
          dispatch(setLoadState(false));
        } else {
          dispatch(apiError(error));
          dispatch(setErrorAlert(true));
          dispatch(setLoadState(false));
        }
      }); // Send the form data
  };
};

export const addFacility = (facility: NewFacility) => {
  return (
    dispatch: ThunkDispatch<FacilityStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'eng/facility'), facility, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        dispatch(getFacilities());
        dispatch(facilityAdded());
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateFacility = (
  facility_id: number,
  facility: UpdateFacility,
  engOrCustomerCare: boolean = false
) => {
  return (
    dispatch: ThunkDispatch<FacilityStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, `facility/${facility_id}`), facility, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        if (engOrCustomerCare) {
          dispatch(getFacilities());
        } else {
          dispatch(getFacilityById(facility_id));
        }
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const facilityAdded = () => ({
  type: FacilityStoreActionTypes.FACILITY_ADDED,
});

export const getFacilities = () => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/facility'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateFacilities(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getFacilityDevices = (facilityId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `facility/${facilityId}/devices`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateFacilityDevices(facilityId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const getFacilityById = (facilityId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `facility/${facilityId}/info`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(populateFacilityInfo(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const populateFacilities = (facilities: FacilityInfo[]) => ({
  type: FacilityStoreActionTypes.POPULATE_FACILITIES,
  payload: { facilities },
});

export const populateFacilityDevices = (
  facility_id: number,
  devices: FacilityDevice[]
) => ({
  type: FacilityStoreActionTypes.POPULATE_FACILITY_DEVICES,
  payload: {
    facilityDevices: {
      facility_id,
      devices,
    },
  },
});

export const clearFacilityDevices = () => ({
  type: FacilityStoreActionTypes.CLEAR_FACILITY_DEVICES,
});

export const populateFacilityInfo = (facilityInfo: Facility) => ({
  type: FacilityStoreActionTypes.POPULATE_FACILITY_INFO,
  payload: { facilityInfo },
});

export const setFacilityAdmins = (facilityId: number, admins: Admin[]) => ({
  type: FacilityStoreActionTypes.SET_FACILITY_ADMINS,
  payload: { facilityId: facilityId, admins: admins },
});

export const getFacilityAdmins = (facilityId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/facility/' + facilityId + '/customers'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        let admins = res.data.map((admin: DashboardUser) => {
          return {
            name: `${admin.first_name} ${admin.last_name}`,
            email: admin.email,
          };
        });
        dispatch(setFacilityAdmins(facilityId, admins));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const setFacilityInvites = (
  facilityId: number,
  invites: FacilityAdminInvite[]
) => ({
  type: FacilityStoreActionTypes.SET_FACILITY_INVITES,
  payload: { facilityId: facilityId, invites: invites },
});

export const getFacilityInvites = (facilityId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/facility/' + facilityId + '/invite'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setFacilityInvites(facilityId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const createFacilityInvite = (
  facilityId: number,
  data: FacilityAdminInvite
) => {
  let queryParams = jsonToFormUrlEncoded(data);
  return (
    dispatch: ThunkDispatch<FacilityStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .post(
        formatUrl(restHost, 'eng/facility/' + facilityId + '/invite'),
        queryParams,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        dispatch(getFacilityInvites(facilityId));
        toast.success('Invite sent successfully');
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error(err.response.data.message);
      });
  };
};

export const deleteFacilityInvite = (
  facilityId: number,
  data: FacilityAdminInvite
) => {
  let queryParams = jsonToFormUrlEncoded(data);
  return (
    dispatch: ThunkDispatch<FacilityStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .delete(formatUrl(restHost, 'eng/facility/' + facilityId + '/invite'), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        data: queryParams,
      })
      .then((response) => {
        // Tried to delete an invite that's already accepted
        if (response.status === 200) {
          toast.error(response.data.message);
        } else {
          // 204 No problem while deleting invite
          toast.success('Invite deleted successfully');
        }
        dispatch(getFacilityInvites(facilityId));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          deleteToken();
        }
        toast.error(err.response.data.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateDeviceAssociationWithFacility = (
  facilityId: number,
  action: FacilityDeviceAssociationAction,
  device_ids: number[]
) => {
  return (
    dispatch: ThunkDispatch<FacilityStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    const requestPayload = {
      action: action,
      device_ids,
    };
    return axios
      .put(
        formatUrl(restHost, `eng/facility/${facilityId}/sync_devices`),
        requestPayload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        toast.success('Device to Facility sync completed');
        dispatch(setLoadState(false));
        dispatch(clearFacilityDevices());
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error(err.response.data.message);
      });
  };
};
