import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getFacilityRoleById } from '../../../shared/utils';
import { Careteam, Owner } from '../../../store/customers/customerTypes';
import DataRowItem from '../../Shared/DataRowItem';
import OnlineStatus from '../../Shared/OnlineStatus';

interface CaregiverDetailViewProps {
  customer: Owner | Careteam;
  title: string;
}

const CaregiverDetailView = (props: CaregiverDetailViewProps) => {
  const { customer, title } = props;

  return (
    <>
      <Typography variant={'h1'}>{title}</Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        <DataRowItem
          title="First name"
          data={customer.first_name}
          titleSize={3}
        />
        <DataRowItem
          title="Last name"
          data={customer.last_name}
          titleSize={3}
        />
        <DataRowItem
          title="Email address"
          data={customer.email}
          titleSize={3}
        />
        <DataRowItem
          title="Phone number"
          data={customer.phone_number}
          titleSize={3}
        />
        <DataRowItem
          title="Facility name"
          data={customer.facility_name}
          titleSize={3}
        />
        <DataRowItem
          title="Role"
          data={
            customer.facility_role_id
              ? getFacilityRoleById(customer.facility_role_id)
              : null
          }
          titleSize={3}
        />
        <DataRowItem title="Address" data={customer.address1} titleSize={3} />
        <DataRowItem
          title="Address 2 (optional)"
          data={customer.address2}
          titleSize={3}
        />
        <DataRowItem title="City" data={customer.city} titleSize={3} />
        <DataRowItem title="State" data={customer.state} titleSize={3} />
        <DataRowItem title="Zip code" data={customer.zipcode} titleSize={3} />
        <DataRowItem title="Country" data={customer.country} titleSize={3} />
        <DataRowItem
          title="Current status"
          titleSize={3}
          render={() => {
            return <OnlineStatus isOnline={customer.is_online} />;
          }}
        />
      </Grid>
    </>
  );
};

export default CaregiverDetailView;
