export interface PharmacyStore {
  pharmacies: Pharmacy[] | null;
  error: any | null;
  isLoading: boolean;
}

export interface Pharmacy {
  id: number;
  name: string;
  created_at: string;
  invites?: PharmacyTechnicianInvite[];
  techs?: PharmacyTechnician[];
}

export interface PharmacyTechnician {
  customer_id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  phone2: string | null;
  address1: string | null;
  address2: string | null;
  city: string;
  state: string;
  zipcode: string;
  country: string | null;
  date_of_birth: string;
  is_patient: false;
  privilege_id: 4;
  last_login_at: string | null;
  email_verified_at: string | null;
  created_at: string;
  modified_at: string | null;
  pharmacy_id: number;
  pharmacy_role_id: number;
  created_by: number;
}

export interface PharmacyTechnicianInvite {
  email: string;
  pharmacy_id: number;
  pharmacy_role_id: number;
  expires_at: string;
}

export enum PharmacyActionType {
  SET_PHARMACIES = 'SET_PHARMACIES',
  PHARMACY_API_ERR = 'PHARMACY_API_ERR',
  PHARMACY_SET_LOAD_STATE = 'PHARMACY_SET_LOAD_STATE',
  SET_PHARMACY_INVITES = 'SET_PHARMACY_INVITES',
  SET_PHARMACY_TECHS = 'SET_PHARMACY_TECHS',
}

export interface PharmacyAPIErrorAction {
  type: PharmacyActionType.PHARMACY_API_ERR;
  payload: { error: any };
}

export interface PharmacySetLoadStateAction {
  type: PharmacyActionType.PHARMACY_SET_LOAD_STATE;
  payload: { isLoading: boolean };
}

export interface SetPharmaciesAction {
  type: PharmacyActionType.SET_PHARMACIES;
  payload: { pharmacies: Pharmacy[] };
}

export interface SetPharmacyInviteAction {
  type: PharmacyActionType.SET_PHARMACY_INVITES;
  payload: { pharmacyId: number; invites: PharmacyTechnicianInvite[] };
}

export interface SetPharmacyTechniciansAction {
  type: PharmacyActionType.SET_PHARMACY_TECHS;
  payload: { pharmacyId: number; techs: PharmacyTechnician[] };
}

export type PharmacyAction =
  | PharmacyAPIErrorAction
  | PharmacySetLoadStateAction
  | SetPharmaciesAction
  | SetPharmacyInviteAction
  | SetPharmacyTechniciansAction;

export interface PharmacyInviteDeletePayload {
  email: string;
  pharmacy_id: number;
  pharmacy_role_id: number;
  expires_at: string;
}
