import isEqual from 'lodash/isEqual';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { MY_ACCOUT_TABS } from '../../shared/constants';
import { RootState } from '../../store';
import { TabSchema } from '../../store/GlobalTypes';
import { sideBarTabSelection } from '../../store/myAccount/actionCreators';
import SideTabs from '../Shared/SideTabs';
import ChangePassword from './components/ChangePassword';
import PersonalInfo from './components/PersonalInfo';

function MyAccount() {
  const { selectedTab } = useSelector<
    RootState,
    {
      selectedTab: TabSchema;
    }
  >((state) => {
    const { myAccount } = state;
    const { selectedTab } = myAccount;
    return {
      selectedTab,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sideBarTabSelection(MY_ACCOUT_TABS.PERSONAL_INFO));
  }, [dispatch]);

  return (
    <div style={{ display: 'flex' }}>
      <Helmet>
        <title>Ōmcare - My Account</title>
      </Helmet>
      <SideTabs
        sideBarTabSelection={sideBarTabSelection}
        selectedTab={selectedTab}
        tabs={MY_ACCOUT_TABS}
      />
      <div style={{ flexGrow: 1, marginTop: '50px', marginLeft: '50px' }}>
        {isEqual(selectedTab, MY_ACCOUT_TABS.PERSONAL_INFO) && (
          <PersonalInfo title={MY_ACCOUT_TABS.PERSONAL_INFO.text} />
        )}
        {isEqual(selectedTab, MY_ACCOUT_TABS.CHANGE_PASSWORD) && (
          <ChangePassword title={MY_ACCOUT_TABS.CHANGE_PASSWORD.text} />
        )}
      </div>
    </div>
  );
}

export default MyAccount;
