import { generateBirthDateFromAgeRange } from '../../../../shared/utils';
import { BasicSelectedValue } from '../../../../store/reporting/reportingTypes';

const FilterData: BasicSelectedValue[] = [
  {
    display: 'Age range',
    value: 'ageRange',
  },
  {
    display: 'Event type',
    value: 'eventType',
  },
  {
    display: 'Facility',
    value: 'facilityId',
  },
  {
    display: 'Gender',
    value: 'gender',
  },
  {
    display: 'Pharmacy',
    value: 'pharmacyId',
  },
  {
    display: 'Prescribed time',
    value: 'medAdminTime',
  },
  {
    display: 'State',
    value: 'state',
  },
  {
    display: 'Zip code',
    value: 'zipCodes',
  },
];

const OwnerFilterDataItem: BasicSelectedValue = {
  display: 'Owner',
  value: 'ownerIds',
};

const ReportDenominators: BasicSelectedValue[] = [
  {
    display: 'Medication',
    value: 'pill',
  },
  {
    display: 'Medication event',
    value: 'medAssist',
  },
];

const Timespans: BasicSelectedValue[] = [
  {
    display: 'Yesterday',
    value: 'yesterday',
  },
  {
    display: 'Last 7 days',
    value: 'last7Days',
  },
  {
    display: 'Last 14 days',
    value: 'last14Days',
  },
  {
    display: 'Last 30 days',
    value: 'last30Days',
  },
  {
    display: 'Custom',
    value: 'datePicker',
  },
];

const AgeRanges: BasicSelectedValue[] = [
  {
    display: '50-59',
    value: generateBirthDateFromAgeRange('50-59'),
  },
  {
    display: '60-69',
    value: generateBirthDateFromAgeRange('60-69'),
  },
  {
    display: '70-79',
    value: generateBirthDateFromAgeRange('70-79'),
  },
  {
    display: '80-89',
    value: generateBirthDateFromAgeRange('80-89'),
  },
  {
    display: '90-99',
    value: generateBirthDateFromAgeRange('90-99'),
  },
  {
    display: '100+',
    value: generateBirthDateFromAgeRange('100-125'),
  },
  {
    display: 'Custom',
    value: 'custom',
  },
];

const Genders: BasicSelectedValue[] = [
  {
    display: 'Male',
    value: '1',
  },
  {
    display: 'Female',
    value: '2',
  },
  { display: 'Non binary', value: '10' },
  { display: 'Decline to say', value: '90' },
];

const States = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

const MedAdminTimes: BasicSelectedValue[] = [
  {
    display: '12:00 AM',
    value: {
      fromTime: '22:00',
      toTime: '02:00',
    },
  },
  {
    display: '1:00 AM',
    value: {
      fromTime: '23:00',
      toTime: '03:00',
    },
  },
  {
    display: '2:00 AM',
    value: {
      fromTime: '00:00',
      toTime: '04:00',
    },
  },
  {
    display: '3:00 AM',
    value: {
      fromTime: '01:00',
      toTime: '05:00',
    },
  },
  {
    display: '4:00 AM',
    value: {
      fromTime: '02:00',
      toTime: '06:00',
    },
  },
  {
    display: '5:00 AM',
    value: {
      fromTime: '03:00',
      toTime: '07:00',
    },
  },
  {
    display: '6:00 AM',
    value: {
      fromTime: '04:00',
      toTime: '08:00',
    },
  },
  {
    display: '7:00 AM',
    value: {
      fromTime: '05:00',
      toTime: '09:00',
    },
  },
  {
    display: '8:00 AM',
    value: {
      fromTime: '06:00',
      toTime: '10:00',
    },
  },
  {
    display: '9:00 AM',
    value: {
      fromTime: '07:00',
      toTime: '11:00',
    },
  },
  {
    display: '10:00 AM',
    value: {
      fromTime: '08:00',
      toTime: '12:00',
    },
  },
  {
    display: '11:00 AM',
    value: {
      fromTime: '09:00',
      toTime: '13:00',
    },
  },
  {
    display: '12:00 PM',
    value: {
      fromTime: '10:00',
      toTime: '14:00',
    },
  },
  {
    display: '1:00 PM',
    value: {
      fromTime: '11:00',
      toTime: '15:00',
    },
  },
  {
    display: '2:00 PM',
    value: {
      fromTime: '12:00',
      toTime: '16:00',
    },
  },
  {
    display: '3:00 PM',
    value: {
      fromTime: '13:00',
      toTime: '17:00',
    },
  },
  {
    display: '4:00 PM',
    value: {
      fromTime: '14:00',
      toTime: '18:00',
    },
  },
  {
    display: '5:00 PM',
    value: {
      fromTime: '15:00',
      toTime: '19:00',
    },
  },
  {
    display: '6:00 PM',
    value: {
      fromTime: '16:00',
      toTime: '20:00',
    },
  },
  {
    display: '7:00 PM',
    value: {
      fromTime: '17:00',
      toTime: '21:00',
    },
  },
  {
    display: '8:00 PM',
    value: {
      fromTime: '18:00',
      toTime: '22:00',
    },
  },
  {
    display: '9:00 PM',
    value: {
      fromTime: '19:00',
      toTime: '23:00',
    },
  },
  {
    display: '10:00 PM',
    value: {
      fromTime: '20:00',
      toTime: '00:00',
    },
  },
  {
    display: '11:00 PM',
    value: {
      fromTime: '21:00',
      toTime: '01:00',
    },
  },
];

const EventTypes: BasicSelectedValue[] = [
  {
    display: 'Caregiver assist',
    value: '1',
  },
  {
    display: 'Smart assist',
    value: '2',
  },
];

export {
  FilterData,
  OwnerFilterDataItem,
  ReportDenominators,
  Timespans,
  AgeRanges,
  Genders,
  States,
  MedAdminTimes,
  EventTypes,
};
