import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { fetchOwnerPin } from '../../../store/customers/actionCreators';
import Modal from '../../Shared/Modal';
import {
  IconAdornment,
  TextInputFormField,
} from '../../Shared/TextInputFormField';

interface PinRequestModalProps {
  ownerCustomerId: number;
  onClose(): void;
  ownerPin: string | null;
  userEmail: string;
}

function PinRequestModal(props: PinRequestModalProps) {
  const { ownerCustomerId, onClose, ownerPin, userEmail } = props;

  const [showPin, setShowPin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((previousState) => !previousState);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (ownerPin) {
      setShowPin(true);
      const timer = setTimeout(() => {
        setShowPin(false);
        onClose();
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [ownerPin, setShowPin, onClose]);

  const schema = yup.object({
    email: yup
      .string()
      .label('Email')
      .email()
      .required('This field is required')
      .test('match', 'Invalid email address', function (value) {
        return value?.toLowerCase() === userEmail?.toLowerCase();
      }),
    password: yup.string().required('This field is required'),
  });

  const formikRef =
    useRef<FormikProps<{ email: string; password: string }>>(null);

  const handleContinue = () => {
    formikRef.current && formikRef.current.submitForm();
  };

  const onSubmit = () => {
    if (formikRef.current) {
      dispatch(
        fetchOwnerPin(formikRef.current.values.password, ownerCustomerId)
      );
    }
  };

  return (
    <Modal
      onClose={onClose}
      showCloseX={showPin}
      title={
        showPin
          ? 'You may view the PIN for up to 60 seconds before the window will automatically close.'
          : undefined
      }
      actions={
        showPin
          ? undefined
          : {
              primaryAction: {
                text: 'Request PIN',
                onClick: handleContinue,
                disabled: !!ownerPin,
              },
            }
      }
    >
      {showPin ? (
        <Typography variant="h3">{ownerPin}</Typography>
      ) : (
        <>
          <div style={{ marginBottom: '10px' }}>
            Viewing an owner&apos;s PIN is logged and monitored as part of HIPAA
            security requirements. Please enter your login credentials.
          </div>
          <Formik
            innerRef={formikRef}
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  name="email"
                  label="Email address"
                  placeholder="Email address"
                  component={TextInputFormField}
                  style={{ margin: '10px 0' }}
                />
                <Field
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                  style={{ margin: '10px 0' }}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
}

export default PinRequestModal;
