import { Typography } from '@mui/material';
import React from 'react';

// TODO
// Style component per Kendra's design

interface OnlineOwnerCountProps {
  customers: number;
  onlineCustomers: number;
}

const OnlineOwnerCount = (props: OnlineOwnerCountProps) => {
  const { onlineCustomers, customers } = props;
  return (
    <>
      <Typography>Online owners</Typography>
      <Typography>
        {onlineCustomers}/{customers}
      </Typography>
    </>
  );
};

export default OnlineOwnerCount;
