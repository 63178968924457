import { Grid } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { careteamRoleOptions } from '../../../shared/constants';
import { createCareteamInvite } from '../../../store/customers/actionCreators';
import { CareteamInvite, Owner } from '../../../store/customers/customerTypes';
import Modal from '../../Shared/Modal';
import SelectFormField from '../../Shared/SelectFormField';
import { TextInputFormField } from '../../Shared/TextInputFormField';

const schema = yup.object({
  first_name: yup.string().trim().required('This field is required'),
  last_name: yup.string().trim().required('This field is required'),
  email: yup.string().label('Email').email().required('This field is required'),
  careteam_role_id: yup.string().required('This field is required'),
});

interface InviteCareteamUserModalProps {
  onClose(): void;
  owner: Owner;
}

export const InviteCareteamUserModal = (
  props: InviteCareteamUserModalProps
) => {
  const { onClose, owner } = props;
  const formikRef = useRef<FormikProps<Partial<CareteamInvite>>>(null);
  const dispatch = useDispatch();
  return (
    <Modal
      onClose={onClose}
      showCloseX
      maxWidth="md"
      title={`Invite a new user to join ${owner.first_name} ${owner.last_name}'s care team`}
      actions={{
        primaryAction: {
          onClick: () => {
            formikRef.current && formikRef.current.submitForm();
          },
          text: 'Invite',
        },
      }}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={async (values) => {
          await dispatch(createCareteamInvite(owner.customer_id, values));
          onClose();
        }}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          careteam_role_id: '',
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Email address"
                  name="email"
                  placeholder="Email address"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Role"
                  name="careteam_role_id"
                  placeholder="Role"
                  component={SelectFormField}
                  options={careteamRoleOptions}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
