import { Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { US_STATES } from '../../../shared/constants';
import { updateDashboardUserProfile } from '../../../store/dashboardUser/actionCreators';
import {
  DashboardUser,
  DashboardUserProfile,
} from '../../../store/dashboardUser/dashboardUserTypes';
import MaskedInput from '../../Shared/MaskedInput';
import SelectFormField from '../../Shared/SelectFormField';
import { TextInputFormField } from '../../Shared/TextInputFormField';

const stateOptions = US_STATES.map((item) => {
  return { label: item.name, value: item.id };
});

const editableFields: (keyof DashboardUser)[] = [
  `first_name`,
  `last_name`,
  `phone`,
  `phone2`,
  `address1`,
  `address2`,
  `city`,
  `state`,
  `zipcode`,
  `country`,
];

const schema = yup.object({
  first_name: yup.string().trim().required('This field is required'),
  last_name: yup.string().trim().required('This field is required'),
  address1: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  zipcode: yup
    .number()
    .typeError('Invalid zip code')
    .required('This field is required')
    .max(99999, 'Invalid zip code')
    .min(10000, 'Invalid zip code'),
  phone: yup
    .string()
    .matches(
      new RegExp(
        '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$'
      ),
      {
        message: 'Should be a valid phone number',
      }
    )
    .required('This field is required'),
  phone2: yup
    .string()
    .matches(
      new RegExp(
        '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$'
      ),
      {
        message: 'Should be a valid phone number',
      }
    ),
});

interface EditPersonalProfileProps {
  user: DashboardUser;
  onSuccess(): void;
  onCancel(): void;
}

function EditPersonalProfile(props: EditPersonalProfileProps) {
  const { user, onSuccess, onCancel } = props;
  const sanitizedInputs = (Object.keys(user) as (keyof DashboardUser)[])
    .filter((field) => editableFields.includes(field))
    .reduce(
      (acl, curr) => Object.assign({}, acl, { [curr]: user[curr] || '' }),
      {} as DashboardUserProfile
    );

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={sanitizedInputs}
      onSubmit={async (values: DashboardUserProfile) => {
        await dispatch(updateDashboardUserProfile(values));
        onSuccess();
      }}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} style={{ maxWidth: '33%' }}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <Field
                label="First name"
                name="first_name"
                placeholder="First name"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Last name"
                name="last_name"
                placeholder="Last name"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Phone number"
                name="phone"
                component={MaskedInput}
                margin="none"
                format="(###) ###-####"
                placeholder="(123)-456-7890"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Phone number 2 (optional)"
                name="phone2"
                component={MaskedInput}
                margin="none"
                format="(###) ###-####"
                placeholder="(123)-456-7890"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Address"
                name="address1"
                placeholder="Address"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Address 2 (optional)"
                name="address2"
                placeholder="Address 2 (optional)"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="City"
                name="city"
                placeholder="City"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="State"
                name="state"
                placeholder="State"
                component={SelectFormField}
                margin="none"
                options={stateOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Zip code"
                name="zipcode"
                placeholder="Zip code"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Country"
                name="country"
                placeholder="Country"
                component={TextInputFormField}
                margin="none"
              />
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs="auto">
                  <Button variant="contained" color="secondary" type="submit">
                    Save
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditPersonalProfile;
