import {
  Button,
  Container,
  Link,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Paper,
} from '@mui/material';
import isNull from 'lodash/isNull';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getSoftwareVersions } from '../../store/software/actionCreators';
import { SoftwareVersion } from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { HeaderWrapper } from '../Shared/HeaderWrapper';

const stateSelector = (state: RootState) => {
  const { software } = state;
  const { softwareVersions } = software;
  return {
    softwareVersions,
  };
};

function SoftwareVersions() {
  const history = useHistory();

  const columns: Column<SoftwareVersion>[] = [
    { title: 'ID', field: 'id', defaultSort: 'desc' },
    { title: 'Version', field: 'version' },
    {
      title: 'Created at',
      field: 'created_at',
      type: 'datetime',
      customSort: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
    },
    {
      title: 'Commit',
      field: '',
      render: function RenderLink(rowData) {
        if (!rowData.commit_url) {
          return null;
        }

        return (
          <Link href={rowData.commit_url.href}>
            {rowData.semver?.build.join('')}
          </Link>
        );
      },
    },
    {
      title: 'Edit',
      field: '',
      sorting: false,
      render: function EditButton(rowData) {
        const redirectToSoftwareVersionDetail = (id: number) => {
          history.push(`/softwareVersion/${id}`);
        };
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => redirectToSoftwareVersionDetail(rowData.id)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const { softwareVersions } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }
  }, [softwareVersions, dispatch]);

  // const handleAddNew = () => {
  //   history.push('/softwareVersion/create');
  // };

  if (isNull(softwareVersions)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Ōmcare - Software Versions</title>
      </Helmet>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          {/* <Button variant="contained" color="secondary" onClick={handleAddNew}>
            Add new software version
          </Button> */}
        </HeaderWrapper>
        <MaterialTable
          detailPanel={(rowData) => {
            return (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Documentation URL</TableCell>
                      <TableCell>
                        <Link href={rowData.rowData.documentation_url || ''}>
                          {rowData.rowData.documentation_url || ''}
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={rowData.rowData.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        Full URL
                      </TableCell>
                      <TableCell>
                        <Link href={rowData.rowData.full_url || ''}>
                          {rowData.rowData.full_url || ''}
                        </Link>
                      </TableCell>
                      {/* <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
          columns={columns}
          data={softwareVersions}
          options={{
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            ...(TableOptions as Partial<Options<SoftwareVersion>>),
          }}
        />
      </div>
    </Container>
  );
}

export default SoftwareVersions;
