import { Reducer } from 'redux';
import {
  WebSocketAction,
  WebSocketStore,
  WebSocketStoreActionTypes,
} from './webSocketTypes';

const initialState: WebSocketStore = {
  connected: false,
};

const webSocketReducer: Reducer<WebSocketStore, WebSocketAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WebSocketStoreActionTypes.WS_CONNECTED: {
      console.log('setting connected = true');
      let newState = {
        ...state,
        connected: true,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default webSocketReducer;
