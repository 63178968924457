/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateMedEvents = {
  med_event_ids: Array<string>;
  /**
   * Only time should be passed and make sure all med event ids have same time of the day.
   */
  desired_admin_time?: string;
  /**
   * Marks the event as either a caregiver assist or a smart assist
   */
  event_type?: UpdateMedEvents.event_type;
  /**
   * Is required only when event type is CAREGIVER_ASSIST
   */
  caregiver_customer_id?: number;
};

export namespace UpdateMedEvents {
  /**
   * Marks the event as either a caregiver assist or a smart assist
   */
  export enum event_type {
    CAREGIVER_ASSIST = 'CAREGIVER_ASSIST',
    SMART_ASSIST = 'SMART_ASSIST',
  }
}
