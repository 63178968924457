import { Button, Container, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import isNull from 'lodash/isNull';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { RootState } from '../../store';
import {
  editSoftwareVersion,
  getSoftwareVersions,
} from '../../store/software/actionCreators';
import {
  SoftwareVersion,
  SoftwareVersionRequestV2,
} from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import SelectFormField from '../Shared/SelectFormField';
import { TextInputFormField } from '../Shared/TextInputFormField';

function EditSoftwareVersion() {
  const dispatch = useDispatch();
  const { softwareVersions } = useSelector((state: RootState) => {
    const { software } = state;
    const { softwareVersions } = software;
    return {
      softwareVersions,
    };
  });
  const history = useHistory();

  let params = useParams<{ versionId: string }>();
  let targetVersion = parseInt(params.versionId);

  const [currentPageSoftwareVersion, setCurrentPageSoftwareVersion] =
    useState<SoftwareVersion | null>(null);

  useEffect(() => {
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }

    if (softwareVersions && !currentPageSoftwareVersion) {
      setCurrentPageSoftwareVersion(
        softwareVersions.filter((version) => version.id === targetVersion)[0]
      );
    }
  }, [softwareVersions, targetVersion, dispatch, currentPageSoftwareVersion]);

  const schema = yup.object({
    version: yup.string().required('This field is required'),
    full_url: yup.string().required('This field is required'),
  });

  const software_versions_list = useMemo(() => {
    if (!softwareVersions) return [];
    return softwareVersions.map((version) => {
      let cur_version = version.version_major + '.' + version.version_minor;
      if (version.version_prerelease !== 0) {
        cur_version += 'pre' + version.version_prerelease;
      }
      return { label: cur_version, value: version.id };
    });
  }, [softwareVersions]);

  const onSubmit = async (data: Partial<SoftwareVersionRequestV2>) => {
    await dispatch(editSoftwareVersion(data));
    history.push('/software');
  };

  const handleCancel = () => {
    history.push('/software');
  };

  if (isNull(softwareVersions) || !currentPageSoftwareVersion) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Software Version - Edit</title>
      </Helmet>
      <Typography variant={'h1'}>Edit a software version</Typography>

      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          id: currentPageSoftwareVersion.id,
          version: currentPageSoftwareVersion.version_raw,
          base_version_id: currentPageSoftwareVersion.base_version_id,
          documentation_url: currentPageSoftwareVersion.documentation_url
            ? currentPageSoftwareVersion.documentation_url
            : '',
          full_url: currentPageSoftwareVersion.full_url,
          patch_url: currentPageSoftwareVersion.patch_url
            ? currentPageSoftwareVersion.patch_url
            : undefined,
          software_product_id: 1,
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={4}>
                <Field
                  label="Version"
                  name="version"
                  placeholder="version"
                  component={TextInputFormField}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={8}>
                <Field
                  label="Base version"
                  disabled={true}
                  name="base_version_id"
                  placeholder="Base version"
                  component={SelectFormField}
                  options={software_versions_list}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  label="Full URL"
                  name="full_url"
                  placeholder="Full URL"
                  disabled={true}
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Patch URL"
                  name="patch_url"
                  placeholder="Patch URL"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Documentation URL"
                  name="documentation_url"
                  placeholder="Documentation URL"
                  component={TextInputFormField}
                />
              </Grid>
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default EditSoftwareVersion;
