export enum DeviceActionTypes {
  POPULATE_DEVICES = 'POPULATE_DEVICES',
  CLEAR_DEVICE_LIST = 'CLEAR_DEVICE_LIST',
  UPDATE_DEVICE_STATUS = 'UPDATE_DEVICE_STATUS',
  FAILURE_SYNC_DEVICE = 'FAILURE_SYNC_DEVICE',
  DID_SYNC_DEVICE = 'DID_SYNC_DEVICE',
  CLEAR_SYNC_DEVICE_STATE = 'CLEAR_SYNC_DEVICE_STATE',
  DEVICES_API_ERR = 'DEVICES_API_ERR',
  DEVICES_SET_LOAD_STATE = 'DEVICES_SET_LOAD_STATE',
  STORE_LOG = 'STORE_LOG',
  CLEAR_LOG = 'CLEAR_LOG',
  STORE_SEAM_IMAGES = 'STORE_SEAM_IMGES',
  STORE_DEVICE_LOGS = 'STORE_DEVICE_LOGS',
  CLEAR_SEAM_IMAGES = 'CLEAR_SEAM_IMAGES',
  STORE_DEVICE_SHADOW = 'STORE_DEVICE_SHADOW',
  CLEAR_DEVICE_SHADOW = 'CLEAR_DEVICE_SHADOW',
  STORE_BB_IMAGES = 'STORE_BB_IMAGES',
}

export interface DeviceSyncPayload {
  sync_code: string;
  patient_customer_id: number;
}

export type DeviceStatus = 'offline' | 'online';

export interface Log {
  device_id: number;
  id: number;
  created_at: string;
  note: string | null;
  email: string;
  uploaded_at: string;
  url: string;
}

export interface Device {
  id: number;
  serial_number: string;
  customer_id: number;
  first_name: string;
  last_name: string;
  email: string;
  date_of_birth: string;
  synced_by_customer_name?: string;
  sync_code_done_by?: number;
  sync_code_done_by_first_name?: string;
  sync_code_done_by_last_name?: string;
  facility_id: number | null;
  facility_name: string | null;
  hardware_version: string;
  software_version: string;
  software_channel_id: number;
  logs: Log[];
  status: DeviceStatus;
  pinned_software_version_id: number;
  owner_name: string;
}

export interface DeviceLog {
  _TRANSPORT: string;
  __MONOTONIC_TIMESTAMP: string;
  SYSLOG_FACILITY: string;
  _BOOT_ID: string;
  MESSAGE: string;
  _HOSTNAME: string;
  __REALTIME_TIMESTAMP: string;
  _MACHINE_ID: string;
  _SOURCE_MONOTONIC_TIMESTAMP: string;
  SYSLOG_IDENTIFIER: string;
  PRIORITY: string;
  __CURSOR: string;
  formatted_timestamp: string;
  id: number;
}

export interface SeamImage {
  id: number;
  device_id: number;
  storage_bucket_path: string;
  created_at: string;
}

export interface ShadowConfig {
  configName: string;
  reportedValue: any;
  desiredValue: any;
}

interface BBImage {
  key: string;
  uploaded_at: string;
  presignedUrl: string;
}

export interface BBImageSetMetadata {
  serial_number: string;
  owner_id: number;
  med_event_id: string;
  sentry_event_id: string;
  uploaded_at: string;
  images: BBImage[];
}

export type PopulateDevicesAction = {
  type: DeviceActionTypes.POPULATE_DEVICES;
  payload: { deviceList: Device[] };
};

export type ClearDeviceList = {
  type: DeviceActionTypes.CLEAR_DEVICE_LIST;
};

export type UpdateDeviceStatusAction = {
  type: DeviceActionTypes.UPDATE_DEVICE_STATUS;
  payload: { deviceSerialNumber: string; deviceStatus: string };
};

export type SyncFailureDeviceAction = {
  type: DeviceActionTypes.FAILURE_SYNC_DEVICE;
  payload: { syncDeviceError: SyncDeviceError | null };
};

export type DidSyncDeviceAction = {
  type: DeviceActionTypes.DID_SYNC_DEVICE;
};

export type ClearSyncDeviceStatusAction = {
  type: DeviceActionTypes.CLEAR_SYNC_DEVICE_STATE;
};

export type DeviceAPIErrorAction = {
  type: DeviceActionTypes.DEVICES_API_ERR;
  payload: { error: any };
};

export type SetLoadStateAction = {
  type: DeviceActionTypes.DEVICES_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type StoreLogAction = {
  type: DeviceActionTypes.STORE_LOG;
  payload: { log: DeviceLog[] };
};

export type ClearLogAction = {
  type: DeviceActionTypes.CLEAR_LOG;
};

export interface SyncDeviceError {
  error_code: 1001 | 1002;
  error_message: string;
}

export type StoreSeamImages = {
  type: DeviceActionTypes.STORE_SEAM_IMAGES;
  payload: {
    deviceId: number;
    seamImages: { [deviceId: string]: SeamImage[] };
  };
};

export type ClearSeamImages = {
  type: DeviceActionTypes.CLEAR_SEAM_IMAGES;
};

export type StoreDeviceLogs = {
  type: DeviceActionTypes.STORE_DEVICE_LOGS;
  payload: {
    deviceId: number;
    deviceLogs: Log[];
  };
};

export type StoreDeviceShadow = {
  type: DeviceActionTypes.STORE_DEVICE_SHADOW;
  payload: {
    deviceId: number;
    shadowConfigs: ShadowConfig[];
  };
};

export type ClearDeviceShadow = {
  type: DeviceActionTypes.CLEAR_DEVICE_SHADOW;
};

export type StoreBBImages = {
  type: DeviceActionTypes.STORE_BB_IMAGES;
  payload: {
    bbImages: BBImageSetMetadata[];
  };
};

export interface DevicesStore {
  deviceList: Device[] | null;
  syncDeviceError: SyncDeviceError | null;
  error: any | null;
  isLoading: boolean;
  currentLog: DeviceLog[] | null;
  deviceSynced: boolean;
  deviceLogs: Log[] | null;
  seamImages: SeamImage[] | null;
  shadowConfigs: ShadowConfig[] | null;
  bbImages: BBImageSetMetadata[] | null;
}

export type DeviceActions =
  | PopulateDevicesAction
  | ClearDeviceList
  | UpdateDeviceStatusAction
  | SyncFailureDeviceAction
  | DidSyncDeviceAction
  | ClearSyncDeviceStatusAction
  | DeviceAPIErrorAction
  | SetLoadStateAction
  | StoreLogAction
  | ClearLogAction
  | StoreSeamImages
  | StoreDeviceLogs
  | ClearSeamImages
  | StoreDeviceShadow
  | ClearDeviceShadow
  | StoreBBImages;
