import { Chip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';
import { Owner } from '../store/customers/customerTypes';
import { Device } from '../store/devices/deviceTypes';
import { PharmacyTechnician } from '../store/pharmacy/pharmacyTypes';
import { getFacilityRoleById } from './utils';
import moment from 'moment';
import { DateTime, Duration } from 'luxon';

export const TableOptions = {
  searchFieldVariant: 'outlined',
  emptyRowsWhenPaging: false,
  thirdSortClick: false,
  showTitle: false,
  draggable: false,
};

// Sorting

export const SortByDateOfBirth = (
  a: { date_of_birth: string },
  b: { date_of_birth: string }
) => moment(a.date_of_birth).diff(b.date_of_birth);

export const SortByDesiredAdminTime = (
  a: { desired_admin_time: string },
  b: { desired_admin_time: string }
) =>
  moment('01/01/2022 ' + a.desired_admin_time).diff(
    '01/01/2022 ' + b.desired_admin_time
  );

export const sortByFullName = (
  a: { first_name: string; last_name: string },
  b: { first_name: string; last_name: string }
) =>
  `${a.last_name} ${a.first_name}`.localeCompare(
    `${b.last_name} ${b.first_name}`
  );

function parseRelativeTime(relativeTime: string): DateTime {
  const matches = relativeTime.match(
    /(\d+)\s*(years?|months?|days?|hours?|minutes?)\s*ago/i
  );

  if (!matches) {
    throw new Error('Invalid relative time string format');
  }

  const value = parseInt(matches[1]);
  const unit = matches[2].toLowerCase();

  let duration: Duration;

  if (unit.includes('day')) {
    duration = Duration.fromObject({ days: value }).negate();
  } else if (unit.includes('hour')) {
    duration = Duration.fromObject({ hours: value }).negate();
  } else if (unit.includes('minute')) {
    duration = Duration.fromObject({ minutes: value }).negate();
  } else if (unit.includes('year')) {
    duration = Duration.fromObject({ years: value }).negate();
  } else if (unit.includes('month')) {
    duration = Duration.fromObject({ months: value }).negate();
  } else {
    throw new Error('Invalid unit in relative time string');
  }

  return DateTime.local().plus(duration);
}
export function sortByCreatedAt(a: Owner, b: Owner): number {
  const timeA = parseRelativeTime(a.created_at);
  const timeB = parseRelativeTime(b.created_at);
  return timeA.toMillis() - timeB.toMillis();
}

export const SortByPrescribedDateTime = (
  a: { prescribed_datetime: string },
  b: { prescribed_datetime: string }
) => moment(a.prescribed_datetime).diff(b.prescribed_datetime);

// render functions for columns

export const NameFormatter = (rowData: Owner | PharmacyTechnician) => {
  return <div>{rowData.first_name + ' ' + rowData.last_name}</div>;
};

export function StatusChip(rowData: Device) {
  return (
    <Chip
      label={rowData.status.toUpperCase()}
      color={rowData.status === 'online' ? 'primary' : 'default'}
    />
  );
}

export function StatusIcon(rowData: Owner) {
  const color = rowData.is_online ? '#82c8ba' : '#cc5d4b';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FiberManualRecordIcon color="error" style={{ color }} />{' '}
      {rowData.is_online ? 'Online' : 'Offline'}
    </div>
  );
}

export function newlyCreated(rowData: any, newThreshold: number = 4) {
  const currentDate = new Date();
  // Calculate the start date for the date range (last 3 days including today is considered as new)
  const startDateRange = new Date();
  startDateRange.setDate(currentDate.getDate() - newThreshold);
  const startDate = new Date(rowData.created_at_string);
  const col =
    startDate >= startDateRange && startDate <= currentDate
      ? '#D0F0C0'
      : '#FFFFFF';
  const text =
    startDate >= startDateRange && startDate <= currentDate ? 'new' : '';
  return (
    <div style={{ display: 'flex', alignItems: 'left' }}>
      <span style={{ marginRight: '5px' }}>{rowData.created_at}</span>
      <div
        style={{
          width: '37px',
          height: '20px',
          backgroundColor: col,
          fontSize: '15px',
        }}
      >
        <span style={{ padding: '4px' }}>{text}</span>
      </div>
    </div>
  );
}

export function DisplayRole(rowData: Owner) {
  return (
    <div>
      {rowData.facility_role_id
        ? getFacilityRoleById(rowData.facility_role_id)
        : 'unknown'}
    </div>
  );
}
