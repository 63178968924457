import MaterialTable, { Column, Options } from '@material-table/core';
import { Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { sortByFullName, TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getCustomerList } from '../../store/customers/actionCreators';
import { Owner } from '../../store/customers/customerTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';

const materialTableColumns: Column<Owner>[] = [
  {
    title: 'Name',
    field: 'name',
    customSort: sortByFullName,
  },
  {
    title: 'Username',
    field: 'email',
  },
  {
    title: 'Date of birth',
    field: 'date_of_birth',
    type: 'date',
  },
];

const Caregivers = () => {
  const { careGivers, connected } = useSelector<
    RootState,
    { careGivers: Owner[] | null; connected: boolean }
  >((state) => {
    const { customers, webSocket } = state;
    const { careGivers } = customers;
    const { connected } = webSocket;
    return {
      careGivers,
      connected,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!careGivers) {
      dispatch(getCustomerList());
    }
  }, [connected, careGivers, dispatch]);

  if (isNull(careGivers)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Caregivers</title>
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant={'h1'}>Caregiver list</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={materialTableColumns}
          data={careGivers.sort(sortByFullName)}
          options={{
            idSynonym: 'customer_id',
            pageSize: 150,
            pageSizeOptions: [150, 250, 350],
            ...(TableOptions as Partial<Options<Owner>>),
          }}
        />
      </Wrapper>
    </Container>
  );
};

export default Caregivers;
