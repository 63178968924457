/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CertificatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add new certificate
   * @param createDevice This creates a corresponding device based on the certificate's CN, if one does not already exist
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public addCertificate(
    createDevice?: boolean,
    requestBody?: {
      /**
       * A certificate signing request in PEM format
       */
      certificate_signing_request?: string;
    }
  ): CancelablePromise<{
    /**
     * A string representing the signed certificate in pem format
     */
    certificate_pem?: string;
    /**
     * The certificate's serial number and identifier for certificate API calls.
     */
    certificate_serial_number?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/certificates',
      query: {
        create_device: createDevice,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * List all certificates associated with logged in user
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public listCertificates(): CancelablePromise<{
    /**
     * The certificate's serial number
     */
    certificate_serial_number?: string;
    /**
     * The certificate's common name, this usually represents a device's serial number.
     */
    certificate_common_name?: string;
    /**
     * Timestamp when the certificate was created (signed).
     */
    created_at?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/certificates',
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Delete certificate
   * @param certificateSerialNumber
   * @returns void
   * @throws ApiError
   */
  public deleteCertificate(
    certificateSerialNumber?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/certificates/{certificate_serial_number}',
      path: {
        certificate_serial_number: certificateSerialNumber,
      },
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
