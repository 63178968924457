import { OmcareRestClient } from '../generated';
import { restHost } from '../apiConfig';
import { getCookie, deleteToken } from '../shared/utils';
import axios, { AxiosError } from 'axios';

// Create the client instance with server and authentication details
const restClient = new OmcareRestClient({
  BASE: `https://${restHost}`,
  TOKEN: getCookie('access_token'),
});

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err: AxiosError) => {
    try {
      if (
        err.response?.status === 401
        //&& err.response?.config.url?.includes(restHost)
      ) {
        console.log('hit here');
        deleteToken();
        return err;
      } else {
        return err;
      }
    } catch (iErr) {
      console.error(iErr);
      return err;
    }
  }
);

export default restClient;
