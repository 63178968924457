/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EngService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve a list of software versions
   * @returns any Get software versions
   * @throws ApiError
   */
  public getSoftwareVersion(): CancelablePromise<
    Array<{
      id?: number;
      software_product_id?: number;
      version?: string;
      version_raw?: string;
      version_major?: number;
      version_minor?: number;
      version_patch?: number;
      version_prerelease_id?: number;
      version_metadata?: number;
      version_prerelease?: number;
      base_version_id?: number;
      full_url?: string;
      patch_url?: string;
      documentation_url?: string;
      created_at?: string;
      created_by?: number;
      modified_at?: string;
      modified_by?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/softwareVersion',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Add a new software version to the database
   * @param requestBody Either version or version_* fields are required to be set
   * @returns any Success
   * @throws ApiError
   */
  public addSoftwareVersion(requestBody?: {
    software_product_id?: number;
    version?: string;
    version_major?: number;
    version_minor?: number;
    version_prerelease?: number;
    version_prerelease_id?: string;
    version_metadata?: string;
    'base_version_id?'?: number;
    'documentation_url?'?: string;
    full_url?: string;
    'patch_url?'?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/softwareVersion',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Edit an existing software version in the database
   * @param requestBody
   * @returns any SUCCESS
   * @throws ApiError
   */
  public editSoftwareVersion(requestBody?: {
    id?: number;
    software_product_id?: number;
    version_major?: number;
    version_minor?: number;
    version_prerelease?: number;
    'base_version_id?'?: number;
    'documentation_url?'?: string;
    full_url?: string;
    'patch_url?'?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/eng/softwareVersion',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Retrieve a JSON array of software deployments from the database
   * @returns any Get software versions
   * @throws ApiError
   */
  public getSoftwareDeployment(): CancelablePromise<
    Array<{
      id?: number;
      software_version_id?: number;
      software_channel_id?: number;
      created_at?: string;
      created_by?: number;
      documentation_url?: string;
      version_major?: number;
      version_minor?: number;
      version_patch?: number;
      version_prerelease?: number;
      version_prerelease_id?: number;
      email?: string;
      version_metadata?: string;
      version?: string;
      version_raw?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/softwareDeployment',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Set the current software version id to the one specificed in {version_id} (responds with new list of software versions)
   * @param versionId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public deployVersion(
    versionId?: number,
    requestBody?: {
      channel_id?: number;
      'documentation_url?'?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/deployVersion/{version_id}',
      path: {
        version_id: versionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Return a list of all facilites in the database
   * @returns any Success
   * @throws ApiError
   */
  public getFacilities(): CancelablePromise<
    Array<{
      id?: number;
      name?: string;
      med_window_time_sec?: number;
      created_at?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/facility',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Add new facility
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public createFacility(requestBody?: {
    name?: string;
    med_window_time_sec?: number;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/facility',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Generate an api key
   * @returns any Successful
   * @throws ApiError
   */
  public generateApiKey(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/generateApiKey',
      errors: {
        400: `Not found`,
        403: `Only Engineering users can create api keys`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get the list of api keys
   * @returns any Successful
   * @throws ApiError
   */
  public getApiKeys(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/apikeys',
      errors: {
        403: `Only engineer users can get API Keys`,
        404: `Customer not found`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Revoke the api keys
   * @returns any Successful
   * @throws ApiError
   */
  public revokeApiKey(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/eng/revokeApiKey',
      errors: {
        400: `Not found`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get a list of all customers for a facility
   * @param facilityId Facility ID
   * @returns any Success
   * @throws ApiError
   */
  public getFacilityCustomers(facilityId?: number): CancelablePromise<
    Array<{
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      is_patient?: boolean;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      date_of_birth?: string;
      privilege_id?: number;
      med_window_time_sec?: number;
      patient_customer_id?: number;
      facility_id?: number;
      facility_role_id?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/facility/{facility_id}/customers',
      path: {
        facility_id: facilityId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get pending invites for facility
   * @param facilityId Facility ID
   * @returns any Success
   * @throws ApiError
   */
  public getPendingInvitesByFacility(facilityId?: number): CancelablePromise<
    Array<{
      email?: string;
      facility_id?: number;
      facility_role_id?: number;
      expires_at?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/facility/{facility_id}/invite',
      path: {
        facility_id: facilityId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Create a new OR resend an invite to a facility admin
   * @param facilityId Facility ID
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public createFacilityInvite(
    facilityId?: number,
    requestBody?: {
      email?: string;
      facility_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/facility/{facility_id}/invite',
      path: {
        facility_id: facilityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * delete a facility admin invite
   * @param facilityId Facility ID
   * @param requestBody
   * @returns any Invite does not exist or you tried to delete invite that has already been accepted
   * @throws ApiError
   */
  public deleteFacilityInvite(
    facilityId?: number,
    requestBody?: {
      email?: string;
      facility_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/eng/facility/{facility_id}/invite',
      path: {
        facility_id: facilityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get a list of all customers for a pharmacy
   * @param pharmacyId Pharmacy ID
   * @returns any Success
   * @throws ApiError
   */
  public getPharmacyCustomers(pharmacyId?: string): CancelablePromise<
    Array<{
      id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      date_of_birth?: string;
      is_patient?: boolean;
      privilege_id?: number;
      last_login_at?: string;
      email_verified_at?: string;
      created_at?: string;
      modified_at?: string;
      facility_id?: number;
      facility_role_id?: number;
      pharmacy_name?: string;
      pharmacy_patient_id?: string;
      device_serial_number?: string;
      facility_name?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/pharmacy/{pharmacy_id}/customers',
      path: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get a list of all technicians for a pharmacy
   * @param pharmacyId Pharmacy ID
   * @returns any successfully got array of customer objects
   * @throws ApiError
   */
  public getPharmacyTechs(pharmacyId?: string): CancelablePromise<
    Array<{
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      date_of_birth?: string;
      is_patient?: boolean;
      privilege_id?: number;
      last_login_at?: string;
      email_verified_at?: string;
      created_at?: string;
      modified_at?: string;
      pharmacy_id?: number;
      pharmacy_role_id?: number;
      created_by?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/pharmacy/{pharmacy_id}/technicians',
      path: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get pending invites for pharmacy
   * @param pharmacyId Pharmacy ID
   * @returns any Successfully get an array of pending invites
   * @throws ApiError
   */
  public getPendingInvitesByPharmacy(pharmacyId?: string): CancelablePromise<
    Array<{
      email?: string;
      pharmacy_id?: number;
      pharmacy_role_id?: number;
      expires_at?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/pharmacy/{pharmacy_id}/invite',
      path: {
        pharmacy_id: pharmacyId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Create a new OR resend an invite to a pharmacy people
   * @param pharmacyId Pharmacy ID
   * @param requestBody
   * @returns any Invited successfully
   * @throws ApiError
   */
  public createPharmacyInvite(
    pharmacyId?: string,
    requestBody?: {
      email?: string;
      pharmacy_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/eng/pharmacy/{pharmacy_id}/invite',
      path: {
        pharmacy_id: pharmacyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * delete a pharmacy invite
   * @param pharmacyId Pharmacy ID
   * @param requestBody
   * @returns any Tried to delete already accepted invite
   * @throws ApiError
   */
  public deletePharmacyInvite(
    pharmacyId?: string,
    requestBody?: {
      email?: string;
      pharmacy_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/eng/pharmacy/{pharmacy_id}/invite',
      path: {
        pharmacy_id: pharmacyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Parameters`,
        401: `UNAUTHORIZED`,
        500: `Internal error`,
      },
    });
  }

  /**
   * Update Device Attributes
   * @param deviceId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateDeviceProperties(
    deviceId?: number,
    requestBody?: {
      software_channel_id?: number;
    }
  ): CancelablePromise<{
    message?: string;
  }> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/eng/device/{device_id}/',
      path: {
        device_id: deviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get the metadata for bounding box images from S3
   * @param startDate
   * @returns any Success
   * @throws ApiError
   */
  public getBoundingBoxImages(startDate?: string): CancelablePromise<
    Array<{
      /**
       * The device serial number
       */
      serial_number?: string;
      /**
       * The owner ID extracted from the S3 key
       */
      owner_id?: string;
      /**
       * The med event ID extracted from the S3 key
       */
      med_event_id?: string;
      /**
       * The sentry event ID extracted from the S3 key
       */
      sentry_event_id?: string;
      images?: Array<{
        /**
         * The S3 object key
         */
        key?: string;
        /**
         * The uploaded date of the S3 object
         */
        uploaded_at?: string;
        /**
         * The pre-signed URL for accessing the S3 object
         */
        presignedUrl?: string;
      }>;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eng/bounding_box_images',
      query: {
        start_date: startDate,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Link|Unlink devices from facility ( ENG, CUST CARE)
   * @param facilityId Facility Id to be assigned a device
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateDeviceAssociationWithFacility(
    facilityId: number,
    requestBody?: {
      /**
       * Action to be performed (e.g., LINK or UNLINK)
       */
      action?: 'LINK' | 'UNLINK';
      /**
       * List of device IDs to be linked or unlinked
       */
      device_ids?: Array<number>;
    }
  ): CancelablePromise<{
    /**
     * List of device IDs that were successfully linked to facility
     */
    successful_link?: Array<number>;
    failure?: Array<{
      /**
       * Device ID that failed to link
       */
      failed_association?: number;
      /**
       * Device assigned to individual, Unassign device first
       */
      error?: string;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/eng/facility/{facility_id}/sync_devices',
      path: {
        facility_id: facilityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
