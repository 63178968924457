import { isEqual, isNull } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  REPORTING_TABS,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../shared/constants';
import { getPrivilegeById } from '../../shared/utils';
import { RootState } from '../../store';
import { getFacilityCustomers } from '../../store/customers/actionCreators';
import { Owner } from '../../store/customers/customerTypes';
import { DashboardUser } from '../../store/dashboardUser/dashboardUserTypes';
import { getFacilities } from '../../store/facility/actionCreators';
import { TabSchema } from '../../store/GlobalTypes';
import { getPharmacies } from '../../store/pharmacy/actionCreators';
import { sideBarTabSelection } from '../../store/reporting/actionCreators';
import SideTabs from '../Shared/SideTabs';
import AdherenceReport from './components/AdherenceReport/AdherenceReport';
import ReportingDashboard from './components/ReportingDashboard';

function Reporting() {
  const { dashboardUserInfo, selectedTab, facilityCustomers, connected } =
    useSelector<
      RootState,
      {
        dashboardUserInfo: DashboardUser | null;
        selectedTab: TabSchema;
        facilityCustomers: Owner[] | null;
        connected: boolean;
      }
    >((state) => {
      const { reporting, dashboardUser, customers, webSocket } = state;
      const { selectedTab } = reporting;
      const { dashboardUserInfo } = dashboardUser;
      const { facilityCustomers } = customers;
      const { connected } = webSocket;
      return {
        selectedTab,
        dashboardUserInfo,
        facilityCustomers,
        connected,
      };
    });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sideBarTabSelection(REPORTING_TABS.DASHBOARD));
    dispatch(getPharmacies());
  }, [dispatch]);

  useEffect(() => {
    if (dashboardUserInfo) {
      if (getPrivilegeById(dashboardUserInfo.privilege_id) === 'engineering') {
        dispatch(getFacilities());
      }
      if (
        dashboardUserInfo.facility_role_id &&
        UNIFIED_FACILITY_ADMIN_ROLES.includes(
          dashboardUserInfo.facility_role_id
        ) &&
        isNull(facilityCustomers) &&
        dashboardUserInfo.facility_id
      ) {
        dispatch(getFacilityCustomers(dashboardUserInfo.facility_id));
      }
    }
  }, [connected, dashboardUserInfo, dispatch, facilityCustomers]);

  return (
    <div style={{ display: 'flex' }}>
      <Helmet>
        <title>Ōmcare - Reporting</title>
      </Helmet>
      <SideTabs
        sideBarTabSelection={(params: TabSchema) =>
          dispatch(sideBarTabSelection(params))
        }
        selectedTab={selectedTab}
        tabs={REPORTING_TABS}
      />
      <div style={{ flexGrow: 1, marginTop: '50px', marginLeft: '50px' }}>
        {isEqual(selectedTab, REPORTING_TABS.DASHBOARD) && (
          <ReportingDashboard title={REPORTING_TABS.DASHBOARD.text} />
        )}
        {isEqual(selectedTab, REPORTING_TABS.ADHERENCE) && (
          <AdherenceReport title={REPORTING_TABS.ADHERENCE.text} />
        )}
      </div>
    </div>
  );
}

export default Reporting;
