import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { syncDevice } from '../../../store/devices/actionCreators';
import Modal from '../../Shared/Modal';

interface SyncDeviceModalProps {
  ownerCustomerId: number;
  onClose(): void;
}

function SyncDeviceModal(props: SyncDeviceModalProps) {
  const { ownerCustomerId, onClose } = props;

  const [typedInput, setTypedInput] = useState('');

  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      syncDevice({
        sync_code: typedInput,
        patient_customer_id: ownerCustomerId,
      })
    );
  };

  return (
    <Modal
      onClose={onClose}
      actions={{
        primaryAction: {
          onClick: onSubmit,
          disabled: typedInput.length !== 4 || !Number(typedInput),
          text: 'Assign',
        },
      }}
    >
      <div style={{ marginBottom: '24px' }}>
        Enter the code displaying on the Ōmcare Home Health Hub.
      </div>
      <TextField
        value={typedInput}
        variant="outlined"
        placeholder={`1234`}
        onChange={(e) => setTypedInput(e.target.value)}
        fullWidth
      />
    </Modal>
  );
}

export default SyncDeviceModal;
