import { Button, Container } from '@mui/material';
import isNull from 'lodash/isNull';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { TableOptions } from '../../shared/tableUtils';
import { getSoftwareChannelById } from '../../shared/utils';
import { RootState } from '../../store';
import {
  createSoftwareDeployment,
  getSoftwareDeployment,
  getSoftwareVersions,
} from '../../store/software/actionCreators';
import {
  SoftwareDeployment,
  SoftwareDeploymentRequest,
  SoftwareVersion,
} from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import DeploySoftwareVersionModal from './components/DeploySoftwareVersionModal';
import { HeaderWrapper } from '../Shared/HeaderWrapper';

const columns: Column<SoftwareDeployment>[] = [
  { title: 'Version', field: 'version' },
  {
    title: 'Software channel',
    field: 'software_channel.display',
  },
  {
    title: 'Documentation URL',
    field: 'documentation_url',
  },
  { title: 'Created by', field: 'email' },
  {
    title: 'Created at',
    field: 'created_at',
    type: 'datetime',
    defaultSort: 'desc',
    customSort: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
  },
];

const stateSelector = (state: RootState) => {
  const { software } = state;
  const { softwareVersions, deployments } = software;

  return {
    softwareVersions,
    deployments,
  };
};

function SoftwareVersionDeployment() {
  const dispatch = useDispatch();

  const { softwareVersions, deployments } = useSelector(stateSelector);

  const softwareVersionMap: { [key: string]: SoftwareVersion } = {};
  const [show_deploy_modal, setShowDeployModal] = useState(false);

  useEffect(() => {
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }
    if (isNull(deployments)) {
      dispatch(getSoftwareDeployment());
    }
  }, [softwareVersions, dispatch, deployments]);

  const software_versions_list = useMemo(() => {
    if (!softwareVersions) return [];
    return softwareVersions.map((version) => {
      return { label: version.version, value: version.id };
    });
  }, [softwareVersions]);

  deployments?.forEach((deployment) => {
    deployment.software_channel = {
      value: deployment.software_channel_id,
      display: getSoftwareChannelById(deployment.software_channel_id),
    };
  });

  const onSubmit = (
    data: SoftwareDeploymentRequest & { confirm_version?: string }
  ) => {
    delete data.confirm_version;
    dispatch(createSoftwareDeployment(data));
    setShowDeployModal(false);
  };

  if (!isNull(softwareVersions)) {
    softwareVersions.forEach((version) => {
      softwareVersionMap[version.id] = version;
    });
  }

  if (isNull(deployments) || isNull(softwareVersions)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Ōmcare - Software Deployments</title>
      </Helmet>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDeployModal(true)}
          >
            Deploy software version
          </Button>
        </HeaderWrapper>
        <MaterialTable
          columns={columns}
          data={deployments}
          options={{
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            ...(TableOptions as Partial<Options<SoftwareDeployment>>),
          }}
        />
      </div>
      {show_deploy_modal ? (
        <DeploySoftwareVersionModal
          onClose={() => setShowDeployModal(false)}
          softwareVersionMap={softwareVersionMap}
          softwareVersionsList={software_versions_list}
          onSubmit={onSubmit}
        />
      ) : null}
    </Container>
  );
}

export default SoftwareVersionDeployment;
