import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';
import { FacilityAdminInvite } from '../../store/facility/facilityTypes';
import { PharmacyTechnicianInvite } from '../../store/pharmacy/pharmacyTypes';
import Modal from './Modal';
import { TextInputFormField } from './TextInputFormField';

const schema = yup.object({
  email: yup.string().label('Email').email().required('This field is required'),
});

interface CreateInviteModalProps {
  text: string;
  onClose(): void;
  onContinue(
    invite: Partial<PharmacyTechnicianInvite> | Partial<FacilityAdminInvite>
  ): void;
  userRole: Object;
}

const CreateInviteModal = (props: CreateInviteModalProps) => {
  const { text, onClose, onContinue, userRole } = props;

  const formikRef = useRef<FormikProps<{ email: string }>>(null);

  const handleContinue = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const onSubmit = () => {
    if (formikRef.current) {
      onContinue({
        email: formikRef.current.values.email,
        ...userRole,
      });
    }
  };

  return (
    <Modal
      onClose={onClose}
      actions={{ primaryAction: { onClick: handleContinue, text: 'Invite' } }}
    >
      <div style={{ marginBottom: 10 }}>{text}</div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{ email: '' }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              label="Email address"
              name="email"
              placeholder="Enter an email address"
              component={TextInputFormField}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateInviteModal;
