/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of gender
 */
export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
  DECLINE_TO_SAY = 'DECLINE_TO_SAY',
}
