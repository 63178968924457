import { createTheme } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';

export const darkPurple = '#250E62';
export const lightPurple = '#865E9C';
export const lightPurple20 = '#E7DFEB';
export const lightTeal = '#6BCABA';
export const lightTeal20 = '#E1F4F1';
export const lightBackground1 = '#FFFFFF';
export const lightBackground2 = '#F2F2F7';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: darkPurple,
    },
    secondary: {
      main: lightPurple,
    },
    text: {
      primary: darkPurple,
      secondary: lightPurple,
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: 'ChaletLondonSixty',
  },
  shadows: Array(25).fill('none') as Shadows,
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          ':first-of-type': {
            paddingLeft: 16,
            margin: 0,
          },
        },
        clearIndicator: {
          marginTop: -8,
        },
        popupIndicator: {
          marginTop: '-7px',
          marginRight: '5px',
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: `${lightTeal20} !important`,
          },
          '&.Mui-focused': {
            backgroundColor: `${lightPurple20} !important`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          minWidth: '150px',
          textTransform: 'none',
          height: '50px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          minWidth: 1280,
        },
        maxWidthLg: {
          lineHeight: 1,
          fontSize: '18px',
          width: '80%',
          marginTop: 50,
        },
        maxWidthXl: {
          lineHeight: 1,
          fontSize: '18px',
          marginTop: 50,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 21px 8px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-2px',
          '&.Mui-disabled': {
            color: 'rgba(0,0,0,0.26)',
          },
          '&.Mui-focused': {
            top: 0,
          },
          // transform: 'translate(14px, 14px) scale(1)',
          // '&.Mui-focused': { transform: 'translate(14px, -9px) scale(0.75)' }
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': { background: lightTeal20 },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: darkPurple,
          ':hover': {
            color: lightPurple,
            background: lightPurple20,
          },
          '&.Mui-selected': { background: lightTeal20 },
          fontSize: '18px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '50px',
          borderRadius: '30px',
          fontSize: '18px',
          color: lightPurple,
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0,0,0,0.12)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { fontSize: '18px' },
        rounded: {
          borderRadius: '30px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            borderRadius: '30px',
          },
        },
        // Icon adornment at the end of Selects
        icon: {
          // Centers icon in Selects
          top: 'calc(13%)',
          marginRight: '9px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: '0px solid',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '28px',
          fontWeight: 600,
          marginBottom: '25px',
          lineHeight: 1,
        },
        h2: {
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: 1,
        },
      },
    },
  },
});

export default mainTheme;
