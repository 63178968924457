export interface AccessTokenInterface {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export interface ChangePasswordInterface {
  current_password: string;
  new_password: string;
}

export interface AuthStore {
  completeAccessToken: AccessTokenInterface | null;
  error: any | null;
  isLoading: boolean;
}

export enum AuthStoreActionTypes {
  SUCCESS_CHANGE_USER_PASSWORD = 'SUCCESS_CHANGE_USER_PASSWORD',
  AUTH_API_ERR = 'AUTH_API_ERR',
  RESET_COMPLETE_ACCESS_TOKEN = 'RESET_COMPLETE_ACCESS_TOKEN',
  AUTH_SET_LOAD_STATE = 'AUTH_SET_LOAD_STATE',
}

export type SuccessChangeUserPasswordType = {
  type: AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD;
  payload: { accessToken: AccessTokenInterface };
};

export type ResetCompleteAccessTokenType = {
  type: AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN;
};

export type ApiErrorType = {
  type: AuthStoreActionTypes.AUTH_API_ERR;
  payload: { error: any };
};

export type SetLoadStateType = {
  type: AuthStoreActionTypes.AUTH_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type AuthStoreAction =
  | SuccessChangeUserPasswordType
  | ResetCompleteAccessTokenType
  | ApiErrorType
  | SetLoadStateType;
