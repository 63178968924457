import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import { FormikValues } from 'formik';
import React, { ReactNode } from 'react';

const CustomIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '2%',
  top: '1%',
}));

const CancelButton = styled(Button)(() => ({
  marginRight: 10,
}));

interface ModalProps {
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  onClose?(): void;
  title?: string;
  showCloseX?: boolean;
  children: ReactNode;
  actions?: {
    primaryAction?: {
      text: string;
      onClick(arg?: FormikValues): void;
      disabled?: boolean;
    };
    //primaryActionText: string;
    //primaryAction(arg?: FormikValues): void;
    //disabled?: boolean;
    secondaryAction?: {
      text: string;
      onClick(arg?: FormikValues): void;
      disabled?: boolean;
    };
  };
  fullScreen?: boolean;
  noScoll?: boolean;
}

function Modal(props: ModalProps) {
  const {
    maxWidth = 'sm',
    onClose,
    title,
    showCloseX,
    children,
    actions = null,
    fullScreen = false,
    noScoll = false,
  } = props;

  const handleClose = (
    _event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (!showCloseX && reason === 'backdropClick') {
      return false;
    }

    if (!showCloseX && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth={maxWidth}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      {title || showCloseX ? (
        <DialogTitle
          id="dialog-title"
          sx={{ fontSize: '18px', fontWeight: 600 }}
        >
          {title}
          {showCloseX && (
            <CustomIconButton
              aria-label="close"
              onClick={onClose}
              color="primary"
              sx={{ marginRight: '4px' }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </CustomIconButton>
          )}
        </DialogTitle>
      ) : null}
      <DialogContent
        tabIndex={0}
        sx={{
          overflowY: noScoll ? 'hidden' : 'auto',
          paddingTop: '20px !Important',
        }}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions>
          <CancelButton
            variant="outlined"
            color="secondary"
            onClick={actions.secondaryAction?.onClick ?? onClose}
            disabled={actions.secondaryAction?.disabled}
          >
            {actions.secondaryAction?.text ?? 'Cancel'}
          </CancelButton>
          <Button
            variant="contained"
            color="secondary"
            onClick={actions.primaryAction?.onClick}
            disabled={actions.primaryAction?.disabled}
          >
            {actions.primaryAction?.text}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Modal;
