import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import {
  Step,
  StepLabel,
  Stepper,
  StepContent,
  Typography,
  // CircularProgress,
} from '@mui/material';
import Modal from './Modal';

import {
  CreateMedicationRollPost,
  Drug,
  MedicationEvent,
  Pouch,
} from '../../store/hubOwner/types';

import { Pharmacy } from '../../store/pharmacy/pharmacyTypes';

import {
  getMedicationScheduleTemplate,
  resetImportMedRollForm,
  createMedicationRoll,
} from '../../store/hubOwner/actionCreators';

import { FormValues } from './ImportMedicationRollStepFormTypes';
import MedicationRollMetaInformation from './steps/MedicationRollMetaInformation';
import MedicationRollMedEventsInformation from './steps/MedicationRollMedEventsInformation';
import MedicationRollFormReview from './steps/MedicationRollReview';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import _ from 'lodash';
import { DateTime } from 'luxon';

interface ImportMedicationRollStepFormProps {
  ownerId: number;
  ownerName: string;
  ownerPharmacyName: string;
  onClose(): void;
  onContinue?(formData: any): void;
  onSuccess?(): void;
  pharmacies: Pharmacy[] | null;
}

const steps = [
  MedicationRollMetaInformation,
  MedicationRollMedEventsInformation,
  MedicationRollFormReview,
];

const initialValues = {
  ...MedicationRollMetaInformation.initialValues,
  ...MedicationRollMedEventsInformation.initialValues,
  ...MedicationRollFormReview.initialValues,
} as FormValues;

const ImportMedicationRollStepForm = (
  props: ImportMedicationRollStepFormProps
) => {
  const [activeStep, setActiveStep] = useState(0);

  const template = useSelector<
    RootState,
    {
      initialValues: FormValues;
      templateRemoteAttempted?: boolean;
      templateRemoteDate?: DateTime;
      templateRemoteBatchId?: Number;
    }
  >((state) => {
    const { medScheduleTemplate } = state.hubOwner;

    // TODO: remove this in favor of a pharmacy id returned from API
    const selectedPharmacyId = _.find(
      props.pharmacies,
      (p) => p.name === props.ownerPharmacyName
    )?.id;

    if (medScheduleTemplate?.medEvents.length) {
      return {
        initialValues: {
          ...initialValues,
          medEvents: medScheduleTemplate.medEvents,
          pharmacy: selectedPharmacyId,
        } as FormValues,
        templateRemoteAttempted: state.medications.medications.length > 0,
        templateRemoteDate: medScheduleTemplate.date,
        templateRemoteBatchId: medScheduleTemplate.batchId,
      };
    }
    return {
      initialValues: {
        ...initialValues,
        pharmacy: selectedPharmacyId,
      } as FormValues,
      templateRemoteAttempted: state.medications.medications.length > 0,
    };
  });

  const importSuccess = useSelector<RootState, boolean>(
    (state) => state.hubOwner.importMedicationRollSuccess
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!template?.templateRemoteAttempted) {
      dispatch(getMedicationScheduleTemplate(props.ownerId));
    }

    if (importSuccess && props.onSuccess) {
      dispatch(resetImportMedRollForm());
      props.onSuccess();
    }
  });

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handlePrev = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  const handleNext = () => [
    setActiveStep(Math.min(activeStep + 1, steps.length - 1)),
  ];

  const onSubmit = () => {
    if (!formikRef.current) {
      return;
    }

    const { setSubmitting, values } = formikRef.current;

    if (!isLastStep()) {
      setSubmitting(false);
      handleNext();
      return;
    }

    /* TODO: map this to a pharmacy configuration rather than a hardcoded pharmacy name */
    const isFullPharmacyIntegration: boolean =
      props.pharmacies?.find((x) => x.id === values.pharmacy)?.name !==
      'DivvyDOSE';

    const data = {
      batchId: isFullPharmacyIntegration ? values.batchId : 0,
      customerId: props.ownerId,
      pharmacyId: values.pharmacy,
      startDate: values.startDate,
      scheduleTemplateDuration: values.scheduleTemplateRepeatDays,
      scheduleTemplate: values.medEvents.map((me) => {
        return {
          desiredTimeOfDay24: me.desiredTimeOfDay,
          prescribedTimeOfDay24: me.desiredTimeOfDay,
          eventType: me.eventType,
          pouches: me.pouches.map((p) => {
            return {
              pouchNumber: isFullPharmacyIntegration ? p.pouchNumber ?? 0 : 0,
              drugs: p.drugs.map((d) => {
                return {
                  nationalDrugCode: d.ndc,
                  rollPackaged: d.rollPackaged === true,
                  isPrn: d.isPrn === true,
                } as Drug;
              }),
            } as Pouch;
          }),
        } as MedicationEvent;
      }),
    } as CreateMedicationRollPost;

    dispatch(createMedicationRoll(data));
  };

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const ActiveStep = steps[activeStep];
  const validationSchema = ActiveStep.validationSchema;

  return (
    <Modal
      title={`Create a medication schedule for ${props.ownerName}`}
      maxWidth="sm"
      showCloseX
      onClose={props.onClose}
      actions={{
        primaryAction: {
          onClick: () => formikRef.current?.submitForm(),
          text: isLastStep() ? 'Submit' : 'Next',
          disabled: formikRef.current?.isSubmitting,
        },
        secondaryAction: {
          onClick: handlePrev,
          text: 'Previous',
          disabled: activeStep === 0 || formikRef.current?.isSubmitting,
        },
      }}
    >
      {/* <CircularProgress /> */}

      <Formik
        initialValues={template.initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <>
              <Form>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((_, index) => {
                    const Component = steps[index];
                    return (
                      <Step key={index}>
                        <StepLabel>{steps[index].label}</StepLabel>
                        <StepContent>
                          <Component
                            key={index}
                            pharmacies={props.pharmacies}
                            formik={formik}
                            templateRemoteDate={template.templateRemoteDate}
                            templateRemoteBatchId={
                              template.templateRemoteBatchId
                            }
                          />
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
                {Object.keys(formik.errors).length && formik.submitCount > 0 ? (
                  <Typography variant="caption">
                    {JSON.stringify(formik.errors)}
                  </Typography>
                ) : null}
                {/* {JSON.stringify(formik.values, null, 2)} */}
              </Form>
            </>
          </LocalizationProvider>
        )}
      </Formik>
    </Modal>
  );
};

export default ImportMedicationRollStepForm;
