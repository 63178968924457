import { Grid, GridSize, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type DataRowProps = {
  title?: string | null;
  titleSize?: number;
  dataSize?: number;
  data?: string | number | null;
  render?: () => ReactNode;
};

const DataRowItem = (props: DataRowProps) => {
  const {
    title,
    titleSize = 4,
    dataSize = 12 - titleSize,
    data = null,
    render,
  } = props;
  return (
    <Grid item container style={{ minHeight: '47px' }}>
      {title && (
        <Grid item xs={titleSize as GridSize}>
          <Typography variant={'h2'}>{title}</Typography>
        </Grid>
      )}
      <Grid item xs={dataSize as GridSize}>
        {render ? render() : data ? data : '-'}
      </Grid>
    </Grid>
  );
};
export default DataRowItem;
