import {
  Grid,
  TextField,
  Button,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Paper,
  List,
  // ListSubheader,
  ListItemText,
  ListItem,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
} from '@mui/material';
import React from 'react';
import { FormikProps, FieldArray, Field } from 'formik';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextInputFormField, IconAdornment } from '../TextInputFormField';
import SelectFormField from '../SelectFormField';

import { Pharmacy } from '../../../store/pharmacy/pharmacyTypes';

import { startCase as _startCase } from 'lodash';

import {
  FormValues,
  MedEvent,
  Pouch,
  Drug,
} from '../ImportMedicationRollStepFormTypes';

import * as Yup from 'yup';
import { MedEventType } from '../../../generated';
import { faCircleXmark, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DateTime } from 'luxon';

import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { Medication } from '../../../generated';

interface MedicationRollMedEventsInformationProps {
  formik: FormikProps<FormValues>;
  pharmacies: Pharmacy[] | null;
  templateRemoteDate?: DateTime;
  templateRemoteBatchId?: Number;
  key: number;
}

const MedicationRollMedEventsInformation = (
  props: MedicationRollMedEventsInformationProps
) => {
  const { formik } = props;

  const meds = useSelector<RootState, Array<Medication>>(
    (state) => state.medications.medications
  );

  const medEvents = formik.values.medEvents;

  const getErrors = (errors: any, index: number, field: string) => {
    if (Array.isArray(errors) && errors.length) {
      if (errors[index]) return errors[index][field];
    }

    return '';
  };

  /* TODO: map this to a pharmacy configuration rather than a hardcoded pharmacy name */
  const isFullPharmacyIntegration: boolean =
    props.pharmacies?.find((x) => x.id === formik.values.pharmacy)?.name !==
    'DivvyDOSE';

  return (
    <FieldArray name="medEvents">
      {({ remove, push }) => (
        <React.Fragment>
          {props.templateRemoteDate ? (
            <Typography variant="caption">
              Schedule copied from{' '}
              {props.templateRemoteDate?.toLocaleString(DateTime.DATE_MED)},
              batch Id {props.templateRemoteBatchId}
            </Typography>
          ) : null}
          {medEvents.length > 0 &&
            medEvents.map((_: MedEvent, index: number) => (
              <Card
                variant="outlined"
                key={index}
                sx={{ marginTop: index > 0 ? '20px' : '7px' }}
              >
                <CardHeader
                  action={
                    <IconButton onClick={() => remove(index)}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="xs"
                        title={`Remove med event`}
                      ></FontAwesomeIcon>
                    </IconButton>
                  }
                  subheader={
                    <Grid container justifyContent={'space-evenly'}>
                      <Grid item xs={5}>
                        <TimePicker
                          label="Desired Time"
                          value={medEvents[index].desiredTimeOfDay ?? ''}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `medEvents.${index}.desiredTimeOfDay`,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                getErrors(
                                  formik.errors.medEvents,
                                  index,
                                  'desiredTimeOfDay'
                                )
                              )}
                              helperText={getErrors(
                                formik.errors.medEvents,
                                index,
                                'desiredTimeOfDay'
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          label="Event Type"
                          name={`medEvents.${index}.eventType`}
                          component={SelectFormField}
                          options={Object.keys(MedEventType).map((type) => {
                            return {
                              label: _startCase(
                                type.replaceAll('_', ' ').toLocaleLowerCase()
                              ),
                              value: type,
                            };
                          })}
                        />
                      </Grid>
                    </Grid>
                  }
                />
                <CardContent>
                  <Grid
                    container
                    justifyContent={'space-between'}
                    style={{ marginTop: '10px' }}
                  >
                    <Grid container justifyContent={'space-between'}>
                      <FieldArray name={`medEvents.${index}.pouches`}>
                        {(pHelpers) => (
                          <React.Fragment>
                            {medEvents[index].pouches.length > 0 &&
                              medEvents[index].pouches.map(
                                (_: Pouch, pIndex: number) => (
                                  <Grid
                                    item
                                    key={pIndex}
                                    xs={12}
                                    sx={{ marginTop: '10px' }}
                                  >
                                    <Paper elevation={0} square>
                                      <Grid
                                        container
                                        justifyContent={'space-between'}
                                        columnSpacing={1}
                                        rowSpacing={1}
                                      >
                                        {isFullPharmacyIntegration && (
                                          <Grid item xs={6}>
                                            <Field
                                              label="Pouch Number"
                                              name={`medEvents.${index}.pouches.${pIndex}.pouchNumber`}
                                              placeholder="20"
                                              component={TextInputFormField}
                                              InputProps={{
                                                endAdornment: (
                                                  <IconAdornment
                                                    onClick={() =>
                                                      pHelpers.remove(pIndex)
                                                    }
                                                    faIconProps={{
                                                      icon: faCircleXmark,
                                                      size: 'xs',
                                                      title: `Remove pouch ${
                                                        medEvents[index]
                                                          .pouches[pIndex]
                                                          .pouchNumber
                                                      } from the ${medEvents[
                                                        index
                                                      ].desiredTimeOfDay?.toLocaleString(
                                                        DateTime.TIME_SIMPLE
                                                      )} med event`,
                                                    }}
                                                  />
                                                ),
                                              }}
                                            />
                                          </Grid>
                                        )}

                                        <FieldArray
                                          name={`medEvents.${index}.pouches.${pIndex}.drugs`}
                                        >
                                          {(dHelpers) => (
                                            <React.Fragment>
                                              <Grid item xs={6}>
                                                <Button
                                                  variant="contained"
                                                  color="secondary"
                                                  fullWidth
                                                  title={`Add medication for pouch ${medEvents[index].pouches[pIndex].pouchNumber}`}
                                                  onClick={() =>
                                                    dHelpers.push({
                                                      ndc: '',
                                                      rollPackaged: true,
                                                      isPrn: false,
                                                    })
                                                  }
                                                >
                                                  Add medication
                                                </Button>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <List
                                                  sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                  }}
                                                  component="span"
                                                  aria-labelledby="nested-list-subheader"
                                                >
                                                  {medEvents[index].pouches[
                                                    pIndex
                                                  ].drugs.length > 0 &&
                                                    medEvents[index].pouches[
                                                      pIndex
                                                    ].drugs.map(
                                                      (
                                                        _: Drug,
                                                        dIndex: number
                                                      ) => (
                                                        <ListItem
                                                          key={dIndex}
                                                          sx={{
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                          }}
                                                        >
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              key={`medEvents.${index}.pouches.${pIndex}.drugs`}
                                                            >
                                                              <Box
                                                                display={
                                                                  'block'
                                                                }
                                                              >
                                                                <ListItemText
                                                                  primary={
                                                                    <Field
                                                                      label="Medication"
                                                                      name={`medEvents.${index}.pouches.${pIndex}.drugs.${dIndex}.ndc`}
                                                                      placeholder="Medication"
                                                                      component={
                                                                        SelectFormField
                                                                      }
                                                                      options={meds.map(
                                                                        (
                                                                          med
                                                                        ) => {
                                                                          return {
                                                                            label:
                                                                              med.name,
                                                                            value:
                                                                              med.national_drug_code,
                                                                          };
                                                                        }
                                                                      )}
                                                                      InputProps={{
                                                                        endAdornment:
                                                                          (
                                                                            <IconAdornment
                                                                              onClick={() =>
                                                                                dHelpers.remove(
                                                                                  dIndex
                                                                                )
                                                                              }
                                                                              faIconProps={{
                                                                                icon: faCircleXmark,
                                                                                size: 'xs',
                                                                                title: `Remove medication from pouch ${medEvents[index].pouches[pIndex].pouchNumber}`,
                                                                              }}
                                                                            />
                                                                          ),
                                                                      }}
                                                                    />
                                                                  }
                                                                  //secondary="20-23-23232"
                                                                />
                                                              </Box>
                                                              <Box
                                                                display={
                                                                  'inline'
                                                                }
                                                              >
                                                                <FormGroup>
                                                                  <FormControlLabel
                                                                    control={
                                                                      <Checkbox
                                                                        checked={
                                                                          medEvents[
                                                                            index
                                                                          ]
                                                                            .pouches[
                                                                            pIndex
                                                                          ]
                                                                            .drugs[
                                                                            dIndex
                                                                          ]
                                                                            .rollPackaged
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) => {
                                                                          formik.setFieldValue(
                                                                            `medEvents.${index}.pouches.${pIndex}.drugs.${dIndex}.rollPackaged`,
                                                                            event
                                                                              .target
                                                                              .checked
                                                                          );
                                                                        }}
                                                                        inputProps={{
                                                                          'aria-label':
                                                                            'controlled',
                                                                        }}
                                                                      />
                                                                    }
                                                                    label="In Pouch"
                                                                  />
                                                                  <FormControlLabel
                                                                    control={
                                                                      <Checkbox
                                                                        checked={
                                                                          medEvents[
                                                                            index
                                                                          ]
                                                                            .pouches[
                                                                            pIndex
                                                                          ]
                                                                            .drugs[
                                                                            dIndex
                                                                          ]
                                                                            .isPrn
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) => {
                                                                          formik.setFieldValue(
                                                                            `medEvents.${index}.pouches.${pIndex}.drugs.${dIndex}.isPrn`,
                                                                            event
                                                                              .target
                                                                              .checked
                                                                          );
                                                                        }}
                                                                        inputProps={{
                                                                          'aria-label':
                                                                            'controlled',
                                                                        }}
                                                                      />
                                                                    }
                                                                    label="PRN"
                                                                  />
                                                                </FormGroup>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </ListItem>
                                                      )
                                                    )}
                                                </List>
                                              </Grid>
                                            </React.Fragment>
                                          )}
                                        </FieldArray>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                )
                              )}

                            <Grid item xs={4}></Grid>
                            {isFullPharmacyIntegration && (
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    pHelpers.push({
                                      pouchNumber:
                                        medEvents.length &&
                                        medEvents[0].pouches.length
                                          ? Math.min(
                                              ...medEvents
                                                .map((m) =>
                                                  m.pouches.map((p) =>
                                                    Number(p.pouchNumber)
                                                  )
                                                )
                                                .flat()
                                            ) - 1
                                          : '',
                                      drugs: [
                                        {
                                          ndc: '',
                                        } as Drug,
                                      ],
                                    })
                                  }
                                >
                                  Add new pouch
                                </Button>
                              </Grid>
                            )}
                          </React.Fragment>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          <Button variant="outlined" onClick={() => push(defaultMedEvent)}>
            Add MedEvent
          </Button>
        </React.Fragment>
      )}
    </FieldArray>
  );
};

const defaultMedEvent = {
  eventType: MedEventType.SMART_ASSIST,
  pouches: [
    {
      pouchNumber: '',
      drugs: [
        {
          ndc: '',
          rollPackaged: true,
          isPrn: false,
        },
      ],
    },
  ],
} as MedEvent;

MedicationRollMedEventsInformation.label = 'Confirm daily medication events';
MedicationRollMedEventsInformation.initialValues = {
  medEvents: [defaultMedEvent],
};

MedicationRollMedEventsInformation.validationSchema = Yup.object({
  medEvents: Yup.array(
    Yup.object().shape({
      desiredTimeOfDay: Yup.date().required('Required'),
    })
  )
    .min(1)
    .required('Required'),
});

export default MedicationRollMedEventsInformation;
