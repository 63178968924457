import { Button, Typography } from '@mui/material';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableOptions } from '../../../shared/tableUtils';
import { RootState } from '../../../store';
import {
  getDeviceLogByUrl,
  clearLog,
} from '../../../store/devices/actionCreators';
import { DeviceLog, Log } from '../../../store/devices/deviceTypes';
import AuthenticatedLink from '../../AuthenticatedLink';
import DispenserStatisticsTable from './DispenserStatisticsTable';
import LogView from './LogView';

interface DeviceLogsTableProps {
  logs: Log[];
}

const DeviceLogsTable = (props: DeviceLogsTableProps) => {
  const { logs } = props;

  const { currentLog = [] } = useSelector<
    RootState,
    {
      currentLog: DeviceLog[] | null;
    }
  >((state) => {
    const { devices } = state;
    const { currentLog } = devices;
    return {
      currentLog,
    };
  });

  const dispatch = useDispatch();

  const [showMetrics, setMetricsVisibility] = useState<number | null>(null);
  const [showLogView, setShowLogView] = useState(false);

  const openLogView = (url: string) => {
    dispatch(getDeviceLogByUrl(new URL(url)));
    setShowLogView(true);
  };

  const closeLogView = () => {
    setShowLogView(false);
    dispatch(clearLog());
  };

  const closeMetrics = () => {
    setMetricsVisibility(null);
    dispatch(clearLog());
  };

  const columns: Column<Log>[] = [
    { title: 'Log ID', field: 'id', defaultSort: 'desc' },
    {
      title: 'Log note',
      field: 'note',
    },
    {
      title: 'Requested by',
      field: 'email',
    },
    {
      title: 'Uploaded at',
      field: 'uploaded_at',
      type: 'datetime',
    },
    {
      title: 'Download',
      field: 'download',
      sorting: false,
      render: function DownloadLink(rowData) {
        return (
          <AuthenticatedLink
            url={new URL(rowData.url)}
            fileName={`omcare_log_${rowData.id}.txt`}
          >
            <Button variant="contained" color="secondary">
              Download
            </Button>
          </AuthenticatedLink>
        );
      },
    },
    {
      title: 'Open log view',
      field: 'open',
      sorting: false,
      render: function ViewLogButton(rowData) {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => openLogView(rowData.url)}
          >
            View log
          </Button>
        );
      },
    },
    {
      title: 'Telemetry',
      field: 'open',
      sorting: false,
      render: function ViewMetricsButton(rowData) {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setMetricsVisibility(rowData.id)}
          >
            View metrics
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant={'h1'}>Logs</Typography>
      <MaterialTable
        columns={columns}
        data={logs}
        options={{
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          ...(TableOptions as Partial<Options<Log>>),
        }}
      />
      {showLogView && currentLog ? (
        <LogView handleClose={() => closeLogView()} logs={currentLog} />
      ) : null}
      {showMetrics ? (
        <DispenserStatisticsTable
          onClose={() => closeMetrics()}
          logId={showMetrics}
        />
      ) : null}
    </>
  );
};

export default DeviceLogsTable;
