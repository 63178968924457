import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const Wrapper = styled('div')(() => ({
  background: '#000',
  opacity: 0.5,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999,
}));

const CustomProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: '50vw',
  top: '50vh',
  margin: '-50px 0 0 -50px',
  zIndex: 15000,
  color: 'white',
}));

function LoadingSpinner() {
  return (
    <>
      <Wrapper />
      <CustomProgress size={100} />
    </>
  );
}

export default LoadingSpinner;
