import MaterialTable, { Column, Options } from '@material-table/core';
import { Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  sortByFullName,
  StatusChip,
  TableOptions,
} from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getDeviceList } from '../../store/devices/actionCreators';
import { Device } from '../../store/devices/deviceTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';

function Devices() {
  const { deviceList, connected } = useSelector<
    RootState,
    { deviceList: Device[] | null; connected: boolean }
  >((state) => {
    const { devices, webSocket } = state;
    const { deviceList } = devices;
    const { connected } = webSocket;
    return {
      deviceList,
      connected,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deviceList) {
      dispatch(getDeviceList());
    }
  }, [connected, deviceList, dispatch]);

  const columns: Column<Device>[] = [
    {
      title: 'Serial number',
      field: 'serial_number',
    },
    {
      title: 'Owner',
      field: 'owner_name',
      customSort: sortByFullName,
    },
    {
      title: 'Synced By',
      field: 'synced_by_customer_name',
    },
    { title: 'HW version', field: 'hardware_version' },
    { title: 'SW version', field: 'software_version' },
    {
      title: 'Status',
      field: 'status',
      render: StatusChip,
      emptyValue: '',
    },
  ];

  const onRowClick = (
    _: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: Device | undefined
  ) => {
    if (rowData) {
      window.open(
        `${window.location.protocol}//${window.location.host}/device/${rowData.serial_number}`,
        '_self'
      );
    }
  };

  if (isNull(deviceList)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Devices</title>
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant={'h1'}>Device list</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={columns}
          data={deviceList.sort(sortByFullName)}
          onRowClick={onRowClick}
          options={{
            pageSize: 150,
            pageSizeOptions: [150, 250, 350],
            ...(TableOptions as Partial<Options<Device>>),
          }}
        />
      </Wrapper>
    </Container>
  );
}

export default Devices;
