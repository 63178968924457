import { TabSchema } from '../GlobalTypes';

export interface MyAccountStore {
  selectedTab: TabSchema;
}

export enum MyAccountActionTypes {
  MY_ACCOUNT_SIDE_BAR_TAB_SELECTION = 'MY_ACCOUNT_SIDE_BAR_TAB_SELECTION',
  MY_ACCOUNT_API_ERR = 'MY_ACCOUNT_API_ERR',
}

export interface MyAccountAPIErrorAction {
  type: MyAccountActionTypes.MY_ACCOUNT_API_ERR;
}

export interface MyAccountSelectSidebarTabAction {
  type: MyAccountActionTypes.MY_ACCOUNT_SIDE_BAR_TAB_SELECTION;
  payload: { selectedTab: TabSchema };
}

export type MyAccountAction =
  | MyAccountAPIErrorAction
  | MyAccountSelectSidebarTabAction;
