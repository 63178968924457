import { Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import { getPharmacies } from '../../store/pharmacy/actionCreators';
import { Pharmacy } from '../../store/pharmacy/pharmacyTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { HeaderWrapper } from '../Shared/HeaderWrapper';

function Pharmacies() {
  const { pharmacies } = useSelector<
    RootState,
    {
      pharmacies: Pharmacy[] | null;
    }
  >((state) => {
    const { pharmacy } = state;
    const { pharmacies } = pharmacy;
    return {
      pharmacies,
    };
  });

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (isNull(pharmacies)) {
      dispatch(getPharmacies());
    }
  }, [pharmacies, dispatch]);

  const columns: Column<Pharmacy>[] = [
    { title: 'Name', field: 'name', defaultSort: 'asc' },
    {
      title: 'Created at',
      field: 'created_at',
      type: 'datetime',
    },
  ];

  const onRowClick = (
    _e: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: Pharmacy | undefined
  ) => {
    if (rowData) {
      history.push('/pharmacies/' + rowData.id);
    }
  };

  if (isNull(pharmacies)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Pharmacies</title>
      </Helmet>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          <Typography variant={'h1'}>Pharmacies</Typography>
        </HeaderWrapper>
        <MaterialTable
          columns={columns}
          data={pharmacies}
          onRowClick={onRowClick}
          options={{
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            ...(TableOptions as Partial<Options<Pharmacy>>),
          }}
        />
      </div>
    </Container>
  );
}

export default Pharmacies;
