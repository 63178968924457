import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Pagination,
  IconButton,
  styled,
} from '@mui/material';
import { isNil } from 'lodash';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Device } from '../../../store/devices/deviceTypes';

const CustomIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '2%',
  top: '1%',
}));

interface LinkDevicesModalProps {
  devices: Device[];
  onClose: () => void;
  onConfirm: (stagedDevices: Device[]) => void;
}

const LinkDevicesModal: React.FC<LinkDevicesModalProps> = ({
  devices,
  onClose,
  onConfirm,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(10); // You can adjust the number of devices per page here
  const [stagedDevices, setStagedDevices] = useState<Device[]>([]);
  const [confirmationStep, setConfirmationStep] = useState(false);

  const filteredDevices = devices.filter(
    (device) =>
      device.serial_number.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isNil(device.sync_code_done_by)
  );

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handleCheckboxChange = (device: Device, checked: boolean) => {
    if (checked) {
      setStagedDevices((prev) => [...prev, device]);
    } else {
      setStagedDevices((prev) => prev.filter((d) => d.id !== device.id));
    }
  };

  const renderContent = () => {
    if (confirmationStep) {
      return (
        <>
          <List>
            {stagedDevices.map((device) => (
              <ListItem key={device.id}>
                <ListItemText secondary={device.serial_number} />
              </ListItem>
            ))}
          </List>
          <DialogActions>
            <Button
              onClick={() => setConfirmationStep(false)}
              variant="outlined"
              color="secondary"
            >
              Back
            </Button>
            <Button
              onClick={() => onConfirm(stagedDevices)}
              variant="contained"
              color="primary"
              disabled={stagedDevices.length === 0}
            >
              Complete
            </Button>
          </DialogActions>
        </>
      );
    } else {
      return (
        <>
          <TextField
            placeholder="Search by Serial Number"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <List>
            {currentDevices.map((device) => (
              <ListItem key={device.id}>
                <ListItemIcon>
                  <Checkbox
                    checked={stagedDevices.some((d) => d.id === device.id)}
                    onChange={(e) =>
                      handleCheckboxChange(device, e.target.checked)
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={device.serial_number} />
              </ListItem>
            ))}
          </List>
          <Pagination
            count={Math.ceil(filteredDevices.length / devicesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
          <DialogActions>
            <Button onClick={onClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => setConfirmationStep(true)}
              variant="contained"
              color="primary"
              disabled={stagedDevices.length === 0}
            >
              Add
            </Button>
          </DialogActions>
        </>
      );
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {confirmationStep ? 'Review and Confirm' : 'Add Devices'}
        <CustomIconButton
          aria-label="close"
          onClick={onClose}
          color="primary"
          sx={{ marginRight: '4px' }}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </CustomIconButton>
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};

export default LinkDevicesModal;
