/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PharmacyTechService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns HTML page to submit batch files
   * @returns any Success
   * @throws ApiError
   */
  public sendPharmacyTechHtmlPage(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/pharmacy_tech',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Upload batch files to Omcare Server
   * @param formData
   * @returns any Successfully Uploaded
   * @throws ApiError
   */
  public uploadBatchFile(formData?: {
    batch_file?: Blob;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/pharmacy_tech',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Wrong permission`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
