import { Typography } from '@mui/material';
import React from 'react';

interface ReportingDashboardProps {
  title: string;
}

function ReportingDashboard(props: ReportingDashboardProps) {
  const { title } = props;
  return (
    <div>
      <Typography variant={'h1'}>{title}</Typography>
    </div>
  );
}

export default ReportingDashboard;
