import React, { useState } from 'react';
import MultiProgress from 'react-multi-progress';
import { List, ListItem, ListItemText, Grid } from '@mui/material';

type Props = {
  doses: any;
  setRowHeight: (height: number) => void;
};

interface Dose {
  status: string; //'missed' | 'taken' | 'upcoming' | 'invalidated' | 'active';
  // other properties
}

/**
 * Gets the status with the largest count from an object of status counts.
 *
 * @param {Record<string, number>} statusCounts - An object where keys are statuses and values are their counts.
 * @returns {{ status: string; count: number; }} - An object with the status having the largest count and its count.
 *                                                  If `statusCounts` is empty, returns an object with `status` as
 *                                                  an empty string and `count` as 0.
 *
 * @example
 * let statusCounts = { active: 10, missed: 20, upcoming: 15 };
 * let maxStatus = getStatusWithLargestCount(statusCounts);
 * console.log(maxStatus); // Output: { status: 'missed', count: 20 }
 */
function getStatusWithLargestCount(statusCounts: Record<string, number>) {
  return Object.entries(statusCounts).reduce(
    (maxStatus, [status, count]) => {
      return count > maxStatus.count ? { status, count } : maxStatus;
    },
    { status: '', count: 0 }
  );
}

function countStatuses(doses: Dose[]) {
  return doses.reduce((acc, dose) => {
    acc[dose.status] = (acc[dose.status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
}

function mapStatusCountsToColorArray(statusCounts: Record<string, number>) {
  const totalCount = Object.values(statusCounts).reduce(
    (acc, val) => acc + val,
    0
  );

  return Object.entries(statusCounts).map(([status, count]) => ({
    value: (count / totalCount) * 100,
    color: statusColors[status],
  }));
}

const statusColors: Record<string, string> = {
  missed: 'red',
  taken: 'green',
  complete: 'green',
  upcoming: 'blue',
  invalidated: 'yellow',
  active: 'purple',
};

const DosesCell = (props: Props) => {
  const doses = props.doses;

  const statusCounts = countStatuses(doses);
  let maxStatus = getStatusWithLargestCount(statusCounts);

  const [showList, setShowList] = useState(false); // Add a new state variable to control the visibility of the List

  const handleGridClick = () => {
    props.setRowHeight(showList ? 52 : doses.length * 75 + 100);
    setShowList(!showList); // Toggle the visibility of the List when the Grid is clicked
  };

  return (
    <Grid
      container
      columnSpacing={1}
      onClick={handleGridClick}
      style={{ cursor: 'pointer' }}
    >
      <Grid item xs={6}>
        <MultiProgress
          elements={mapStatusCountsToColorArray(statusCounts)}
        ></MultiProgress>
      </Grid>
      <Grid item xs={6}>
        <span>{`${maxStatus.count} of ${doses.length} ${maxStatus.status}`}</span>
      </Grid>

      {showList && ( // Conditionally render the List based on the value of showList
        <Grid item xs={12}>
          <List dense={true}>
            {doses.map((dose: any) => (
              <ListItem key={dose.id}>
                <ListItemText
                  primary={`${dose.is_prn ? '[As Needed] | ' : ''}${
                    dose.drug_name
                  }`}
                  secondary={`${dose.status} (${dose.last_received_event}) | ${
                    dose.batch_id
                  } - ${dose.pouch_number} | ${
                    dose.roll_packaged ? 'In Pouch' : 'Out of Pouch'
                  }`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default DosesCell;
