import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCareTeamDetails,
  clearCareTeamDetails,
  getFacilityCustomers,
  getCustomerList,
} from '../../../store/customers/actionCreators';
import CaregiverDetailView from './CaregiverDetailView';
import { Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { RootState } from '../../../store';
import { Careteam } from '../../../store/customers/customerTypes';
import { getPrivilegeById } from '../../../shared/utils';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';

const CareTeamDetails = () => {
  const dispatch = useDispatch();

  const { dashboardUserInfo, careTeamDetails, connected } = useSelector<
    RootState,
    {
      dashboardUserInfo: DashboardUser | null;
      careTeamDetails: Careteam | null;
      connected: boolean;
    }
  >((state) => {
    const { dashboardUser, customers, webSocket } = state;
    const { dashboardUserInfo } = dashboardUser;
    const { careTeamDetails } = customers;
    const { connected } = webSocket;
    return {
      dashboardUserInfo,
      careTeamDetails,
      connected,
    };
  });

  const { care_team_id = '' } = useParams<{ care_team_id: string }>();

  useEffect(() => {
    if (dashboardUserInfo) {
      if (
        getPrivilegeById(dashboardUserInfo.privilege_id) === 'customer_care'
      ) {
        dispatch(getCustomerList());
      } else {
        dispatch(getFacilityCustomers(dashboardUserInfo.facility_id ?? 0));
      }

      dispatch(getCareTeamDetails(Number(care_team_id)));
      return () => {
        dispatch(clearCareTeamDetails());
      };
    }
  }, [connected, dispatch, dashboardUserInfo, care_team_id]);

  if (isNull(careTeamDetails)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant={'h1'}>Account details</Typography>
      <CaregiverDetailView customer={careTeamDetails} title="" />
    </Container>
  );
};

export default CareTeamDetails;
