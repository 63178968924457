import axios from 'axios';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { restHost } from '../../apiConfig';
import { deleteToken, formatUrl, getCookie } from '../../shared/utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  AdherenceDataPayload,
  BasicSelectedValue,
  FilterValues,
  FilterValuesPayload,
  NewFilter,
  RemoveFilter,
  ReportingStore,
  RequiredFilterValues,
  SelectedFilter,
  SelectedValue,
  ReportingStoreActionTypes,
  DateRangeValue,
  AgeRangeValue,
  MedAdminTimeValue,
} from './reportingTypes';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TabSchema } from '../GlobalTypes';

export const apiError = (error: any) => ({
  type: ReportingStoreActionTypes.REPORTING_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: ReportingStoreActionTypes.REPORTING_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const sideBarTabSelection = (selectedTab: TabSchema) => ({
  type: ReportingStoreActionTypes.REPORTING_SIDE_BAR_TAB_SELECTION,
  payload: { selectedTab },
});

function handleNewFilter(data: NewFilter) {
  let selectedValues: SelectedValue[] = [];
  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((element: BasicSelectedValue) => {
      selectedValues.push(element);
    });
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    });
  }
  const newSelectedFilters = [
    {
      filterValue: data.selectedFilter.value,
      filterDisplay: data.selectedFilter.display,
      selectedValues: selectedValues,
    },
  ];
  return {
    type: ReportingStoreActionTypes.BUILD_FILTER_EVENT,
    payload: {
      selectedFilters: data.selectedFilters.concat(newSelectedFilters),
    },
  };
}

function handleAlreadySelectedFilter(data: NewFilter) {
  const deepCopy = cloneDeep(data.selectedFilters);
  const selectedValues: SelectedValue[] = [];
  const reportDenominatorAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'groupBy'
  );
  const reportDenominatorAdded = data.selectedFilter.value === 'groupBy';
  if (reportDenominatorAlreadySelected && reportDenominatorAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'groupBy')[0]
      .selectedValues.shift();
  }

  const dateRangeAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'datePicker'
  );
  const dateRangeAdded = data.selectedFilter.value === 'datePicker';
  if (dateRangeAlreadySelected && dateRangeAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'datePicker')[0]
      .selectedValues.shift();
  }

  const ownerAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'owner'
  );
  const ownerAdded = data.selectedFilter.value === 'owner';
  if (ownerAlreadySelected && ownerAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'owner')[0]
      .selectedValues.shift();
  }

  const ageRangeAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'ageRange'
  );
  const ageRangeAdded = data.selectedFilter.value === 'ageRange';
  if (ageRangeAlreadySelected && ageRangeAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'ageRange')[0]
      .selectedValues.shift();
  }

  const medAdminTimeAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'medAdminTime'
  );
  const medAdminTimeAdded = data.selectedFilter.value === 'medAdminTime';
  if (medAdminTimeAlreadySelected && medAdminTimeAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'medAdminTime')[0]
      .selectedValues.shift();
  }

  const eventTypeAlreadySelected = deepCopy.find(
    (item: SelectedFilter) => item.filterValue === 'eventType'
  );
  const eventTypeAdded = data.selectedFilter.value === 'eventType';
  if (eventTypeAlreadySelected && eventTypeAdded) {
    deepCopy
      .filter((item: SelectedFilter) => item.filterValue === 'eventType')[0]
      .selectedValues.shift();
  }

  if (Array.isArray(data.selectedFilterValue)) {
    data.selectedFilterValue.forEach((item: SelectedValue) => {
      selectedValues.push(item);
    });
  } else {
    selectedValues.push({
      display: data.selectedFilterValue.display,
      value: data.selectedFilterValue.value,
    });
  }
  let oldSelectedValue = deepCopy.filter(
    (item: SelectedFilter) => item.filterValue === data.selectedFilter.value
  )[0].selectedValues;
  selectedValues.forEach((item) => {
    oldSelectedValue.push(item);
  });
  deepCopy
    .filter(
      (item: SelectedFilter) => item.filterValue === data.selectedFilter.value
    )[0]
    .selectedValues.concat(selectedValues);
  return {
    type: ReportingStoreActionTypes.BUILD_FILTER_EVENT,
    payload: {
      selectedFilters: deepCopy,
    },
  };
}

export function buildFilterAction(data: NewFilter) {
  const alreadySelectedFilters = data.selectedFilters.filter(
    (item: SelectedFilter) => item.filterValue === data.selectedFilter.value
  );
  if (alreadySelectedFilters.length > 0) {
    // If filter already selected and different value selected.
    let isDuplicateFilterTypeFound = false;
    if (Array.isArray(data.selectedFilterValue)) {
      data.selectedFilterValue.forEach((item: BasicSelectedValue) => {
        isDuplicateFilterTypeFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val) => val.value === item.value
          ).length === 0;
      });
    } else {
      if (Array.isArray(data.selectedFilterValue)) {
        isDuplicateFilterTypeFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val: SelectedValue) =>
              (data.selectedFilterValue as BasicSelectedValue[]).filter(
                (filter) => val.display === filter.display
              )
          ).length === 0;
      } else {
        isDuplicateFilterTypeFound =
          alreadySelectedFilters[0].selectedValues.filter(
            (val: SelectedValue) =>
              val.display ===
              (data.selectedFilterValue as BasicSelectedValue).display
          ).length === 0;
      }
    }
    if (isDuplicateFilterTypeFound) {
      // Keeping this for when/if we allow multiple age ranges
      // if (data.selectedFilter.value === 'ageRange') {
      //   let existingAgeRanges = cloneDeep(
      //     data.selectedFilters.find(
      //       (filter) => filter.filterValue === 'ageRange'
      //     )
      //   ).selectedValues.map((age) => age.display.split('-').map((x) => +x))

      //   let newAgeRanges = cloneDeep(
      //     data.selectedFilterValue.display.split('-').map((x) => +x)
      //   )

      //   let newOverlapsOld = false
      //   existingAgeRanges.forEach(
      //     (age) =>
      //       (newOverlapsOld =
      //         age[0] <= newAgeRanges[1] && age[1] >= newAgeRanges[0])
      //   )
      //   if (newOverlapsOld) {
      //     return () => {
      //       toast.info(
      //         'You have attempted to add an age range that overlaps an existing age range.'
      //       )
      //     }
      //   }
      // }
      return (dispatch: Dispatch) => {
        dispatch(handleAlreadySelectedFilter(data));
      };
    } else {
      return (dispatch: Dispatch) => {
        dispatch(setLoadState(false));
        toast.info('You have attempted to add duplicate filters');
      };
    }
  } else {
    return (dispatch: Dispatch) => {
      dispatch(handleNewFilter(data));
    };
  }
}

function getAdherenceDataSuccess(data: AdherenceDataPayload | null) {
  return {
    type: ReportingStoreActionTypes.RECIEVE_ADHERENCE_DATA,
    payload: {
      adherenceData: data,
    },
  };
}
const generateQueryParamsForAdherence = (searchQuery: SelectedFilter[]) => {
  const queryParam: Partial<FilterValuesPayload> = {};
  searchQuery.forEach((item: SelectedFilter) => {
    if (item.filterValue === 'groupBy') {
      queryParam.group_by = item.selectedValues[0].value as string;
    }
    if (item.filterValue === 'datePicker') {
      queryParam.from_date = String(
        (item.selectedValues[0].value as DateRangeValue).fromDate
      );
      queryParam.to_date = String(
        (item.selectedValues[0].value as DateRangeValue).toDate
      );
    } else if (item.filterValue === 'ageRange') {
      queryParam.start_age = (
        item.selectedValues[0].value as AgeRangeValue
      ).fromAge;
      queryParam.end_age = (
        item.selectedValues[0].value as AgeRangeValue
      ).toAge;
    } else if (item.filterValue === 'gender') {
      queryParam.gender = item.selectedValues.map(
        (filter: SelectedValue) => filter.value
      ) as string[];
    } else if (item.filterValue === 'eventType') {
      queryParam.event_type_id = Number(item.selectedValues[0].value as string);
    } else if (item.filterValue === 'state') {
      queryParam.state = item.selectedValues.map(
        (filter: SelectedValue) => filter.value
      ) as string[];
    } else if (item.filterValue === 'zipCodes') {
      queryParam.zip_code = item.selectedValues.map(
        (filter: SelectedValue) => filter.value
      ) as string[];
    } else if (item.filterValue === 'pharmacyId') {
      queryParam.pharmacy_name = item.selectedValues.map(
        (filter: SelectedValue) => filter.display
      );
    } else if (item.filterValue === 'facilityId') {
      queryParam.facility_name = item.selectedValues.map(
        (filter: SelectedValue) => filter.display
      );
    } else if (item.filterValue === 'medAdminTime') {
      queryParam.medAdmin_from_time = (
        item.selectedValues[0].value as MedAdminTimeValue
      ).fromTime;
      queryParam.medAdmin_to_time = (
        item.selectedValues[0].value as MedAdminTimeValue
      ).toTime;
    } else if (item.filterValue === 'ownerIds') {
      queryParam.owner_ids = item.selectedValues.map((filter: SelectedValue) =>
        Number(filter.value)
      );
    }
  });

  return queryParam;
};

export function getAdherenceData(searchQuery: SelectedFilter[]) {
  const queryParam = generateQueryParamsForAdherence(searchQuery);
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    dispatch(getAdherenceDataSuccess(null));
    return axios
      .post(formatUrl(restHost, 'reporting/adherence'), queryParam, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(getAdherenceDataSuccess(res.data));
        dispatch(setLoadState(false));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          deleteToken();
        }
        dispatch(setLoadState(false));
        toast.error(error.message);
      });
  };
}

export function removeFilterEvent(data: RemoveFilter) {
  let deepCopy = cloneDeep(data.selectedFilters);
  const currentFilter = deepCopy.filter(
    (item: SelectedFilter) => item.filterValue === data.mainFilterValue
  )[0];
  currentFilter.selectedValues = currentFilter.selectedValues.filter(
    (item) => !isEqual(item.value, data.filterValue)
  );
  deepCopy = deepCopy.filter(
    (item: SelectedFilter) => item.selectedValues.length >= 1
  );
  if (currentFilter.selectedValues.length === 0 && deepCopy.length <= 0) {
    deepCopy = deepCopy.filter(
      (item) => item.filterValue !== data.mainFilterValue
    );
  }
  return {
    type: ReportingStoreActionTypes.REMOVE_FILTER_EVENT,
    payload: {
      selectedFilters: deepCopy,
      selectedFilter: data.selectedFilter,
      requiredFilterValues: data.requiredFilterValues,
    },
  };
}

export function removeFilterHandler(data: RemoveFilter) {
  return (
    dispatch: ThunkDispatch<ReportingStore, void, AnyAction> & Dispatch
  ) => {
    const filter = dispatch(removeFilterEvent(data));
    // only refresh data if filter exists
    if (!isEmpty(filter.payload.selectedFilters)) {
      dispatch(
        getAdherenceData([
          ...filter.payload.selectedFilters,
          ...data.facilityFilter,
        ])
      );
    }
  };
}

export function clearFilters() {
  return {
    type: ReportingStoreActionTypes.REMOVE_FILTER_EVENT,
    payload: {
      selectedFilters: [],
      selectedFilter: '',
      requiredFilterValues: {
        groupBy: '',
        dateRange: '',
        fromDate: '',
        toDate: '',
      },
    },
  };
}

export function changeFilterEvent(selectedFilter: string) {
  return {
    type: ReportingStoreActionTypes.CHANGE_FILTER_EVENT,
    payload: {
      selectedFilter: selectedFilter,
    },
  };
}

export function selectFilterValueEvent(filterValues: Partial<FilterValues>) {
  return {
    type: ReportingStoreActionTypes.SELECT_FILTER_VALUE_EVENT,
    payload: {
      filterValues: filterValues,
    },
  };
}

export function selectRequiredFilterValueEvent(
  data: Partial<RequiredFilterValues>
) {
  return {
    type: ReportingStoreActionTypes.SELECT_REQUIRED_FILTER_VALUE_EVENT,
    payload: {
      groupBy: data.groupBy,
      dateRange: data.dateRange,
      fromDate: data.fromDate,
      toDate: data.toDate,
    },
  };
}

export function downloadAdherenceReport(searchQuery: SelectedFilter[]) {
  const queryParam = generateQueryParamsForAdherence(searchQuery);

  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'reporting/adherence/download'), queryParam, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let currentDate = moment().format('MM/DD/YYYY h:mm:ss a');
        link.setAttribute('download', `AdherenceReport_${currentDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadState(false));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          deleteToken();
        }
        dispatch(setLoadState(false));
        toast.error(error.message);
      });
  };
}
