import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TabSchema } from '../../store/GlobalTypes';

const drawerWidth = 301;

interface SideTabsProps {
  sideBarTabSelection(selectedTab: TabSchema): void;
  selectedTab: TabSchema;
  tabs: { [key: string]: { text: string; icon: IconDefinition | null } };
}

function SideTabs(props: SideTabsProps) {
  const { sideBarTabSelection, selectedTab, tabs } = props;
  const dispatch = useDispatch();

  return (
    <Drawer
      sx={{ width: drawerWidth, flexShrink: 0 }}
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          width: drawerWidth,
        },
      }}
    >
      <Toolbar />
      <List component="nav" disablePadding>
        {Object.keys(tabs).map((tab) => {
          const icon = tabs[tab].icon;
          return (
            <ListItem
              button
              divider
              selected={isEqual(selectedTab, tabs[tab])}
              sx={{
                width: '300px',
                height: '75px',
                textAlign: 'left',
                paddingLeft: '50px',
              }}
              onClick={() => dispatch(sideBarTabSelection(tabs[tab]))}
              key={tab}
            >
              <ListItemText>
                <div style={{ display: 'inline-flex' }}>
                  {icon ? (
                    <FontAwesomeIcon
                      icon={icon}
                      fixedWidth
                      style={{
                        fontSize: '35px',
                        marginRight: '17px',
                      }}
                    />
                  ) : null}{' '}
                  <span
                    style={{
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '1px',
                    }}
                  >
                    {tabs[tab].text}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}

export default SideTabs;
