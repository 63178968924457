/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of med dose events
 */
export enum MedicationDoseEventType {
  PRESCRIBED = 'prescribed',
  LOADED = 'loaded',
  UNLOADED = 'unloaded',
  DISPENSED = 'dispensed',
  BAG_REMOVED = 'bag_removed',
  SKIPPED = 'skipped',
  ADMINISTERED = 'administered',
  INVALIDATED = 'invalidated',
}
