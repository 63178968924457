import axios from 'axios';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { restHost, storageHost } from '../../apiConfig';
import { deleteToken, formatUrl, getCookie } from '../../shared/utils';
import {
  WebSocketStoreActionTypes,
  WSName,
  WSSource,
  WSType,
} from '../webSocket/webSocketTypes';
import {
  Device,
  DeviceActionTypes,
  DeviceLog,
  DevicesStore,
  DeviceStatus,
  DeviceSyncPayload,
  Log,
  SeamImage,
  ShadowConfig,
  StoreDeviceLogs,
  BBImageSetMetadata,
} from './deviceTypes';

export const populateDevices = (deviceList: Device[]) => ({
  type: DeviceActionTypes.POPULATE_DEVICES,
  payload: { deviceList },
});

export const clearDeviceList = () => ({
  type: DeviceActionTypes.CLEAR_DEVICE_LIST,
});

export const updateDeviceStatus = (
  deviceSerialNumber: string,
  deviceStatus: DeviceStatus
) => ({
  type: DeviceActionTypes.UPDATE_DEVICE_STATUS,
  payload: { deviceSerialNumber, deviceStatus },
});

export const getDeviceList = (
  facility_assigned?: boolean,
  owner_assigned?: boolean
) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `devices`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        params: {
          facility_assigned,
          owner_assigned,
        },
      })
      .then((res) => {
        dispatch(setLoadState(false));
        dispatch(populateDevices(res.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          deleteToken();
        }
        console.error(err);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load device log');
      });
  };
};

export const syncDevice = (data: DeviceSyncPayload) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.FACILITY,
      type: WSType.COMMAND,
      name: WSName.SYNC_DEVICE,
      sync_code: data.sync_code,
      patient_customer_id: data.patient_customer_id,
    },
  },
});

export const failureSyncDevice = (error: any) => ({
  type: DeviceActionTypes.FAILURE_SYNC_DEVICE,
  payload: { syncDeviceError: error },
});

export const didSyncDevice = () => ({
  type: DeviceActionTypes.DID_SYNC_DEVICE,
});

export const clearSyncDeviceStatus = () => ({
  type: DeviceActionTypes.CLEAR_SYNC_DEVICE_STATE,
});

export const requestDeviceLog = (serial_number: string, note: string) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.CUSTOMER_CARE,
      type: WSType.COMMAND,
      name: WSName.REQUEST_LOG,
      serial_number: serial_number,
      note: note,
    },
  },
});

export const deviceReboot = (serial_number: string) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.CUSTOMER_CARE,
      type: WSType.COMMAND,
      name: WSName.DEVICE_REBOOT,
      serial_number: serial_number,
    },
  },
});

export const reloadSoftwareVersions = () => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.ENG,
      type: WSType.COMMAND,
      name: WSName.RELOAD_SW_VERSIONS,
    },
  },
});

export const getDeviceLogByUrl = (logUrl: URL) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(logUrl.href, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeLog(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load device log');
      });
  };
};

export const getDeviceLog = (logId: number) => {
  return getDeviceLogByUrl(
    new URL(formatUrl(storageHost, `device/log/${logId}`))
  );
};

export const apiError = (error: any) => ({
  type: DeviceActionTypes.DEVICES_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: DeviceActionTypes.DEVICES_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const storeLog = (log: DeviceLog[]) => ({
  type: DeviceActionTypes.STORE_LOG,
  payload: { log: log },
});

export const clearLog = () => ({
  type: DeviceActionTypes.CLEAR_LOG,
});

export const updateDeviceProperties = (
  device_id: number,
  fields: Partial<Device>
) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, `devices/${device_id}`), fields, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        dispatch(setLoadState(false));
        dispatch(getDeviceList());
        toast.success(`Device updated`);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to update device');
      });
  };
};

export const storeSeamImages = (
  deviceId: number,
  seamImages: {
    [deviceId: string]: SeamImage[];
  }
) => ({
  type: DeviceActionTypes.STORE_SEAM_IMAGES,
  payload: { deviceId, seamImages: seamImages },
});

export const clearSeamImages = () => ({
  type: DeviceActionTypes.CLEAR_SEAM_IMAGES,
});

export const fetchSeamImages = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'devices/' + deviceId + '/dispenser_images'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeSeamImages(deviceId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error loading seam images');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const storeDeviceShadow = (
  deviceId: number,
  shadowConfigs: ShadowConfig[]
) => ({
  type: DeviceActionTypes.STORE_DEVICE_SHADOW,
  payload: { deviceId, shadowConfigs },
});

export const fetchDeviceShadow = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'devices/' + deviceId + '/shadow'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        const shadowState = res.data;
        const configFieldNames =
          shadowState &&
          Array.from(
            new Set([
              ...Object.keys(shadowState.reported),
              ...Object.keys(shadowState.desired),
            ])
          );
        const configs =
          configFieldNames &&
          configFieldNames.map((configName: string) => ({
            configName,
            reportedValue: shadowState.reported[configName],
            desiredValue: shadowState.desired[configName],
          }));
        dispatch(storeDeviceShadow(deviceId, configs));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error getting device shadow');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const clearDeviceShadow = () => ({
  type: DeviceActionTypes.CLEAR_DEVICE_SHADOW,
});

export const updateDeviceShadow = (device_id: number, stateUpdate: any) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, `devices/${device_id}/shadow`), stateUpdate, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        toast.success(`Device Shadow Updated`);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to update device shadow');
      });
  };
};

export const storeDeviceLogs = (
  deviceId: number,
  logs: Log[]
): StoreDeviceLogs => ({
  type: DeviceActionTypes.STORE_DEVICE_LOGS,
  payload: { deviceId, deviceLogs: logs },
});

export const fetchDeviceLogs = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `devices/${deviceId}/logs`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeDeviceLogs(deviceId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error loading seam images');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const storeBBImages = (bbImages: BBImageSetMetadata[]) => ({
  type: DeviceActionTypes.STORE_BB_IMAGES,
  payload: { bbImages },
});

export const getBoundingBoxImages = (startDate?: DateTime) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/bounding_box_images'), {
        params: {
          start_date: startDate ? startDate.toISODate() : undefined,
        },
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setLoadState(false));
        dispatch(storeBBImages(res.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          deleteToken();
        }
        console.error(err);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load bounding box images');
      });
  };
};

export const unassignDevice = (
  deviceId: number,
  serialNumber: string,
  userRole: WSSource
) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: userRole,
      type: WSType.COMMAND,
      name: WSName.FACTORY_RESET,
      device_id: deviceId,
      serial_number: serialNumber,
      access_token: getCookie('access_token'),
    },
  },
});

export const deleteDatabase = (serialNumber: string) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.CUSTOMER_CARE,
      type: WSType.COMMAND,
      name: WSName.DELETE_HUB_DB,
      serial_number: serialNumber,
    },
  },
});
