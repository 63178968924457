import { Reducer } from 'redux';

import {
  MedicationStore,
  MedicationStoreAction,
  MedicationStoreActionTypes,
} from './types';

const initialState: MedicationStore = {
  medications: [],
};

const hubOwnerReducer: Reducer<MedicationStore, MedicationStoreAction> = (
  state = initialState,
  action
): MedicationStore => {
  switch (action.type) {
    case MedicationStoreActionTypes.GET_MEDICATION_SUCCESS: {
      const meds = action.payload;

      let newState = {
        ...state,
        medications: meds,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default hubOwnerReducer;
