import { Container } from '@mui/material';
import isNil from 'lodash/isNil';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getFacilityById } from '../../store/facility/actionCreators';
import LoadingSpinner from '../Shared/LoadingSpinner';
import FacilityData from './components/FacilityData';
import FacilityDevices from './components/FacilityDevices';

const stateSelector = (state: RootState) => {
  const { facility, dashboardUser } = state;
  const { facilityInfo } = facility;
  const { dashboardUserInfo } = dashboardUser;
  return {
    facilityInfo,
    dashboardUserInfo,
  };
};

function MyFacilityDetail() {
  const dispatch = useDispatch();

  const { facilityInfo, dashboardUserInfo } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const facilityId = dashboardUserInfo ? dashboardUserInfo.facility_id : null;

  useEffect(() => {
    if (facilityId && isNil(facilityInfo)) {
      dispatch(getFacilityById(facilityId));
    }
  }, [facilityInfo, facilityId, dispatch]);

  if (isNil(facilityInfo) || isNil(dashboardUserInfo)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <FacilityData facility={facilityInfo} facilityId={facilityId ?? 0} />
      {facilityId && <FacilityDevices facilityId={facilityId} />}
    </Container>
  );
}

export default MyFacilityDetail;
