import { Reducer } from 'redux';
import {
  DashboardUserStore,
  DashboardUserStoreAction,
  DashboardUserStoreActionTypes,
} from './dashboardUserTypes';

const initialState: DashboardUserStore = {
  dashboardUserInfo: null,
  error: null,
  isLoading: false,
};

const dashboardUserReducer: Reducer<
  DashboardUserStore,
  DashboardUserStoreAction
> = (state = initialState, action) => {
  switch (action.type) {
    case DashboardUserStoreActionTypes.POPULATE_DASHBOARD_USER: {
      const { dashboardUserInfo } = action.payload;
      let newState = {
        ...state,
        dashboardUserInfo: dashboardUserInfo,
      };
      return newState;
    }
    case DashboardUserStoreActionTypes.DASHBOARD_USER_API_ERR: {
      const { error } = action.payload;
      console.log('error: ', action.payload);
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case DashboardUserStoreActionTypes.DASHBOARD_USER_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        isLoading: isLoading,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default dashboardUserReducer;
