import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';
import { Device } from '../../../store/devices/deviceTypes';
import { SoftwareVersion } from '../../../store/software/softwareTypes';
import StyledSelect from '../../Shared/StyledSelect';

interface DeviceUpdateButtonProps {
  device: Device;
  softwareVersions: SoftwareVersion[];
  action(e: React.MouseEvent, deviceId: number, versionId: number | null): void;
}

function DeviceUpdateButton(props: DeviceUpdateButtonProps) {
  const { device, softwareVersions, action } = props;

  let [newVersion, setNewVersion] = useState(
    device.pinned_software_version_id || 0
  );

  function handleChange(event: SelectChangeEvent<string>) {
    setNewVersion(event.target.value as unknown as number);
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-end"
      style={{ marginBottom: 5 }}
    >
      <Grid item xs="auto">
        <FormControl required variant="outlined" sx={{ minWidth: '200px' }}>
          <StyledSelect
            value={newVersion}
            onChange={handleChange}
            sx={{ width: '200px', textAlign: 'left' }}
          >
            {[{ id: 0, version: 'None' }]
              .concat(softwareVersions)
              .map((version) => (
                <MenuItem key={version.id} value={version.id}>
                  {version.version}
                </MenuItem>
              ))}
          </StyledSelect>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="secondary"
          disabled={device.pinned_software_version_id === newVersion}
          onClick={(e) =>
            action(
              e,
              device.id,
              newVersion === 0 ? null : (newVersion as unknown as number)
            )
          }
        >
          Update Pinned Version
        </Button>
      </Grid>
    </Grid>
  );
}

export default DeviceUpdateButton;
