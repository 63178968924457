import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import App from './App';
import WebSocketConnection from './components/WebSocketConnection';
import * as serviceWorker from './serviceWorker';
import { RootStore } from './store/index';
import mainTheme from './mainTheme';
import { webSocketHost } from './apiConfig';
import { getCookie } from './shared/utils';
import './css/omcare.css';

const token = getCookie('access_token');

interface RootProps {
  store: typeof RootStore;
}

const Root = (props: RootProps) => (
  <ThemeProvider theme={mainTheme}>
    <CssBaseline />
    <Provider store={props.store}>
      <WebSocketConnection host={webSocketHost} token={token ? token : ''}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </WebSocketConnection>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(<Root store={RootStore} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
