import { Moment } from 'moment';
import { TabSchema } from '../GlobalTypes';

export interface DateRangeValue {
  fromDate: Moment;
  toDate: Moment;
}

export interface AgeRangeValue {
  fromAge: string;
  toAge: string;
}

export interface MedAdminTimeValue {
  fromTime: string;
  toTime: string;
}

export interface BasicSelectedValue {
  value: string | DateRangeValue | AgeRangeValue | MedAdminTimeValue;
  display: string;
  disabled?: boolean;
}

export interface SelectedValue {
  display: string;
  value:
    | DateRangeValue
    | AgeRangeValue
    | MedAdminTimeValue
    | BasicSelectedValue[]
    | string;
}

export interface SelectedFilter {
  filterValue: string | DateRangeValue | AgeRangeValue | MedAdminTimeValue;
  filterDisplay: string;
  selectedValues: SelectedValue[];
}

export interface RequiredFilterValues {
  groupBy: string;
  dateRange: string;
  fromDate: Moment | '';
  toDate: Moment | '';
}

export interface FilterValues {
  ageRange: AgeRangeValue | string;
  customAgeRange: number[];
  gender: string;
  state: string;
  zipCodes: string;
  pharmacyId: string;
  facilityId: string;
  medAdminTime: MedAdminTimeValue | string;
  ownerIds: string;
  eventTypeId: string;
}

export interface AdherenceData {
  numberAdherent: number;
  numberNonAdherent: number;
  numberEvents: number;
  groupBy: string;
}

export interface AdherenceDataPayload {
  numberAdherent: number;
  numberNonAdherent: number;
  groupBy: string;
}

export interface FilterValuesPayload {
  group_by: string;
  from_date: string;
  to_date: string;
  start_age: string;
  end_age: string;
  gender: string[];
  state: string[];
  zip_code: string[];
  pharmacy_name: string[];
  facility_name: string[];
  medAdmin_from_time: string;
  medAdmin_to_time: string;
  owner_ids: number[];
  event_type_id: number;
}

export interface NewFilter {
  selectedFilter: BasicSelectedValue;
  selectedFilterValue: BasicSelectedValue | BasicSelectedValue[];
  selectedFilters: SelectedFilter[];
}

export interface RemoveFilter {
  facilityFilter: SelectedFilter[];
  filterValue: string;
  mainFilterValue: string;
  requiredFilterValues: RequiredFilterValues;
  selectedFilter: string;
  selectedFilters: SelectedFilter[];
}

export interface ReportingStore {
  error: any | null;
  isLoading: boolean;
  selectedTab: TabSchema;
  selectedFilters: SelectedFilter[];
  requiredFilterValues: RequiredFilterValues;
  filterValues: Partial<FilterValues>;
  defaultFilterValues: FilterValues;
  selectedFilter: string;
  adherenceData: AdherenceData | null;
}

export enum ReportingStoreActionTypes {
  REPORTING_API_ERR = 'REPORTING_API_ERR',
  REPORTING_SET_LOAD_STATE = 'REPORTING_SET_LOAD_STATE',
  REPORTING_SIDE_BAR_TAB_SELECTION = 'REPORTING_SIDE_BAR_TAB_SELECTION',
  BUILD_FILTER_EVENT = 'BUILD_FILTER_EVENT',
  REMOVE_FILTER_EVENT = 'REMOVE_FILTER_EVENT',
  CHANGE_FILTER_EVENT = 'CHANGE_FILTER_EVENT',
  SELECT_FILTER_VALUE_EVENT = 'SELECT_FILTER_VALUE_EVENT',
  RECIEVE_ADHERENCE_DATA = 'RECIEVE_ADHERENCE_DATA',
  SELECT_REQUIRED_FILTER_VALUE_EVENT = 'SELECT_REQUIRED_FILTER_VALUE_EVENT',
}

export type ApiErrorType = {
  type: ReportingStoreActionTypes.REPORTING_API_ERR;
  payload: { error: any };
};

export type SetLoadStateType = {
  type: ReportingStoreActionTypes.REPORTING_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export interface ReportingSelectSidebarTabAction {
  type: ReportingStoreActionTypes.REPORTING_SIDE_BAR_TAB_SELECTION;
  payload: { selectedTab: TabSchema };
}

export interface HandleNewFilter {
  type: ReportingStoreActionTypes.BUILD_FILTER_EVENT;
  payload: { selectedFilters: SelectedFilter[] };
}

export interface GetAdherenceDataSuccess {
  type: ReportingStoreActionTypes.RECIEVE_ADHERENCE_DATA;
  payload: { adherenceData: AdherenceDataPayload | null };
}

export interface RemoveFilterEvent {
  type: ReportingStoreActionTypes.REMOVE_FILTER_EVENT;
  payload: {
    selectedFilters: SelectedFilter[];
    selectedFilter: string;
    requiredFilterValues: RequiredFilterValues;
  };
}

export interface ChangeFilterEvent {
  type: ReportingStoreActionTypes.CHANGE_FILTER_EVENT;
  payload: { selectedFilter: string };
}

export interface SelectFilterValueEvent {
  type: ReportingStoreActionTypes.SELECT_FILTER_VALUE_EVENT;
  payload: { filterValues: Partial<FilterValues> };
}

export interface SelectRequiredFilterValueEvent {
  type: ReportingStoreActionTypes.SELECT_REQUIRED_FILTER_VALUE_EVENT;
  payload: {
    groupBy: string;
    dateRange: string;
    fromDate: Moment;
    toDate: Moment;
  };
}

export type ReportingAction =
  | ApiErrorType
  | SetLoadStateType
  | ReportingSelectSidebarTabAction
  | HandleNewFilter
  | GetAdherenceDataSuccess
  | RemoveFilterEvent
  | ChangeFilterEvent
  | SelectFilterValueEvent
  | SelectRequiredFilterValueEvent;
