import axios from 'axios';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { restHost } from '../../apiConfig';
import {
  deleteToken,
  formatUrl,
  getCookie,
  jsonToFormUrlEncoded,
} from '../../shared/utils';
import { CustomerStore } from '../customers/customerTypes';
import {
  Pharmacy,
  PharmacyActionType,
  PharmacyInviteDeletePayload,
  PharmacyTechnician,
  PharmacyTechnicianInvite,
} from './pharmacyTypes';

export const apiError = (error: any) => ({
  type: PharmacyActionType.PHARMACY_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: PharmacyActionType.PHARMACY_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const getPharmacies = () => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'pharmacy'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setPharmacies(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message ?? '');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const setPharmacies = (pharmacies: Pharmacy[]) => ({
  type: PharmacyActionType.SET_PHARMACIES,
  payload: { pharmacies },
});

export const setPharmacyInvites = (
  pharmacyId: number,
  invites: PharmacyTechnicianInvite[]
) => ({
  type: PharmacyActionType.SET_PHARMACY_INVITES,
  payload: { pharmacyId: pharmacyId, invites: invites },
});

export const getPharmacyInvites = (pharmacyId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/pharmacy/' + pharmacyId + '/invite'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setPharmacyInvites(pharmacyId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const createPharmacyInvite = (
  pharmacyId: number,
  data: PharmacyTechnicianInvite
) => {
  let queryParams = jsonToFormUrlEncoded(data);
  return (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .post(
        formatUrl(restHost, 'eng/pharmacy/' + pharmacyId + '/invite'),
        queryParams,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      )
      .then(() => {
        dispatch(getPharmacyInvites(pharmacyId));
        toast.success('Invite Sent Successfully');
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deletePharmacyInvite = (
  pharmacyId: number,
  data: PharmacyInviteDeletePayload
) => {
  let queryParams = jsonToFormUrlEncoded(data);
  return (
    dispatch: ThunkDispatch<CustomerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .delete(formatUrl(restHost, 'eng/pharmacy/' + pharmacyId + '/invite'), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        data: queryParams,
      })
      .then((response) => {
        // Tried to delete an invite that's already accepted
        if (response.status === 200) {
          toast.error(response.data.message);
        } else {
          // 204 No problem while deleting invite
          toast.success('Invite deleted successfully');
        }

        dispatch(getPharmacyInvites(pharmacyId));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        // Tried to delete an invite that doesn't even exist
        if (err?.response?.status === 400) {
          toast.error(err?.response?.data?.message);
        }
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const setPharmacyTechs = (
  pharmacyId: number,
  techs: PharmacyTechnician[]
) => ({
  type: PharmacyActionType.SET_PHARMACY_TECHS,
  payload: { pharmacyId: pharmacyId, techs: techs },
});

export const getPharmacyTechs = (pharmacyId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/pharmacy/' + pharmacyId + '/technicians'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        let techs = res.data.map((tech: PharmacyTechnician) => {
          return {
            name: `${tech.first_name} ${tech.last_name}`,
            email: tech.email,
          };
        });
        dispatch(setPharmacyTechs(pharmacyId, techs));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          deleteToken();
        }
        toast.error(err?.response?.data?.message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};
