import MaterialTable, { Column, Options } from '@material-table/core';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CARETEAM_ROLE,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../../shared/constants';
import { sortByFullName, TableOptions } from '../../../shared/tableUtils';
import { getCareTeamRoleById } from '../../../shared/utils';
import { RootState } from '../../../store';
import { Careteam, Owner } from '../../../store/customers/customerTypes';
import { DashboardUserStore } from '../../../store/dashboardUser/dashboardUserTypes';
import { HeaderWrapper } from '../../Shared/HeaderWrapper';
import { Wrapper } from '../../Shared/Wrapper';
import { InviteCareteamUserModal } from './InviteCareteamUserModal';
import { PendingCareteamInvitesTable } from './PendingCareteamInvitesTable';

const columns: Column<Careteam>[] = [
  {
    title: 'Name',
    field: 'name',
    customSort: sortByFullName,
    render: (data) => `${data.first_name} ${data.last_name}`,
  },
  {
    title: 'Role',
    field: 'careteam_role',
    render: (data) => getCareTeamRoleById(data.careteam_role_id),
  },
];

interface OwnerDetailCareTeamProps {
  careTeam: Careteam[] | null;
  handleAddPrimaryContact(
    arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void;
  title: string;
  owner: Owner;
}

function OwnerDetailCareTeam(props: OwnerDetailCareTeamProps) {
  const { careTeam, handleAddPrimaryContact, title, owner } = props;

  const history = useHistory();

  const [showInvite, setInviteModalVisibility] = useState(false);

  const { dashboardUserInfo } = useSelector<RootState, DashboardUserStore>(
    (state) => state.dashboardUser
  );

  const onRowClick = (
    _: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: Careteam | undefined
  ) => {
    if (rowData) {
      const isFacilityAdmin =
        dashboardUserInfo &&
        UNIFIED_FACILITY_ADMIN_ROLES.includes(
          dashboardUserInfo.facility_role_id as number
        );
      if (
        isFacilityAdmin &&
        (rowData.careteam_role_id === CARETEAM_ROLE.FAMILY_CONTACT ||
          rowData.careteam_role_id === CARETEAM_ROLE.FAMILY_ADMIN ||
          rowData.careteam_role_id === CARETEAM_ROLE.FAMILY_CAREGIVER)
      ) {
        toast.error('You do not have access to view this care team member');
      } else {
        history.push(
          `/customers/${rowData.patient_customer_id}/care-team/${rowData.customer_id}`
        );
      }
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant={'h1'}>{title}</Typography>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddPrimaryContact}
          >
            Create primary contact
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setInviteModalVisibility(true);
            }}
          >
            Invite user
          </Button>
        </div>
      </HeaderWrapper>
      <MaterialTable
        columns={columns}
        data={(careTeam || []).sort(sortByFullName)}
        onRowClick={onRowClick}
        options={{
          idSynonym: 'careteam_role_id',
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
          ...(TableOptions as Partial<Options<Careteam>>),
        }}
      />
      <div style={{ marginTop: '25px' }}>
        <PendingCareteamInvitesTable ownerId={owner.customer_id} />
      </div>
      {showInvite ? (
        <InviteCareteamUserModal
          onClose={() => setInviteModalVisibility(false)}
          owner={owner}
        />
      ) : null}
    </Wrapper>
  );
}

export default OwnerDetailCareTeam;
