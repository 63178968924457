import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SelectFormField from '../SelectFormField';
import { Field, FormikProps } from 'formik';
import { TextInputFormField } from '../TextInputFormField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { Pharmacy } from '../../../store/pharmacy/pharmacyTypes';
import { getMedications } from '../../../store/medications/actionCreators';

import { FormValues } from '../ImportMedicationRollStepFormTypes';

import * as Yup from 'yup';
import { DateTime } from 'luxon';

interface MedicationRollMetaInformationProps {
  formik: FormikProps<FormValues>;
  pharmacies: Pharmacy[] | null;
  templateRemoteDate?: DateTime;
  templateRemoteBatchId?: Number;
  key: number;
}

const MedicationRollMetaInformation = (
  props: MedicationRollMetaInformationProps
) => {
  const { formik, pharmacies } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMedications(formik.values.pharmacy));
  }, [dispatch, formik.values.pharmacy]);

  const pharmacyOptions =
    pharmacies &&
    pharmacies
      .map((pharmacy) => {
        return {
          label: pharmacy.name,
          value: pharmacy.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  /* TODO: map this to a pharmacy configuration rather than a hardcoded pharmacy name */
  const isLitePharmacyIntegration: boolean =
    props.pharmacies?.find((x) => x.id === formik.values.pharmacy)?.name !==
    'DivvyDOSE';

  return (
    <Grid container alignItems="flex-start" spacing={2} marginTop={'12px'}>
      <Grid item xs={12}>
        <Field
          label="Pharmacy"
          name="pharmacy"
          placeholder="Pharmacy"
          component={SelectFormField}
          options={pharmacyOptions}
        />
      </Grid>
      {/* TODO: map this to a pharmacy configuration rather than a hardcoded pharmacy name */}
      {isLitePharmacyIntegration && (
        <Grid item xs={12}>
          <Field
            label="Batch ID"
            name="batchId"
            placeholder="123"
            component={TextInputFormField}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DesktopDatePicker
          label="Start Date"
          inputFormat="MM-dd-yyyy"
          value={formik.values.startDate}
          onChange={(value) => {
            formik.setFieldValue('startDate', value, true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={Boolean(formik.errors.startDate)}
              helperText={formik.errors.startDate}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

MedicationRollMetaInformation.label = 'Complete pharmacy information';
MedicationRollMetaInformation.initialValues = {
  startDate: DateTime.now(),
};

MedicationRollMetaInformation.validationSchema = Yup.object({
  pharmacy: Yup.number().required('Required'),
  batchId: Yup.string(),
  startDate: Yup.date().typeError('Specify a date').required('Required'),
});

export default MedicationRollMetaInformation;
