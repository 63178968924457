import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { oAuthHost } from '../../apiConfig';
import {
  jsonToFormUrlEncoded,
  setCookie,
  formatUrl,
  getCookie,
  deleteToken,
} from '../../shared/utils';
import {
  AccessTokenInterface,
  AuthStoreActionTypes,
  ChangePasswordInterface,
} from './authTypes';

export const apiError = (error: any) => ({
  type: AuthStoreActionTypes.AUTH_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: AuthStoreActionTypes.AUTH_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const logOut = (refresh_token: string) => {
  let params = jsonToFormUrlEncoded({ refresh_token: refresh_token });
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'auth/logout'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .catch((err) => {
        console.error(err.response?.data?.message);
      })
      .finally(() => {
        deleteToken();
        dispatch(setLoadState(false));
      });
  };
};

export const successChangeUserPassword = (
  accessToken: AccessTokenInterface
) => ({
  type: AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD,
  payload: { accessToken: accessToken },
});

export const changeUserPassword = (data: ChangePasswordInterface) => {
  let params = jsonToFormUrlEncoded(data);
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(oAuthHost, 'auth/changePassword'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(successChangeUserPassword(res.data));
        setCookie('access_token', res.data.access_token, res.data.expires_in);
        setCookie('refresh_token', res.data.refresh_token);
        setCookie('token_type', res.data.token_type);
        toast.success('Password changed successfully');
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location.href = formatUrl(oAuthHost);
        }
        let message;
        if (err.response.status === 400) {
          message = 'Incorrect request, please try again';
        }
        if (err.response.status === 403) {
          message = 'Incorrect current password';
        }
        if (err.response.status === 500 || err.response.status === 503) {
          message = 'System down, please try again';
        }
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const resetCompleteAccessToken = () => ({
  type: AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN,
});
